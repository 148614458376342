import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { endPoints } from "../../constant/Environment";
import { getAllData } from "../../Utility/API";
import Loader from "../../components/Loader";
import Modal from "react-bootstrap/Modal";
import { BsCart3 } from "react-icons/bs";
import { Link } from "react-router-dom";
import moment from "moment";
import "./style.css";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Actions } from "../../redux/Actions/Actions";
import { getAllBetData } from "../../Utility/API";
import { getLeagueImageUrl } from "../../Utility/functions/Helper";
import { sportEndPoint } from "../../constant/Environment";
import { useSelector } from "react-redux";

function MobilePromo() {
  const state = useSelector((state) => state);

  const { auth, sidebar } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [promoList, setPromoList] = useState([]);
  const [showPromoImage, setPromoImage] = useState("");
  const [leagueId, setSelectedLeagueId] = useState("");
  const [league, setLeague] = useState("");

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [popupPromotions, setPopupPromotions] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setPopupPromotions(item);
  };

  useEffect(() => {
    getPromotionList();
  }, []);

  const dispatch = useDispatch();

  const getPromotionList = async (list) => {
    let url = `${endPoints.api.GET_PROMO_LIST}`;
    setIsLoading(true);

    await getAllData(url)
      .then((response) => {
        setIsLoading(false);
        if (response.status === "success") {
          if (response.data.length > 0) {
            const filteredRows = response?.data?.filter(
              (item) =>
                item.isfeatured == 1 &&
                item.isactive == 1 &&
                item.promoSite == "DFS"
            );
            if (filteredRows?.length > 0) {
              // console.log(filteredRows[0]);
              setPromoImage(filteredRows[0]);
            }
            setPromoList(response.data);
          }
        } else {
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  return (
    <>
      <Row
        className="g-2 promotion-img-mobile"
        style={{ marginLeft: "0px", marginTop: "50px", marginRight: "0px" }}
      >
        {showPromoImage && (
          <Col md={12}>
            <Link to={""} onClick={() => handleShow(showPromoImage)}>
              <img
                src={`${endPoints.apiBaseUrl}${showPromoImage.creative}`}
                alt={"Featured Image"}
                height="auto"
                style={{ width: "100%", border: "none!important" }}
              />
            </Link>
          </Col>
        )}
        {isLoading && (
          <Loader spinner={true} visible={isLoading} className="loader" />
        )}

        {promoList.map((item, ind) => {
          if (
            item.isfeatured == 0 &&
            item.isactive == 1 &&
            item.promoSite == "DFS"
          ) {
            return (
              <Col md={4} xs="6">
                <Link to={""} onClick={() => handleShow(item)}>
                  <img
                    src={`${endPoints.apiBaseUrl}${item.creative}`}
                    alt={item.promo_name}
                    style={{ height: "auto!important" }}
                  />
                </Link>
              </Col>
            );
          } else {
            return null;
          }
        })}
      </Row>
      <Modal
        className="promotion_modal"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h2>{popupPromotions.promo_name}</h2>
            <div className="wishlist row">
              {/* <div className="col-4">
                <img
                  src={`${endPoints.apiBaseUrl}${popupPromotions.creative}`}
                  alt="logo1"
                  style={{ width: "100%" }}
                />
              </div> */}
              <div className="col-12">
                <div className="heading-popup-label">
                  {popupPromotions.promo_copy}
                </div>
                <div className="promotion_modal_p">
                  <p>
                    {" "}
                    Valid until{" "}
                    {moment
                      .utc(popupPromotions.enddate)
                      .format("h:mm A [EST], MMM D, YYYY")}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="join-btn-text prmotionbutton"
                style={{ background: "#0D3862", width: "100%" }}
                onClick={() => {
                  setShow(false);
                  navigate("/");
                  dispatch({
                    type: Actions.TEST_CASE,
                    data: false,
                  });
                }}
              >
                Play Now
              </Button>
            </div>

            <div className="voucher-bottom">
              <p>{popupPromotions.terms_condition}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MobilePromo;
