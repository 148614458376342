import React, { useEffect, useState } from "react";
import "./MyBets.css";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { Form, Row, Col } from "react-bootstrap";
import { fetchAllData, getAllData } from "../../../Utility/API";
import { sportEndPoint } from "../../../constant/Environment";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../redux/Actions/Actions";
import void2 from "../../../image/Void.png";
import dnp from "../../../image/dnp.png";
import { Helmet } from "react-helmet";
import { PageVisit } from "../../../Utility/API";
import PendingBets from "./PendingBets"
import HistoryBets from "./HistoryBets";
import { isMobile } from "react-device-detect";

const MyBets = () => {
  const [ALLData, setALLData] = useState([]);
  const [pending, setPending] = useState([]);
  const [history, setHistory] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [isHistory, setIsHistory] = useState(false);
  const [sameEvent, setSameEvent] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { auth, sidebar } = state;

  useEffect(() => {
    if (auth?.user?.idUser) {
      fetchplayerOpen(auth?.user?.idUser);
      fetchplayerHistory(auth?.user?.idUser);
    }
  }, [Object.keys(auth.user).length > 0]);

  const fetchplayerOpen = (userId) => {
    let url = `${sportEndPoint.apiBaseUrl}${sportEndPoint.api.PENDING_BETS}/${userId}`; //&status=PENDING

    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        setALLData(response);
        setPending(response);
      });
  };

  const fetchplayerHistory = (userId) => {
    let url = `${sportEndPoint.apiBaseUrl}${sportEndPoint.api.COMPLETED_BETS}/${userId}`; //&status=APPROVED

    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        setALLData(response);
        setHistory(response);
      });
  };

   const openEntry = () => {

        setIsPending(true);
        setIsHistory(false);
   }

   const HistoryEntry = () => {

    setIsPending(false);
    setIsHistory(true);
  }

  const gotoHome = () => {
    dispatch({
      type: Actions.TEST_CASE,
      data: false,
    });
    navigate("/");
  };

  useEffect(() => {
    PageVisit(state?.auth?.user?.idUser || "", "my-entries");
  }, []);

  return (
    <div>
      <Helmet>
        <title>BettDraft - My Entries</title>
      </Helmet>

      <div className="container bg-color bg-white">
        <div className={isMobile?"inner-small-space-mobile":"inner-small-space"}>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="row justify-content-center sticky-div2  bg-white">
                <div className="col-md-8">
                  <div class="flex-div btn-with mb-2">
                    <button
                      type="submit"
                      onClick={openEntry}
                      style={{ padding: "14px" }}
                      className={
                        isPending
                          ? "save-btn2 green-button btn btn-success btn blue-button "
                          : " green-button btn btn-success btn openbtn"
                      }
                    >
                      Open Entries
                    </button>
                    <button
                      type="submit"
                      onClick={HistoryEntry}
                      style={{ padding: "14px" }}
                      className={
                        !isPending
                          ? "save-btn2 green-button btn btn-success btn blue-button"
                          : " green-button btn btn-success btn pastbtn"
                      }
                    >
                      Past Entries
                    </button>
                  </div>
                </div>
              </div>
              <div className="custom-height-scroll gray-blue-border">
                <div className="row">

                 <PendingBets pending={pending} isPending={isPending}/>

                 <HistoryBets history={history} isHistory={isHistory}/>

                  {isPending && pending.length == 0 && (
                    <div className="col-md-12">
                      <div className="null-entries">
                        <p>No Entries placed yet !</p>

                        <button
                          className=" btn-primary mt-2 btn save-btn placebetbtn"
                          onClick={gotoHome}
                        >
                          PLACE BET
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyBets;
