import React, { useState, Suspense, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Link } from "react-router-dom";
import InfoModal from "./screens/Login/InfoModal";
import { GeoComplyKey } from "./constant/GeoEnv";
import { FaList } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { BiWallet } from "react-icons/bi";
import { RiFileList3Line } from "react-icons/ri";
import { AiOutlineLogin } from "react-icons/ai";
import Navbar from "./components/Navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { endPoints, sportEndPoint } from "./constant/Environment";
import { fetchAllData } from "./Utility/API";
import axios from "axios";
import { Actions } from "./redux/Actions/Actions";
import UserInactive from "./components/UserInactive";
import MainRoutes from "./MainRoutes";
import Slider from "./components/Navbar/slider";
import FantasyRoutes from "./FantasyRoutes";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollTop";
import { isAndroid, isMobile } from "react-device-detect";
import { createBrowserHistory } from "history";
import { axiosInstance } from "./Utility/axiosInstances";
import { setDispatch } from "./Utility/ConfigData";
import { setCookie } from "./Utility/functions/Helper";
import { Helmet } from "react-helmet";

import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import Toaster from "./components/Toast";
import { ErrorToast } from "./components/Toast/message";
import { googleLogout } from "@react-oauth/google";

import { decryptUsingAES128, clientMob, client } from "./Utility/ConfigData";

var client1 = null;

const App = () => {
  const state = useSelector((state) => state);
  const { auth, sidebar } = state;
  let maintenanceData = auth?.maintenanceData;
  let maintenanceFlag = maintenanceData?.specifiers?.maintenance_mode || false;
  const [routeList, setRouteList] = useState([
    "/login",
    // "/signup",
    "/join-now",
    "/info",
    "/personal-information",
    // "/change-email",
    "/change-password",
    "/vouchers",
    "/my-entries",
    "/userwallet",
    "/terms-conditions",
    "/validity-and-acceptance",
    "/gaming-rules",
    "/privacy-policy",
    "/how-to-play",
    "/scoring",
    "/sports-rules",
    "/live-betting",
    "/responsible-gaming",
    "/responsible-gaming-settings",
    "/odds-soccer",
    "/odds-basket",
    "/odds-hockey",
    "/odds-tennis",
    "/odds-waterPolo",
    "/odds-cricket",
    "/odds-darts",
    "/odds-boxing",
    "/odds-fustal",
    "/odds-aussie",
    "/odds-volley",
    "/odds-handball",
    "/odds-baseball",
    "/odds-beach-volley",
    "/odds-pesapallo",
    "/faq",
    "/about-us",
    "/contact-us",
    // "/bettingguide",
    "/termsconditions",
    "/responsible-gaming",
    "/house-rules",
    "/houserules",
    "/change-email",
    "/vouchers",
    "/details/",
    "/cart",
    "/checkout",
    "/orders",
    "/ResetPassword",
    "/resetPassword",
    "/verification",
    "/resetpassword",
    "/verification",
    "/accountverified",
    "/accountSuccess",
    "/activations",
    "/accountCongrats",
    "/fabicash",
    "/enroll",
    "/verification-steps",
    "/kyc",
    "/geocheck",
    "/activatewallet",
    "/promo-code",
    "/under-maintenance",
    "/join-now-pre",
    "/wallet",
    "/mobile-wallet",
    "/mobile-promo",
    "/forgot-password"
  ]);
  //console.log("window.location.pathname", window.location.pathname);
  // console.log('routeList',routeList.includes(window.location.pathname));
  let params = new URLSearchParams(window.location.search);
  let fromMobile = params.get("fromMobile") || "";
  let ref = params.get("ref") || "";
  let campaign = params.get("campaign") || "";
  if (ref) {
    setCookie("ref", ref, 30); // Set the cookie to expire in 30 days
  }
  if (campaign) {
    setCookie("campaign", campaign, 30); // Set the cookie to expire in 30 days
  }

  const [isLoading, setIsLoadinig] = useState(false);

  const wallet_access = state?.auth?.geoWallet;

  const history = createBrowserHistory();
  const dispatch = useDispatch();

  const applink = localStorage.getItem("appLink");

  const playlink = localStorage.getItem("playstorelink");

  let length = state.betslip.betslips.length;
  //working  code for back button
  useEffect(() => {
    window.onpopstate = () => {
      setRouteList([...routeList]);
    };
  }, [window]);

  useEffect(() => {
    setRouteList([...routeList]);
  }, [sidebar]);

  useEffect(() => {
    // localStorage.setItem("license", "");
    checkUnderMaintence();
    defaultBetConfig();
    getGeolocation();
    setDispatch(dispatch);
    googleLogout();
  }, []);

  // useEffect(() => {
  //   if(state?.auth?.user?.kycStatus == "kycfailed"){
  //     dispatch({
  //       type: Actions.LOGOUT,
  //     });
  //     sessionStorage.clear();
  //   }
  // }, []);

  function checkUnderMaintence() {
    let url = `${sportEndPoint.apiBaseUrl}${sportEndPoint.api.maintenance}`;
    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        dispatch({
          type: Actions.MAINTENANCE,
          data: response,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function defaultBetConfig() {
    let url = `${sportEndPoint.apiBaseUrl}${sportEndPoint.api.Default_Bet_Config}`;
    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        dispatch({ type: Actions.BET_CONFIG, data: response });
        // dispatch({
        //   type: Actions.SET_GAME,
        //   data: "American Football",
        //   Id: "131506",
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getGeolocation() {
    let url = `${endPoints.apiBaseUrl}${endPoints.api.geocomplyLience}`;
    axios.get(url).then((response) => {
      //  console.log("response", response);
      let result = response?.data?.data?.split('">')[1];
      result = result.split("</")[0];
      //console.log('result',result)
      localStorage.setItem("license", result);
    });
  }

  const modalclose = () => {
    dispatch({
      type: Actions.GEOCOMPLYMODAL,
      data: false,
    });
  };

  const middleCallback = async (data) => {
    console.log("data", data);

    switch (data.type) {
      case "LOG":
        console.log(data.message);
        // if(data.message=="Auto open deeplink to register:"){
        //   break;
        // }
        break;
      case "INTERACTION":
        // Acquire interaction: such as show popup with data.link...
        // Data object includes: {type, message, reason, link}
        // Acquire interaction with data.link:
        // Such as show popup. It should have a timer to push users to click on

        // There is a reason field inside the INTERACTION object for clients can
        // detect if they want to use their own message as below:

        // 'app_required': when need to open app to geolocate.
        // 'tlb_require': when need to open TLB to geolocate.
        // 'location_required': when Location permission is not granted.
        // 'precise_location_required': when Precise Location permission is not granted.
        // 'notification_required': when Notification on IOS permission is not granted. (Android no need notification permission anymore)
        break;
      default:
        console.log(`Unhandled ${data.type}: ${data.message}`);
    }
  };
  const finalCallback = async (err, data) => {
    setIsLoadinig(false);

    if (err) {
      console.log("Geolocate failed with error code: " + err.error_code);
      console.log("Geolocate failed with error message: " + err.error_message);
      // ErrorToast('err',err.error_message);
      return {
        status: "failed",
        message: err.error_message,
      };
    } else {
      console.log("Geolocate succeeded with data: " + data);
      if (data) {
        let decryptResponse = decryptUsingAES128(data);
      }
      return {
        status: "success",
        message: "Successfully login",
      };
    }
  };

  const ErrorToast1 = (msg) => {
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      closeOnClick={true}
      pauseOnHover={true}
      draggable={true}
    />;
    {
      /* Same as */
    }
    <ToastContainer />;
  };

  function getGeolocation() {
    setIsLoadinig(true);
    let url = `${endPoints.apiBaseUrl}${endPoints.api.geocomplyLience}`;
    axios
      .get(url)
      .then((response) => {
        let result = response?.data?.data?.split('">')[1];
        result = result?.split("</")[0];
        // console.log('result',result)
        localStorage.setItem("license", result);
        var initConfig = {
          //   oobeeUrl: 'url of new Oobee Server version 2.x',
          oobeeUrl: GeoComplyKey.oobeeUrl,
          license: result || localStorage.getItem("license"),
        };
        if (client1 == null) {
          client1 = clientMob.createClient(initConfig);
        }

        setIsLoadinig(false);
      })
      .catch((error) => {
        setIsLoadinig(false);

        console.error(error);
      });
  }

  axiosInstance.interceptors.request.use((config) => {
    // console.log("res", config);
    //  console.log("res", config);
    config.headers["SessonId"] = localStorage.getItem("session");
    return config;
  });

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      console.log("error", error);
      if (error.response.status == "401") {
        window.alert(
          "You are currently logged in on another device. Please login again to continue.  You will be logged out of your other device."
        );
        history.replace("/login");
        dispatch({
          type: Actions.LOGOUT,
        });
        dispatch({
          type: Actions.REMOVE_ALL_BETSLIP,
        });
        dispatch({
          type: Actions.PARLAY_BETS,
          data: false,
        });
        sessionStorage.clear();
        window.location.reload();
      }
      return error;
    }
  );

  const pathname = window.location.pathname;

  let verification = state?.auth?.user?.verification;

  const downLoadSoftware = () => {
    if (navigator.userAgentData.platform == "macOS") {
      window.open(GeoComplyKey.installer_link_mac, "_self");
    }
    if (navigator.userAgentData.platform == "Windows") {
      window.open(GeoComplyKey.installer_link_win, "_self");
    }
    dispatch({
      type: Actions.GEOSOFTWARE,
      geoFlag: false,
    });
  };

  return (
    <BrowserRouter history={history}>
      <ScrollToTop />
      <UserInactive />
      <Helmet>
        <title>Play DFS | BettDraft Fantasy Sports</title>

        <meta
          name="description"
          content="Mix & Match Daily Fantasy Sports with NFL, MLB, NBA, NHL, MLS and more. Check BettDraft's available stats"
        />

        <link rel="canonical" href="https://dfs.bettdraft.com/" />

        <script type="application/ld+json">
          {`
           {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "BettDraft",
            "url": "https://dfs.bettdraft.com/",
            "thumbnailUrl": "https://www.bettdraft.com/static/media/bdlogo.377e3fd12e6001d9ed5a.png",
            "thumbnail": {
              "@type": "ImageObject",
              "url": "https://www.bettdraft.com/static/media/bdlogo.377e3fd12e6001d9ed5a.png",
              "width": "600",
              "height": "200"
            },
            "sameAs": [
              "https://www.facebook.com/bettdraft",
              "https://twitter.com/BettDraft",
              "https://www.instagram.com/bettdraft/",
              "https://www.linkedin.com/company/bettdraft/",
              "https://www.bettdraft.com/"
            ]
          }          
           `}
        </script>
      </Helmet>

      {ErrorToast()}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Toaster />
      </div>

      {(maintenanceFlag &&
        !maintenanceData?.whiteList.includes(auth?.user?.idUser)) ||
      fromMobile == "mobile" ? null : (
        <Navbar />
      )}
      {(maintenanceFlag &&
        !maintenanceData?.whiteList.includes(auth?.user?.idUser)) ||
      fromMobile == "mobile" ? null : (
        <Slider />
      )}
      <InfoModal
        show={state?.auth.geoFlag}
        onHide={() => {
          dispatch({
            type: Actions.GEOSOFTWARE,
            geoFlag: false,
          });
        }}
        onSubmit={() => downLoadSoftware()}
      />
      <div style={{ marginBottom: "40px" }}>
        {routeList.includes(window.location.pathname) ? (
          <MainRoutes />
        ) : (
          <div id="Sportbook">
            <div
              className={"container-fluid bg-color-sportbook"}
              style={{
                background: "white",
                marginTop: pathname === "/" ? "2px" : "90px",
              }}
            >
              <div className={"inner-space1"}>
                <div className="row">
                  <FantasyRoutes />
                </div>
              </div>
            </div>
          </div>
        )}
        {fromMobile == "mobile" ? (
          ""
        ) : (
          <div className="ipad-sticky-bottom show-ipad">
            {window.location.pathname == "/MobileBetSlip" ? (
              ""
            ) : (
              <div>
                {length > 0 && (
                  <Link to={`/MobileBetSlip`} className="mobilebetslip">
                    <div className="green-bg">
                      <div className="green-maindiv">
                        <div className="green-selected">
                          <h5 className="mainbet">
                            <b>{length > 0 && length}</b>
                          </h5>

                          <h5 className="play-s"> player selected </h5>
                        </div>
                        <div className="flex-aa">
                          <h6 className="font-sticky">
                            Finalize{" "}
                            <i
                              className="fa fa-angle-double-up"
                              style={{ fontSize: "17px", fontWeight: "600" }}
                            ></i>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            )}

            <div className="ipad-inner-sticky">
              <Link
                className="sticky_link"
                to="/"
                onClick={() => {
                  dispatch({
                    type: Actions.TEST_CASE,
                    data: false,
                  });
                }}
              >
                <FaList size={24} />
                <span>Players</span>
              </Link>

              {state?.auth?.isAuthenticated ? (
                <Link
                  className="sticky_link"
                  to="/my-entries"
                  onClick={() => {
                    dispatch({
                      type: Actions.TEST_CASE,
                      data: false,
                    });
                  }}
                >
                  <RiFileList3Line size={24} />
                  <span>My Entries</span>
                </Link>
              ) : (
                <Link
                  className="sticky_link"
                  to={`/login`}
                  onClick={() => {
                    dispatch({
                      type: Actions.TEST_CASE,
                      data: false,
                    });
                  }}
                >
                  <RiFileList3Line size={24} />
                  <span>My Entries</span>
                </Link>
              )}

              {state?.auth?.isAuthenticated ? (
                <Link
                  className="sticky_link"
                  to={
                    verification == "1"
                      ? !wallet_access
                        ? "/"
                        : "/userwallet"
                      : "/"
                  }
                  onClick={() => {
                    dispatch({
                      type: Actions.TEST_CASE,
                      data: false,
                    });
                  }}
                >
                  <BiWallet size={24} />
                  <span>My Wallet</span>
                </Link>
              ) : (
                <Link
                  className="sticky_link"
                  to={`/login`}
                  onClick={() => {
                    dispatch({
                      type: Actions.TEST_CASE,
                      data: false,
                    });
                  }}
                >
                  <BiWallet size={24} />
                  <span>My Wallet</span>
                </Link>
              )}

              {state?.auth?.isAuthenticated ? (
                <Link
                  className="sticky_link"
                  to="/profile"
                  onClick={() => {
                    dispatch({
                      type: Actions.TEST_CASE,
                      data: false,
                    });
                  }}
                >
                  <CgProfile size={24} />
                  <span>My Account</span>
                </Link>
              ) : (
                <Link
                  className="sticky_link"
                  to={`/login`}
                  onClick={() => {
                    dispatch({
                      type: Actions.TEST_CASE,
                      data: false,
                    });
                  }}
                >
                  <AiOutlineLogin />
                  <span>Login</span>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
      {/* {!isMobile && !maintenanceFlag && <Footer />} */}
      {maintenanceFlag &&
      !maintenanceData?.whiteList.includes(
        auth?.user?.idUser
      ) ? null : isMobile ? null : (
        <Footer />
      )}
    </BrowserRouter>
  );
};

export default App;
