//promo-code

import React, { useEffect, useState } from "react";
import "./UnderMaintenance.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assests/images/bdlogo.png";
import { sportEndPoint } from "../../constant/Environment";
import { fetchAllData } from "../../Utility/API";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../redux/Actions/Actions";
const UnderMaintenance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    checkUnderMaintence();
  }, []);
  function checkUnderMaintence() {
    let url = `${sportEndPoint.apiBaseUrl}${sportEndPoint.api.maintenance}`;
    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        dispatch({
          type: Actions.MAINTENANCE,
          data: response,
        });
        console.log("response", response);
        if (response?.specifiers?.maintenance_mode) {
        } else {
          dispatch({
            type: Actions.TEST_CASE,
            data: false,
          });
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div style={{ marginTop: "50px" }}>
      <div className="container maintainance">
        <div className="inner-divspace">
          <div className="article">
            <img
              alt="bettdraft"
              src={logo}
              style={{
                height: "44px",
                cursor: "pointer",
              }}
              // alt="BettDraft"
            />

            <h2 style={{ fontSize: " 26px", marginTop: "25px" }}>
              We&rsquo;ll be back soon!
            </h2>
            <div style={{ padding: "0px 150px" }}>
              <p>
                Sorry for the inconvenience. We're performing some maintenance
                at the moment. Please try again in a few minutes. If you need
                assistance, email support@bettdraft.com, otherwise we'll be back
                up shortly!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenance;
