
import { Actions } from "../Actions/Actions";

const initialState = {
  isAuthenticated: false,
  token: "",
  user: {},
  isModal: false,
  isModalLogin: false,
  geoExpiryTime: "",
  ip:"",
  geoFlag: false,
  maintenanceData: {},
  googleUser:{},
  stateInfo:"",
  geoWallet:false,
  geoError: {},
  geoResponse:false,

};


export default function authenticationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case Actions.MAINTENANCE:
      return {
      ...state,
      maintenanceData:action.data
      }
   case Actions.GEOSOFTWARE:
      return {
      ...state,
       geoFlag:action.geoFlag
      }
    case Actions.GOOGLE_LOGIN:
      return {
        ...state,
        googleUser: action.data.googleUser,
      }
    case Actions.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.data.user,

      };
    case Actions.MODAL_LOGIN:
      return {
        ...state,
        isModalLogin: true,
        isAuthenticated: true,
        user: action.data.user,

      };
    case Actions.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isModalLogin: false,
        user: {},
        token: "",
        isModal: false,
        isModalLogin: false,
        geoExpiryTime: "",
        ip:"",
        geoFlag: false,
        geoError: {},
        geoWallet:false,
        geoResponse:false,
      };
       case Actions.GEOLOCATION:
      return {
        ...state,
        geoExpiryTime: action.data.dateTime,
        ip: action.data.ip,
      };
      case Actions.STATE_LOCATION:
        return {
          ...state,
          stateInfo: action.data
        };
      case Actions.GEO_BASED_WALLET:
          return {
            ...state,
            geoWallet: action.data
          }; 
      case Actions.GEO_ERROR:
      return {
        ...state,
        geoError: action.data,
      };
      case Actions.GEO_RESPONSE:
      return {
        ...state,
        geoResponse: action.data,
      };

    default:
      return state;
  }
}
