import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { endPoints, sportEndPoint } from "../../../constant/Environment";
import { getData, getAllData } from "../../../Utility/API";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Actions } from "../../../redux/Actions/Actions";

const Promotions = (props) => {
  const { data } = props;
  const [promoList, setPromoList] = useState([]);
  useEffect(() => {
    getPromotionList();
  }, []);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getPromotionList = async () => {
    let url = `${endPoints.api.GET_BANNER}`;

    let result = await getAllData(url);
    //  console.log('result',result)
    if (result.status == "success") {
      setPromoList(result.data);
    }
  };

  const handleBannerClick = (link) => {
    const url = new URL(link);
    const relativePath = url.pathname + url.search;
    dispatch({
      type: Actions.TEST_CASE,
      data: false,
    });
    navigate(relativePath);
  };

  const time = 5000;
  // const checkActive = obj => obj.isActive === true

  return (
    <>
      <div className="banner-link-div">
        <div className="banner-space">
          {promoList.length > 0 &&
            promoList?.find((o) => o.isActive == true) && (
              <Carousel variant="dark">
                {promoList.map((item) => {
                  let url = `${endPoints.apiBaseUrl}${item.banner_url}`;
                  if (item.isActive) {
                    return (
                      <Carousel.Item
                        interval={time}
                        onClick={() => {
                          handleBannerClick(item.link);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {/* <a href={item.link}> */}
                        <img
                          alt="bettdraft"
                          className="d-block w-100"
                          src={url}
                          // alt="First slide"
                        />
                        {/* </a> */}
                      </Carousel.Item>
                    );
                  } else {
                    return null;
                  }
                })}
              </Carousel>
            )}
        </div>
      </div>

    </>
  );
};

export default Promotions;
