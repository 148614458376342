export const Actions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  SIDEBAR_EXPAND: "SIDEBAR_EXPAND",
  SELECTED_BETSLIP: "SELECTED_BETSLIP",
  PARLAY_BETS: 'PARLAY_BETS',
  REMOVE_SELECTED_BETSLIP: "REMOVE_SELECTED_BETSLIP",
  REMOVE_ALL_BETSLIP: "REMOVE_ALL_BETSLIP",
  SET_GAME: "SET_GAME",
  CLEAR_GAME: "CLEAR_GAME",
  LEAGUE_GAMES: "LEAGUE_GAMES",
  BETSLIP_RATES: "BETSLIP_RATES",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  MODAL_LOGIN: "MODAL_LOGIN",
  BET_CONFIG: "BET_CONFIG",
  SHOW_GAMES_MENU: 'SHOW_GAMES_MENU',
  HIDE_GAMES_MENU: 'HIDE_GAMES_MENU',
  SELECTED_REFRESH_BETSLIP: "SELECTED_REFRESH_BETSLIP",
  TEST_CASE: "TEST_CASE",
  CART_COUNT: "CART_COUNT",
  CART_ITEMS: "CART_ITEMS",
  UPDATE_CART_ITEMS: "UPDATE_CART_ITEMS",
  CLEAR_CART: "CLEAR_CART",
  SINGLE_BET_UPDATE:"SINGLE_BET_UPDATE",
  RECECIPT_VISIBLE:"RECECIPT_VISIBLE",
  CLEAR_MSSG:"CLEAR_MSSG",
  SELECTED:"SELECTED",
  GEOLOCATION:"GEOLOCATION",
  TEAMCHECK:"TEAMCHECK",
  DATE_MSG:"DATE_MSG",
  MORE_LESS_VISIBLE:"MORE_LESS_VISIBLE",
  GEOSOFTWARE:'GEOSOFTWARE',
  MAINTENANCE: 'MAINTENANCE',
  GEOCOMPLYMODAL:'GEOCOMPLYMODAL',
  DISPLAY_TOAST:'DISPLAY_TOAST',
  GOOGLE_LOGIN: "GOOGLE_LOGIN",
  STAT_CHECK:"STAT_CHECK",
  POPUP_MODAL:"POPUP_MODAL",
  POPUP_DATA:"POPUP_DATA",
  STATE_LOCATION:"STATE_LOCATION",
  GEO_BASED_WALLET:"GEO_BASED_WALLET",
  PROMO_PAGE:"PROMO_PAGE",
  GEO_ERROR: "GEO_ERROR",
  GEO_RESPONSE:'GEO_RESPONSE'
  
 
};
export default {};
