import React from "react";
import "./style.css";
import { MdLocationOff } from "react-icons/md";
import locationicon from "../../../src/assests/new/location-off.png";
import { useSelector } from "react-redux";

const FailedGeocheck = () => {
  const state = useSelector((state) => state);

  const error = state?.auth?.geoError;

  return (
    <div>
      <div className="verification-box">
        <div className="inner-geodiv">
          <img className="locationicon" src={locationicon}></img>
          <span className="main-para">
            <span className="big-heading">
              {error?.type
                ? error?.type == "boundary"
                  ? "LOCATION WARNING!"
                  : error?.type == "proxy"
                  ? "VPN WARNING"
                  : error?.type == "jailbreak_detection"
                  ? "DEVICE WARNING"
                  : `${error?.type?.toUpperCase()} WARNING`
                : "LOCATION WARNING!"}
            </span>
            <br />
            {/* <span className='big-heading'>Location Warning!</span><br/> */}
            <span className="small-heading">
              {error?.message
                ? `${error?.message} Once you have resolved this error, please try login again.`
                : "To access BettDraft you need to enable access to location services on your device. Please enable these on your device or browser then try login again."}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FailedGeocheck;
