import React, { useState, useRef, useEffect } from "react";
import "../../screens/Sportsbook/Sportsbook.css"; //"../Sportsbook.css";
import { Row, Col, Card, Button, Form, Container } from "react-bootstrap";
import "../../css/main.css";
import NewPlayerBetSlip from "../NewPlayerBetSlip";
import { useSelector, useDispatch } from "react-redux";
import { RiFileList3Line } from "react-icons/ri";
import { Actions } from "../../redux/Actions/Actions";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import NotFound from "../../screens/NotFound";
import { PlayerCardImageUrl } from "../../constant/Environment";
import Toaster from "../Toast";
import Loader from "../Loader";
import { isMobile } from "react-device-detect";

import { ErrorToast } from "../Toast/message";
import { getdate } from "../../Utility/functions/Helper";

import MarketingPopup from "../MarketingPopup";
import { PageVisit, RemovePlayer } from "../../Utility/API";
import { AddPlayer } from "../../Utility/API";

const NewPlayerCard = (props) => {
  const {
    playerList,
    searchQuery,
    activeTabIndex,
    activeTabName,
    activeMatchTabName,
    activeshortTabName,
  } = props;
  const [card, setcard] = useState([]);
  const dispatch = useDispatch();
  const [counter, setcounter] = useState(1);
  const state = useSelector((state) => state);
  const { selectedBetslipBtn } = state.betslip;
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const [matchtime, setMatchtime] = useState("");
  const [modalvisible, setModalvisible] = useState(false);
  const [newmarket, setNewmarket] = useState([]);

  useEffect(() => {
    if (activeMatchTabName) {
      const splitMatch = activeMatchTabName.split(" v/s ");
      const home = splitMatch[0];
      const away = splitMatch.length > 1 ? splitMatch[1] : "";
      setHomeTeam(home);
      setAwayTeam(away);
    }
  });

  const triggermodal = () => {
    setModalvisible(true);
  };

  const modalclose = () => {
    setModalvisible(false);
  };

  useEffect(()=>{
    PageVisit(state?.auth?.user?.idUser || "","home");
  },[]);


  useEffect(() => {
    if (activeTabName && activeMatchTabName) {
      const alldates = getdate(playerList);

      alldates.map((item, index) => {
        if (
          activeMatchTabName == item.games &&
          activeTabName == item.shortdate
        ) {
          const matchDateTime = moment(item?.name);
          const maindate = matchDateTime.format("ddd, MMM DD h:mm A");
          setMatchtime(maindate);
        }
      });
    }
  });

  const handlechangePlayer = (btnName, element, playerId, eventId) => {
    if (state?.betslip?.isVisible == false) {
      dispatch({
        type: Actions.RECECIPT_VISIBLE,
        data: true,
      });
    }

    if (state?.betslip?.dateMsg == true) {
      dispatch({
        type: Actions.DATE_MSG,
        data: false,
      });
    }

    if (state?.betslip?.isSelected == true) {
      dispatch({
        type: Actions.SELECTED,
        data: false,
      });
    }

    if (state?.betslip.isteam == true && state?.betslip.betslips.length < 10) {
      dispatch({
        type: Actions.TEAMCHECK,
        data: false,
      });
    }

    if (state?.betslip.Message == false) {
      dispatch({
        type: Actions.CLEAR_MSSG,
        data: true,
      });
    }

    let index = selectedBetslipBtn.indexOf(btnName);
    setcounter(1);

    if (index == -1) {
      if (length <= 9) {
        /**
         * If element is not in selected then will insert it
         * otherwise already in array it will remove it
         */
        console.log(length, index);

        if (index > -1) {
          dispatch({
            type: Actions.REMOVE_SELECTED_BETSLIP,
            removeEleIndex: index,
          });
        } else {
          dispatch({
            type: Actions.SELECTED_BETSLIP,
            data: {
              ...element,
              displayName: "",
              isMore: false,
              isLess: false,
              playerId,
              eventId,
              isShowing: false,
            },
            selectedBtn: btnName,
          });
          AddPlayer(state?.auth?.user?.idUser || "",element.single_event,element.name,element.single_event,element.points,element.statAbv);
        }
      } else {
        ErrorToast("Maximum of 10 players allowed per contest.");
      }
    } else {
      dispatch({
        type: Actions.REMOVE_SELECTED_BETSLIP,
        removeEleIndex: index,
      });
      RemovePlayer(state?.auth?.user?.idUser || "",element.single_event,element.name,element.single_event,element.points,element.statAbv);
      setNewmarket([]);
    }
  };

  let length = state.betslip.betslips.length;

  useEffect(() => {
    const relatedmarket = new Set();

    state.betslip.betslips.forEach((item) => {
      if (Array.isArray(item.correlatedmarket)) {
        item.correlatedmarket.forEach((value) => {
          relatedmarket.add(`${item.playerId}_${item.eventId}_${value}`);
        });
      } else if (typeof item.correlatedmarket === "string") {
        // If it's a string, add it directly to the Set
        // relatedmarket.add(item.correlatedmarket);
      }
    });
    const uniqueMarketArray = Array.from(relatedmarket);
    setNewmarket(uniqueMarketArray);
    
  }, [state.betslip.betslips]);

  useEffect(() => {
    setTimeout(() => {
      setcounter(0);
    }, 2000);
  }, [counter]);
  return (
    <Row>
      <Toaster />
      {playerList.length > 0 ? (
        <Col sm="12" lg="8">
          <div
            className="midbody bodyborder"
            style={{
              backgroundColor: "#E4E4E4",
              borderRadius: "10px",
              // padding: "20px",
              border: "1px solid rgb(84, 103, 121)",
              marginLeft: "10px",
              maxWidth: " 790px",
            }}
          >
            <MarketingPopup />

            <div className="tab-content" style={{ padding: "0px" }}>
              {awayTeam != "" ? (
                <div class="title-card-header">
                  <div class="row">
                    <div class="col-md-8 col-7 col70">
                      <p
                        class="flex-centert"
                        style={{
                          justifyContent: "left",
                          paddingLeft: "15px",
                          fontSize: "14px!important",
                        }}
                      >
                        {/* <span class="border-button">Home</span> */}
                        {awayTeam} <span style={{ color: "#C02640" }}>@</span>{" "}
                        {homeTeam}
                      </p>
                    </div>
                    {/* <div class="text-center col-md-4 col-2">
                      <p> vs </p>
                    </div> */}
                    <div class="col-md-4 col-5 col30">
                      <p
                        class="flex-centert flex-md-right matchtime"
                        style={{
                          justifyContent: "right",
                          paddingRight: "5px",
                          color: "#0D3862!important",
                        }}
                      >
                        {matchtime}
                        {/* <span class="border-button">Away</span> */}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="title-card-header">
                  <div class="row">
                    <div class="col-md-8 col-7">
                      <p
                        class="flex-centert"
                        style={{
                          justifyContent: "left",
                          paddingLeft: "15px",
                          fontSize: "14px!important",
                        }}
                      >
                        {/* <span class="border-button">Home</span> */}
                        {awayTeam} <span style={{ color: "#C02640" }}></span>{" "}
                        {homeTeam}
                      </p>
                    </div>
                    {/* <div class="text-center col-md-4 col-2">
                      <p> vs </p>
                    </div> */}
                    <div class="col-md-4 col-5">
                      <p
                        class="flex-centert flex-md-right matchtime"
                        style={{
                          justifyContent: "right",
                          paddingRight: "15px",
                          color: "#0D3862!important",
                        }}
                      >
                        {matchtime}
                        {/* <span class="border-button">Away</span> */}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="gyuse-4"
                style={{
                  height: "600px",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                <Col lg="12" xs="12" className="smallbetoption   m-hide">
                  <Row style={{ marginTop: "3px" }}>
                    <Col lg="2" xs="2"></Col>
                    <Col lg="2" xs="1">
                      <h5
                        className="mainbet"
                        style={{
                          border: "2px solid black",
                          textAlign: "center",
                          color: "black",
                          borderRadius: "50%",
                          height: "30px",
                          width: "30px",
                          padding: "4px",
                        }}
                      >
                        {" "}
                        <b>{length > 0 && length}</b>
                      </h5>
                    </Col>
                    <Col lg="6" xs="5">
                      <h5
                        style={{
                          color: "black",
                          marginTop: "7px",
                          fontWeight: "500",
                          fontSize: "17px",
                        }}
                      >
                        player selected
                      </h5>
                    </Col>
                    <Col lg="4" xs="3">
                      <Link to={`/MobileBetSlip`}>
                        <h6
                          style={{
                            color: "black",
                            fontSize: "15px",
                            float: "right",
                            marginTop: "8px",
                            fontWeight: "600",
                          }}
                        >
                          Finalize{" "}
                          <i
                            className="fa fa-angle-double-up"
                            style={{ fontSize: "17px", fontWeight: "600" }}
                          ></i>
                        </h6>
                      </Link>
                    </Col>
                    <Col lg="2" xs="1"></Col>
                  </Row>
                </Col>

                {playerList.map((mainitem, index) => {
                  const awayteam = mainitem?.teamA?.abv || "";
                  const hometeam = mainitem?.teamH?.abv || "";
                  const teamid = mainitem?.teamH?.id || "";
                  var d = mainitem.openDate;

                  const inputDate = moment(mainitem?.openDate);

                  const formattedDate = inputDate.format("ddd, MMM D h:mm A");

                  var gameTime = `${moment(d).format("LLL")}`;
                  const away = mainitem?.awayPlayers || [];
                  const home = mainitem?.homePlayers;
                  const fullist = away.concat(home);
                  const CombineId = `${mainitem.id}_${index}`;
                  return (
                    <div>
                      {mainitem?.type == 1 ? (
                        <Row
                          style={{
                            paddingRight: "15px",
                            paddingLeft: "15px",
                          }}
                        >
                          {/* <Col xs="6" lg="6" md="6" className="mobile-col"> */}

                          {fullist
                            ?.filter((player) =>
                              player?.name
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .map((item2, index) => {
                              let playerImage = "";

                              if (item2.image) {
                                if (item2?.image.includes("https://")) {
                                  playerImage = item2?.image;
                                } else {
                                  playerImage = `${PlayerCardImageUrl}/${item2?.image}`;
                                }
                              } else {
                                let defaultName = item2?.sportName
                                  .replace(/\s/g, "")
                                  ?.toLowerCase();
                                playerImage = `${PlayerCardImageUrl}${"/player/"}${defaultName}.png`;
                              }

                              let playervalue = {};

                              if (
                                activeMatchTabName == mainitem.eventName &&
                                activeTabName == mainitem.displayDate
                              ) {
                                return (
                                  <>
                                    <Col
                                      xs="6"
                                      lg="6"
                                      md="6"
                                      className="allplayers"
                                      style={{
                                        padding: "5px",
                                      }}
                                    >
                                      <Card
                                        className="player-card"
                                        style={{
                                          borderRadius: "10px",
                                          cursor: "pointer",
                                          padding: "0px",
                                        }}
                                      >
                                        <Card.Body>
                                          <Row>
                                            <Col lg="4" xs="12">
                                              <img
                                                alt={item2.name}
                                                src={playerImage}
                                                // alt="palyer"
                                                className="player"
                                                style={{ marginTop: "-8px" }}
                                              />
                                            </Col>

                                            <Col
                                              lg="8"
                                              xs="12"
                                              className="mid-section"
                                            >
                                              <h5
                                                className="cardtext"
                                                style={{
                                                  color: "#C02640",
                                                  textTransform: "capitalize",
                                                  fontWeight: "bold",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {" "}
                                                {item2?.name}{" "}
                                              </h5>

                                              {mainitem?.type == 2 ? (
                                                <h6
                                                  className="cardtext cardtext-1"
                                                  style={{
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {mainitem?.eventName}
                                                </h6>
                                              ) : (
                                                <h6
                                                  style={{
                                                    // marginTop: "6%",
                                                    fontWeight: "bold",
                                                  }}
                                                  className="cardtext cardtext-1"
                                                >
                                                  <span className="red-player">
                                                    {item2?.positionAbv}{" "}
                                                  </span>
                                                  <span>
                                                    <span
                                                      style={{
                                                        fontWeight: "normal",
                                                      }}
                                                    >
                                                      |{" "}
                                                    </span>
                                                    {item2?.team === teamid
                                                      ? hometeam
                                                      : awayteam}{" "}
                                                    {item2?.team === teamid ? (
                                                      <span
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        vs
                                                      </span>
                                                    ) : (
                                                      <span
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        @
                                                      </span>
                                                    )}{" "}
                                                    {item2?.team === teamid
                                                      ? awayteam
                                                      : hometeam}
                                                  </span>
                                                </h6>
                                              )}

                                              {/* <h6
                                                  style={{
                                                    //  marginTop: "8%"
                                                    fontWeight: "normal",
                                                  }}
                                                  className="cardtext cardtext-1"
                                                >
                                                  {" "}
                                                  {formattedDate}{" "}
                                                </h6> */}
                                            </Col>
                                            {item2.playerMarkets.map(
                                              (playeritem, index) => {
                                                const mainid = `${playeritem?.id}_${item2?.id}_${CombineId}`;

                                                let playerImage = "";

                                                let btn11Name = `${mainid}_btn11`;

                                                let cardClassName =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "player-card card-active2"
                                                    : "player-card ";

                                                let cardClassText =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "cardtext cardtext-1"
                                                    : "cardtext cardtext-1";
                                                let playerNameClass =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "cardtext"
                                                    : "cardtext";

                                                let scoreClass =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "score"
                                                    : "score";

                                                let pointClass =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "point"
                                                    : "point";

                                                let dividerClass =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "divider-score-active"
                                                    : "divider-score";

                                                let playerboxClass =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? true
                                                    : false;

                                                return (
                                                  <Col
                                                    lg="4"
                                                    xs="4"
                                                    key={index}
                                                    className="no-mobile-space"
                                                    style={{
                                                      padding: "0px!important",
                                                    }}
                                                  >
                                                    {/* { playeritem?.point > 0 ? */}
                                                    <Row
                                                      className="playerbox"
                                                      style={{
                                                        backgroundColor:
                                                          newmarket.includes(
                                                            `${item2.id}_${mainitem.id}_${playeritem.id}`
                                                          )
                                                            ? "grey"
                                                            : playerboxClass
                                                            ? "#179624"
                                                            : "#0D3862",
                                                      }}
                                                      onClick={
                                                        newmarket.includes(
                                                          `${item2.id}_${mainitem.id}_${playeritem.id}`
                                                        )
                                                          ? null
                                                          : () => {
                                                              handlechangePlayer(
                                                                btn11Name,
                                                                (playervalue = {
                                                                  id: mainid,
                                                                  teamAid:
                                                                    mainitem
                                                                      ?.teamA
                                                                      ?.id ||
                                                                    "",
                                                                  teamAname:
                                                                    mainitem
                                                                      ?.teamA
                                                                      ?.abv ||
                                                                    "",
                                                                  teamHid:
                                                                    mainitem
                                                                      ?.teamH
                                                                      ?.id ||
                                                                    "",
                                                                  teamHname:
                                                                    mainitem
                                                                      ?.teamH
                                                                      ?.abv ||
                                                                    "",
                                                                  single_event:
                                                                    mainitem?.eventName,
                                                                  game_type:
                                                                    mainitem?.type,
                                                                  name: item2?.name,
                                                                  position:
                                                                    item2?.positionAbv,
                                                                  team2:
                                                                    item2?.team,
                                                                  teamName:
                                                                    item2?.teamAbv,
                                                                  image:
                                                                    item2?.image,
                                                                  points:
                                                                    playeritem?.point,
                                                                  statName:
                                                                    playeritem?.statName,
                                                                  team: item2?.team,
                                                                  event_time:
                                                                    gameTime,
                                                                  // date: new Date(),
                                                                  event:
                                                                    mainitem?.startDate,
                                                                  sports:
                                                                    item2?.sport,
                                                                  league:
                                                                    item2?.league,
                                                                  marketId:
                                                                    playeritem?.id,
                                                                  marketname:
                                                                    playeritem?.name,
                                                                  statAbv:
                                                                    playeritem?.statAbv,
                                                                  correlatedmarket:
                                                                    playeritem.excludeMarkets
                                                                      ? playeritem.excludeMarkets
                                                                      : "",
                                                                  playerevent: {
                                                                    type: mainitem?.type,
                                                                    id: mainitem?.id,
                                                                    extId:
                                                                      mainitem?.extId,
                                                                    league:
                                                                      mainitem?.league,
                                                                    sport:
                                                                      mainitem?.sport,
                                                                    teamA:
                                                                      mainitem?.teamA,
                                                                    teamH:
                                                                      mainitem?.teamH,
                                                                  },
                                                                }),
                                                                item2.id,
                                                                mainitem.id
                                                              );
                                                            }
                                                      }
                                                    >
                                                      <Col
                                                        xs="12"
                                                        lg="12"
                                                        className="points"
                                                      >
                                                        <h3
                                                          className={pointClass}
                                                          style={{
                                                            paddingBottom:
                                                              "0px!important",
                                                            marginBottom: "0px",
                                                          }}
                                                        >
                                                          {playeritem?.point}
                                                        </h3>
                                                      </Col>

                                                      <Col
                                                        xs="12"
                                                        lg="12"
                                                        className="scores smallscreen"
                                                      >
                                                        <h6
                                                          className="score"
                                                          style={{
                                                            fontWeight:
                                                              "500!important",
                                                          }}
                                                        >
                                                          {isMobile
                                                            ? playeritem?.statAbv
                                                            : playeritem?.statName}
                                                        </h6>
                                                      </Col>
                                                    </Row>
                                                    {/* : 
                                                        "" } */}
                                                  </Col>
                                                );
                                              }
                                            )}
                                          </Row>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </>
                                );
                              } else {
                                return null;
                              }
                            })}
                        </Row>
                      ) : (
                        <Row
                          style={{
                            paddingRight: "15px",
                            paddingLeft: "15px",
                          }}
                        >
                          {fullist
                            ?.filter((player) =>
                              player?.name
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .map((item2, index) => {
                              let playerImage = "";

                              if (item2.image) {
                                if (item2?.image.includes("https://")) {
                                  playerImage = item2?.image;
                                } else {
                                  playerImage = `${PlayerCardImageUrl}/${item2?.image}`;
                                }
                              } else {
                                let defaultName = item2?.sportName
                                  .replace(/\s/g, "")
                                  ?.toLowerCase();
                                playerImage = `${PlayerCardImageUrl}${"/player/"}${defaultName}.png`;
                              }

                              let playervalue = {};

                              if (
                                activeMatchTabName == mainitem.eventName &&
                                activeTabName == mainitem.displayDate
                              ) {
                                return (
                                  <>
                                    <Col
                                      xs="6"
                                      lg="6"
                                      md="6"
                                      className="allplayers"
                                      style={{
                                        padding: "5px",
                                      }}
                                    >
                                      <Card
                                        className="player-card"
                                        style={{
                                          borderRadius: "10px",
                                          cursor: "pointer",
                                          padding: "0px",
                                        }}
                                      >
                                        <Card.Body>
                                          <Row>
                                            <Col lg="4" xs="12">
                                              <img
                                                alt="bettdraft"
                                                src={playerImage}
                                                // alt="palyer"
                                                className="player"
                                                style={{ marginTop: "-8px" }}
                                              />
                                            </Col>

                                            <Col
                                              lg="8"
                                              xs="12"
                                              className="mid-section"
                                            >
                                              <h5
                                                className="cardtext"
                                                style={{
                                                  color: "#C02640",
                                                  textTransform: "capitalize",
                                                  fontWeight: "bold",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {" "}
                                                {item2?.name}{" "}
                                              </h5>

                                              {mainitem?.type == 2 ? (
                                                <h6
                                                  className="cardtext cardtext-1"
                                                  style={{
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {mainitem?.eventName}
                                                </h6>
                                              ) : (
                                                <h6
                                                  style={{
                                                    // marginTop: "6%",
                                                    fontWeight: "bold",
                                                  }}
                                                  className="cardtext cardtext-1"
                                                >
                                                  <span className="red-player">
                                                    {item2?.positionAbv}{" "}
                                                  </span>
                                                  <span>
                                                    <span
                                                      style={{
                                                        fontWeight: "normal",
                                                      }}
                                                    >
                                                      |{" "}
                                                    </span>
                                                    {item2?.team === teamid
                                                      ? hometeam
                                                      : awayteam}{" "}
                                                    {item2?.team === teamid ? (
                                                      <span
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        vs
                                                      </span>
                                                    ) : (
                                                      <span
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        @
                                                      </span>
                                                    )}{" "}
                                                    {item2?.team === teamid
                                                      ? awayteam
                                                      : hometeam}
                                                  </span>
                                                </h6>
                                              )}

                                              {/* <h6
                                                style={{
                                                  fontWeight: "normal",
                                                }}
                                                className="cardtext cardtext-1"
                                              >
                                                {" "}
                                                {formattedDate}{" "}
                                              </h6> */}
                                            </Col>
                                            {item2.playerMarkets.map(
                                              (playeritem, index) => {
                                                const mainid = `${playeritem?.id}_${item2?.id}_${CombineId}`;

                                                let playerImage = "";

                                                let btn11Name = `${mainid}_btn11`;

                                                let cardClassName =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "player-card card-active2"
                                                    : "player-card ";

                                                let cardClassText =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "cardtext cardtext-1"
                                                    : "cardtext cardtext-1";
                                                let playerNameClass =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "cardtext"
                                                    : "cardtext";

                                                let scoreClass =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "score"
                                                    : "score";

                                                let pointClass =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "point"
                                                    : "point";

                                                let dividerClass =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? "divider-score-active"
                                                    : "divider-score";

                                                let playerboxClass =
                                                  selectedBetslipBtn.includes(
                                                    btn11Name
                                                  )
                                                    ? true
                                                    : false;

                                                return (
                                                  <Col
                                                    lg="4"
                                                    xs="6"
                                                    key={index}
                                                    className="no-mobile-space"
                                                  >
                                                    {/* { playeritem?.point > 0 ? */}
                                                    <Row
                                                      className="playerbox"
                                                      style={{
                                                        backgroundColor:
                                                          newmarket.includes(
                                                            `${item2.id}_${mainitem.id}_${playeritem.id}`
                                                          )
                                                            ? "grey"
                                                            : playerboxClass
                                                            ? "#179624"
                                                            : "#0D3862",
                                                      }}
                                                      onClick={newmarket.includes(
                                                        `${item2.id}_${mainitem.id}_${playeritem.id}`
                                                      )
                                                        ? null
                                                        :
                                                        () => {
                                                        handlechangePlayer(
                                                          btn11Name,
                                                          (playervalue = {
                                                            id: mainid,
                                                            teamAid:
                                                              mainitem?.teamA
                                                                ?.id || "",
                                                            teamAname:
                                                              mainitem?.teamA
                                                                ?.abv || "",
                                                            teamHid:
                                                              mainitem?.teamH
                                                                ?.id || "",
                                                            teamHname:
                                                              mainitem?.teamH
                                                                ?.abv || "",
                                                            single_event:
                                                              mainitem?.eventName,
                                                            game_type:
                                                              mainitem?.type,
                                                            name: item2?.name,
                                                            position:
                                                              item2?.positionAbv,
                                                            team2: item2?.team,
                                                            teamName:
                                                              item2?.teamAbv,
                                                            image: item2?.image,
                                                            points:
                                                              playeritem?.point,
                                                            statName:
                                                              playeritem?.statName,
                                                            correlatedmarket:
                                                              playeritem.excludeMarkets
                                                                ? playeritem.excludeMarkets
                                                                : "",  
                                                            team: item2?.team,
                                                            event_time:
                                                              gameTime,
                                                            // date: new Date(),
                                                            event:
                                                              mainitem?.startDate,
                                                            sports:
                                                              item2?.sport,
                                                            league:
                                                              item2?.league,
                                                            marketId:
                                                              playeritem?.id,
                                                            marketname:
                                                              playeritem?.name,
                                                            statAbv:
                                                              playeritem?.statAbv,
                                                            playerevent: {
                                                              type: mainitem?.type,
                                                              id: mainitem?.id,
                                                              extId:
                                                                mainitem?.extId,
                                                              league:
                                                                mainitem?.league,
                                                              sport:
                                                                mainitem?.sport,
                                                              teamA:
                                                                mainitem?.teamA,
                                                              teamH:
                                                                mainitem?.teamH,
                                                            },
                                                          }),
                                                          item2.id,
                                                          mainitem.id
                                                        )}
                                                      }
                                                    >
                                                      <Col
                                                        xs="12"
                                                        lg="12"
                                                        className="points"
                                                      >
                                                        <h3
                                                          className={pointClass}
                                                        >
                                                          {playeritem?.point}
                                                        </h3>
                                                      </Col>

                                                      <Col
                                                        xs="12"
                                                        lg="12"
                                                        className="scores smallscreen"
                                                      >
                                                        <h6
                                                          className="score"
                                                          style={{
                                                            marginTop: "10px",
                                                          }}
                                                        >
                                                          {isMobile
                                                            ? playeritem?.statAbv
                                                            : playeritem?.statAbv}
                                                        </h6>
                                                      </Col>
                                                    </Row>
                                                    {/* : 
                                                       "" } */}
                                                  </Col>
                                                );
                                              }
                                            )}
                                          </Row>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </>
                                );
                              } else {
                                return null;
                              }
                            })}
                        </Row>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Col>
      ) : (
        <Col sm="12" lg="8">
          <div
            className="midbody bodyborder shail"
            style={{
              backgroundColor: "#E4E4E4",
              borderRadius: "10px",
              paddingTop: "5px",
              border: "2px solid #546779",
              marginLeft: "5px",
            }}
          >
            <NotFound />
          </div>
        </Col>
      )}
      <NewPlayerBetSlip />
    </Row>
  );
};

export default NewPlayerCard;
