import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalDialog, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import { Actions } from "../../redux/Actions/Actions";
import { endPoints } from "../../constant/Environment";
import { useNavigate } from "react-router";
import { addData } from "../../Utility/API";

const MarketingPopup = () => {
  const [promoList, setPromoList] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [popuid, setpoupid] = useState("");
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  let modalvisible = state?.betslip?.popupvisible;
  const popupArr = state?.betslip?.popupdata;
  const navigate = useNavigate();

  const modalclose = () => {
    dispatch({
      type: Actions.POPUP_MODAL,
      data: false,
    });
  };

  const Checkuser = async (id) => {
    setIsLoading(true);
    let data = {
      id_user: state?.auth?.user?.idUser,
      banner_id: id,
    };
    let url = `${endPoints.api.CHECK_USER}`;
    await addData(url, data)
      .then((response) => {
        setIsLoading(false);
        if (response.data.status == "success") {
          // SuccesToast(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleBannerClick = (link, id) => {
    const url = new URL(link);

    const relativePath = url.pathname + url.search;

    dispatch({
      type: Actions.TEST_CASE,
      data: false,
    });
    navigate(relativePath);

    dispatch({
      type: Actions.POPUP_MODAL,
      data: false,
    });

    Checkuser(id);
  };

  return (
    <>
      {popupArr.length > 0 && (
        <div>
          {popupArr.map((item) => {
            let mobileurl = `${endPoints.apiBaseUrl}${item.mobile_image}`;
            let weburl = `${endPoints.apiBaseUrl}${item.web_image}`;
            if (item.isActive) {
              return (
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  backdrop="static"
                  keyboard={false}
                  show={modalvisible ? true : ""}
                  style={{ padding: "18px" }}
                >
                  <Modal.Header className="modalheader no-padding">
                    <Modal.Title
                      id="contained-modal-title-vcenter"
                      style={{ padding: "0px!important" }}
                    >
                      <img
                        src={isMobile ? mobileurl : weburl}
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="paddingtop"></Modal.Body>

                  <Modal.Footer className="bordernone">
                    <Col lg="12" xs="12">
                      <div className="test-area" style={{ marginTop: "-25px" }}>
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "center",
                          }}
                        >
                          {item.description}
                        </p>
                      </div>
                    </Col>

                    <Col lg="12" xs="12" style={{ textAlign: "center" }}>
                      <Button
                        style={{
                          width: isMobile ? "40%" : "40%",
                          cursor: "pointer",
                          backgroundColor: "rgb(23, 150, 36)",
                          border: "1px solid rgb(23, 150, 36)",
                        }}
                        onClick={() => {
                          handleBannerClick(item.link, item.id);
                        }}
                      >
                        {item.title}
                      </Button>
                    </Col>

                    <Col lg="12" xs="12" style={{ textAlign: "center" }}>
                      <span
                        style={{
                          textAlign: "center",
                          fontWeight: "600",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={modalclose}
                      >
                        Close
                      </span>
                    </Col>
                    <Col lg="12"></Col>
                  </Modal.Footer>
                </Modal>
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
    </>
  );
};

export default MarketingPopup;
