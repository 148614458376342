import React from "react";
import "./footer.css";
import footerlogo from "../../assests/new/logo.png";
import app from "../../assests/new/mac.png";
import play from "../../assests/new/playstore.png";
import fb from "../../assests/new/fb.png";
import insta from "../../assests/new/insta.png";
import twitter from "../../assests/new/twitter.png";
import ytube from "../../assests/new/youtube.png";
import sportsio from "../../assests/new/sportsdata.png";
import { useNavigate } from "react-router-dom";
import { Actions } from "../../redux/Actions/Actions";
import { useSelector, useDispatch } from "react-redux";
import androidapp from "../../assests/android-app/BettDraftProdP2V3.2.apk";
import { appVersion } from "../../constant/Environment";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Coming Soon!</strong>
    </Tooltip>
  );

  const navigateScreen = (name) => {
    dispatch({
      type: Actions.TEST_CASE,
      data: false,
    });
    navigate(name);
  };
  return (
    <footer className="footer">
      <div className="row">
        <div className="col-md-2 text-center">
          <div className="footerlogo">
            <img
              alt="DFS Bettdraft"
              src={footerlogo}
              style={{ height: "40px" }}
            />
            <img
              alt="appstore_logo"
              className="mt-5"
              src={app}
              style={{ height: "40px", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  "https://apps.apple.com/in/app/bettdraft-fantasy-sports-game/id6467048047",
                  "_blank"
                ); //to open new page
              }}
            />
          </div>
        </div>
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-6">
              <div className="footerlinks">
                <h6 style={{ color: "white" }}>
                  <b>SUPPORT</b>
                </h6>
                <span
                  className="footertag"
                  onClick={() => {
                    navigateScreen("/contact-us");
                  }}
                >
                  Contact us
                </span>
                <span
                  className="footertag"
                  onClick={() => {
                    navigateScreen("/how-to-play");
                  }}
                >
                  How to play
                </span>
                <span
                  className="footertag"
                  onClick={() => {
                    navigateScreen("/scoring");
                  }}
                >
                  Scoring
                </span>
                <span
                  className="footertag"
                  onClick={() => {
                    navigateScreen("/faq");
                  }}
                >
                  FAQs
                </span>
              </div>
            </div>

            <div className="col-md-6">
              <div className="footerlinks">
                <h6 style={{ color: "white" }}>
                  <b>ABOUT BETTDRAFT</b>
                </h6>
                <span
                  className="footertag"
                  onClick={() => {
                    navigateScreen("/about-us");
                  }}
                >
                  About Us
                </span>
                <span
                  className="footertag"
                  onClick={() => {
                    navigateScreen("/termsconditions");
                  }}
                >
                  Terms and Conditions
                </span>
                <span
                  className="footertag"
                  onClick={() => {
                    navigateScreen("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </span>
                <span
                  className="footertag"
                  onClick={() => {
                    navigateScreen("/house-rules");
                  }}
                >
                  House Rules
                </span>
                <span
                  className="footertag"
                  onClick={() => {
                    navigateScreen("/responsible-gaming");
                  }}
                >
                  Responsible Gaming
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center">
          <div className="branding">
            <div className="socialmedia">
              <a href="https://www.facebook.com/bettdraft" target="a_blank">
                <img src={fb} alt="facebook_icon" />
              </a>

              <a href="https://x.com/BettDraft" target="a_blank">
                <img src={twitter} alt="twitter_icon" />
              </a>
              <a href="https://www.instagram.com/bettdraft/" target="a_blank">
                <img src={insta} alt="instagram_icon" />
              </a>
            </div>

            <div className="reference" style={{ marginTop: "30%" }}>
              <img src={sportsio} style={{ height: "40px" }} />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <h6
          style={{ color: "white", fontWeight: "lighter", textAlign: "center" }}
        >
          © BettDraft All rights are reserved{" "}
          <span style={{ display: "none" }}>| V {appVersion.version}</span>
        </h6>
      </div>
    </footer>
  );
};

export default Footer;
