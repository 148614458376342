import React, { useState } from "react";
import { CgChevronRight } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../redux/Actions/Actions";
import { Link } from "react-router-dom";
import Sportsbook from "../../Sportsbook";
import "./mobileProfile.css";
const MobileProfile = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { auth, cart } = state;
  let verification = state?.auth?.user?.verification;
  const wallet_access = state?.auth?.geoWallet;
  const ProfileMenus = [
    {
      name: "Personal Information",
      path: "personal-information",
    },
    {
      name: "Change Email",
      path: "change-email",
    },
    {
      name: "Change Password",
      path: "change-password",
    },
    {
      name: "Responsible Settings",
      path: "responsible-gaming-settings",
    },
    {
      name: "My Entries",
      path: "my-entries",
    },
    {
      name: "Wallet",
      path: verification == "1" ? (wallet_access ? "userwallet" : "") : "",
    },
    {
      name: "Promotions",
      path: "promotions",
    },

    {
      name: "Contact US",
      path: "contact-us",
    },
    {
      name: "Promo Code",
      path: "promo-code",
    },
  ];

  return (
    <div id="Sportbook">
      <div className="container-fluid bg-color-sportbook">
        <div className="inner-space1">
          <div
            className="custom-fff-div2"
            style={{ marginTop: "-68px", background: "#E4E4E4!important" }}
          >
            <div className="flex-column-hidden">
              <div style={{ padding: "9px" }}>
                <h5 style={{ color: "#0D3862", fontWeight: "bold" }}>
                  {"Profile"}
                </h5>
              </div>
              {ProfileMenus.map((item, index) => {
                return (
                  <Link
                    to={`/${item.path}`}
                    className="sideBar-game"
                    onClick={() => {
                      dispatch({
                        type: Actions.TEST_CASE,
                        data: false,
                      });
                    }}
                  >
                    <div className="linkdiv-box">
                      <div className="flex-start-colum">
                        <a
                          className="link-according profilecolor"
                          style={{ color: "#0D3862!important" }}
                        >
                          <span
                            style={{
                              color: "red!important",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            {item.name}
                          </span>
                          <CgChevronRight />
                        </a>
                        <div className="link-according-border"></div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileProfile;
