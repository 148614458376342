// Input.js - Reusable Input Component
import React from "react";
import "./input.css";
const BettDraftInput = ({
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  label,
  name,
  maxLength
}) => {
  return (
    <div id="bettdraft">
      {/* <label className='mb-3' style={{    display: 'grid',color:'#0D3862'}}>{label}</label> */}
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="bettdraft-input"
        maxLength={maxLength}
      />
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
};

export default BettDraftInput;
