import React, { useState, useEffect } from "react";
import "./style.css";
import {
  FormControl,
  FormLabel,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { Switch } from "@mui/material";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { endPoints } from "../../constant/Environment";
import { addData, getAllData } from "../../Utility/API";
import { ErrorToast, SuccesToast } from "../../components/Toast/message";
import Loader from "../../components/Loader";
import Toaster from "../../components/Toast";
import { useDispatch } from "react-redux";
import { Actions } from "../../redux/Actions/Actions";
import { useNavigate } from "react-router";
import { PageVisit } from "../../Utility/API";

const Responsiblegamingsettings = () => {
  const state = useSelector((state) => state);
  const [toggleState, setToggleState] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState("");
  // const[amount, setAmount] = useState('');
  const [checked, setChecked] = useState(false);
  const [selectBetLimit, setSelectBetLimit] = useState(false);
  const [selectDepositLimit, setSelectDepositLimit] = useState(false);
  const [days, setDays] = useState("");
  const [amount, setAmount] = useState(0);
  const [days1, setDays1] = useState("");
  const [amount1, setAmount1] = useState(0);
  const [resetDate, setResetDate] = useState("");
  const [resetDate1, setResetDate1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cooloffperiod, setcooloffperiod] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    PageVisit(state?.auth?.user?.idUser || "", "responsible-game-setting");
  }, []);

  const currencies = [
    {
      value: "1",
      label: "1 Days",
    },
    {
      value: "3",
      label: "3 Days",
    },
    {
      value: "7",
      label: "7 Days",
    },
    {
      value: "14",
      label: "14 Days",
    },
    {
      value: "30",
      label: "30 Days",
    },
    // {
    //   value: "90",
    //   label: "90 Days(3 Months)",
    // },
    // {
    //   value: "180",
    //   label: "180 Days(6 Months)",
    // },
    // {
    //   value: "364",
    //   label: "364 Days(1 Year)",
    // },
  ];

  const GetUserById = () => {
    if (state?.auth?.user?.idUser) {
      let url = `${endPoints.api.GET_BY_USERID}/${state?.auth?.user?.idUser}`;
      getAllData(url).then((response) => {
        dispatch({
          type: Actions.LOGIN,
          data: { user: response.data },
        });
      });
    }
  };

  useEffect(() => {
    GetUserById();
  }, []);

  const handleToggle = () => {
    setChecked(!checked);
  };

  const handleUpdate = () => {
    // Handle update logic here
  };


  useEffect(() => {
    let FilterBetLimit = state?.auth?.user?.rg_LimitsDto?.filter(
      (item) => item.rg_type == "1"
    );
    let FilterDepositLimit = state?.auth?.user?.rg_LimitsDto?.filter(
      (item) => item.rg_type == "2"
    );
  

    FilterBetLimit && setDays(FilterBetLimit[0]?.time_period);
    FilterBetLimit && setAmount(FilterBetLimit[0]?.rg_limit_value);
    FilterBetLimit &&
      setSelectBetLimit(
        FilterBetLimit[0]?.isActive ? FilterBetLimit[0]?.isActive : false
      );
    FilterBetLimit &&
      setResetDate(
        FilterBetLimit[0]?.reset_date
          ? moment(FilterBetLimit[0]?.reset_date).format("MMM Do YY")
          : "Today"
      );
   
    FilterDepositLimit && setDays1(FilterDepositLimit[0]?.time_period);
    FilterDepositLimit && setAmount1(FilterDepositLimit[0]?.rg_limit_value);
    FilterDepositLimit &&
      setSelectDepositLimit(
        FilterDepositLimit[0]?.isActive
          ? FilterDepositLimit[0]?.isActive
          : false
      );
    FilterDepositLimit &&
      setResetDate1(
        FilterDepositLimit[0]?.reset_date
          ? moment(FilterDepositLimit[0]?.reset_date).format("MMM Do YY")
          : "Today"
      );

  }, [state?.auth?.user?.rg_LimitsDto]);

  const handleBetClick = async () => {
    let data = {
      id_user: state?.auth?.user?.idUser,
      rg_type: 1,
      rg_limit_value: parseInt(amount),
      time_period: parseInt(days),
      isActive: selectBetLimit,
    };
    if (data?.rg_limit_value && data?.time_period) {
      let url = `${endPoints.api.userRGLimit}`;
      setIsLoading(true);
      let result = await addData(url, data);
      try {
        if (result.data.status === "success") {
          SuccesToast(result?.data?.message);
          GetUserById();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDepositClick = async () => {
    let data = {
      id_user: state?.auth?.user?.idUser,
      rg_type: 2,
      rg_limit_value: parseInt(amount1),
      time_period: parseInt(days1),
      isActive: selectDepositLimit,
    };
    if (data?.rg_limit_value && data?.time_period) {
      let url = `${endPoints.api.userRGLimit}`;
      setIsLoading(true);
      try {
        let result = await addData(url, data);
        if (result.data.status === "success") {
          SuccesToast(result?.data?.message);
          GetUserById();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCooloff = async () => {
    let data = {
      id_user: state?.auth?.user?.idUser,
      time_period: parseInt(cooloffperiod),
      isActive: true,
    };
    let url = `${endPoints.api.USER_COOLOFF}`;
    setIsLoading(true);
    let result = await addData(url, data);
    if (cooloffperiod == "") {
      ErrorToast("please enter the time period");
    }
    try {
      if (result.data.status === "success") {
        SuccesToast(result?.data?.message);
        // GetUserById();
        dispatch({
          type: Actions.LOGOUT,
        });
        dispatch({
          type: Actions.REMOVE_ALL_BETSLIP,
        });
        dispatch({
          type: Actions.PARLAY_BETS,
          data: false,
        });
        sessionStorage.clear();
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExclude = async () => {
    let data = {
      id_user: state?.auth?.user?.idUser,
      isActive: true,
    };
    let url = `${endPoints.api.USER_EXCLUDE}`;
    setIsLoading(true);
    let result = await addData(url, data);
    try {
      if (result.data.status === "success") {
        SuccesToast(result?.data?.message);
        // GetUserById();
        dispatch({
          type: Actions.LOGOUT,
        });
        dispatch({
          type: Actions.REMOVE_ALL_BETSLIP,
        });
        dispatch({
          type: Actions.PARLAY_BETS,
          data: false,
        });
        sessionStorage.clear();
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Toaster></Toaster>
      {isLoading && <Loader spinner={true} visible={isLoading} />}
      {/* <Helmet>
    <title>Responsible Gaming | BettDraft Fantasy Sports </title>

     <meta
       name="description"
       content="BettDraft encourages responsible gaming. If it stops being fun, learn more about the available resources."
     />

     <link
       rel="canonical"
       href="https://dfs.bettdraft.com/responsible-gaming"
     />
     </Helmet> */}
      <div className="container">
        <div className="row">
          <div className="col-12 ">
            <div className="container bg-color-rg space-bottom mainbox">
              <div className="inner-smallspace-rg">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="content-page-design">
                      <p className="main-topic">Responsible Gaming Settings:</p>
                      {/* <h4>RESPONSIBLE GAMING</h4> */}
                      <p>
                        Controlling your spend and setting your limits is an
                        important part of gaming responsible. We encourage you
                        to set your limits below to maintain control over your
                        spend and to always ensure you are gaming within your
                        means.
                      </p>

                      <p>
                        Your limits can be set for a period of your choice and
                        will reset at the end of each period. Once set you
                        cannot increase or change the period length until the
                        current period is complete. You may however request the
                        update your limits and they will take effect once the
                        current period is complete. You can however lower your
                        limit for the current period by selecting to reduce the
                        amount and leaving your period set to the same amount of
                        days. This will be updated immediately.
                      </p>

                      <p>
                        <b>Cool Off</b>: <br />
                        If you feel you need a break, we suggest you set a cool
                        off period. This will lock you out of the site and app
                        for the selected period. During this time you will not
                        be able to access your account or play at BettDraft.
                      </p>

                      <p>
                        <b>Self Exclusion</b>:<br /> If you feel you have a
                        gambling problem, we suggest you opt to self exclude.
                        Your account will be closed immediately and we will be
                        in touch to arrange payment of any balances you have in
                        your account. This is non reversible and your account
                        will not be reopened, even upon request.
                      </p>

                      <p className="small-topic">Play and Deposit Limits:</p>
                      <div className="row">
                        {/* Toggle */}
                        <div className="col-md-3 col-xs-6">
                          <FormLabel className="formlabel play-limit">
                            Play Limits:
                          </FormLabel>
                          <br />
                          <Switch
                            className="switch-rgs"
                            checked={selectBetLimit}
                            value={selectBetLimit}
                            onClick={() => {
                              setSelectBetLimit(!selectBetLimit);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                        {/* Number of days */}
                        <div className="col-md-3 col-xs-12">
                          <FormLabel
                            className="formlabel"
                            htmlFor="numberOfDays"
                          >
                            Period
                          </FormLabel>
                          <FormControl
                            className="rgs-input"
                            as="select"
                            id="numberOfDays"
                            defaultValue="7 Days"
                            value={days}
                            onChange={(e) => setDays(e.target.value)}
                            //  value={days}
                            //  onChange={handleChange}
                          >
                            <option value="" disabled selected>
                              Select Number of Days
                            </option>
                            {currencies.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </FormControl>
                        </div>
                        {/* Amount */}
                        <div className="col-md-3 col-xs-12">
                          <FormLabel className="formlabel" htmlFor="amount">
                            Amount
                          </FormLabel>
                          <span className="input-group-text">$</span>
                          <FormControl
                            className="rgs-input input form-control-amount"
                            type="number"
                            id="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                        {/* Update Button */}
                        <div className="col-md-3 col-xs-12">
                          <div className="align-last">
                            <Button
                              className="btn-update"
                              onClick={handleBetClick}
                            >
                              Update
                            </Button>
                          </div>
                          <div className="col-12" style={{ textAlign: "end" }}>
                            <span style={{ fontSize: "12px" }}>
                              Update will be applied on {resetDate}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-5">
                        {/* Toggle */}
                        <div className="col-md-3 col-xs-12">
                          <FormLabel className="formlabel play-limit">
                            Deposit Limits:
                          </FormLabel>
                          <br />
                          <Switch
                            className="switch-rgs"
                            checked={selectDepositLimit}
                            // onChange={handleToggle}
                            value={selectDepositLimit}
                            onClick={() => {
                              setSelectDepositLimit(!selectDepositLimit);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                        {/* Number of days */}
                        <div className="col-md-3 col-xs-12">
                          <FormLabel
                            className="formlabel"
                            htmlFor="numberOfDays"
                          >
                            Period
                          </FormLabel>
                          <FormControl
                            className="rgs-input input"
                            as="select"
                            id="numberOfDays"
                            value={days1}
                            onChange={(e) => setDays1(e.target.value)}
                          >
                            <option value="" disabled selected>
                              Select Number of Days
                            </option>
                            {currencies.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </FormControl>
                        </div>
                        {/* Amount */}
                        <div className="col-md-3 col-xs-12">
                          <FormLabel className="formlabel" htmlFor="amount">
                            Amount
                          </FormLabel>
                          <span className="input-group-text">$</span>
                          <FormControl
                            className="rgs-input form-control-amount"
                            type="number"
                            id="amount"
                            value={amount1}
                            onChange={(e) => setAmount1(e.target.value)}
                          />
                        </div>
                        {/* Update Button */}
                        <div className="col-md-3 col-xs-12">
                          <div className="align-last">
                            <Button
                              className="btn-update"
                              onClick={handleDepositClick}
                            >
                              Update
                            </Button>
                          </div>
                          <div className="col-12" style={{ textAlign: "end" }}>
                            <span style={{ fontSize: "12px" }}>
                              Update will be applied on {resetDate1}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "55px" }}>
          <div className="col-md-6 col-xs-12">
            <div className="container bg-color-rg space-bottom">
              <div className="inner-smallspace-rg">
                <div className="row">
                  <div className="col-md-12">
                    <div className="content-page-design">
                      <p className="main-topic">Cool Off Period:</p>
                      {/* <h4>RESPONSIBLE GAMING</h4> */}
                      <p>
                        Please select the number of days you would like to take
                        a break from playing at BettDraft. Please note you will
                        not be able to access your account or any games for this
                        period.
                      </p>

                      <p style={{ paddingTop: "10px" }}>
                        We will not be able to remove this limit once set.
                      </p>

                      <div className="row box-2">
                        {/* Number of days */}
                        <div className="col-12">
                          <FormLabel
                            className="formlabel"
                            htmlFor="numberOfDays"
                          >
                            Period:
                          </FormLabel>
                          <FormControl
                            className="rgs-input"
                            as="select"
                            id="numberOfDays"
                            value={cooloffperiod}
                            onChange={(e) => setcooloffperiod(e.target.value)}
                          >
                            <option value="" disabled selected>
                              Select Number of Days
                            </option>
                            {currencies.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </FormControl>
                        </div>
                        {/* Update Button */}
                        <div
                          className="col-12"
                          style={{ marginTop: "10px", paddingBottom: "10px" }}
                        >
                          <div className="align-last">
                            <Button
                              className="btn-cooloff"
                              onClick={handleCooloff}
                            >
                              Set Cool Off
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="container bg-color-rg space-bottom">
              <div className="inner-smallspace-rg">
                <div className="row">
                  <div className="col-md-12">
                    <div className="content-page-design">
                      <p className="main-topic">Self Exclusion:</p>
                      {/* <h4>RESPONSIBLE GAMING</h4> */}
                      <p>
                        If you have a gambling problem or feel you are unable to
                        control your play and it may lead to you spending beyond
                        your means, we suggest you opt to self exclude.
                      </p>

                      <p>
                        Self Exclusion will remove access immediately from the
                        site and cannot be reversed. Any open balances will be
                        returned to you.
                      </p>

                      <p>
                        We are at liberty to advise certain regulatory bodies of
                        your self exclusion and it may register you on a state
                        or regional database.
                      </p>

                      <p className="danger-text">
                        <b>
                          This cannot under any circumstances be reversed. It is
                          a permanent exclusion from BettDraft and any BettDraft
                          companies.
                        </b>
                      </p>

                      <div className="row">
                        {/* Update Button */}
                        <div className="col-12">
                          <div className="align-last">
                            <Button
                              className="btn-self"
                              onClick={handleExclude}
                            >
                              Self Exclude from BettDraft
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Responsiblegamingsettings;
