export const GeoComplyKey = {
  //staging env
  // installer_link_mac:
  //   "https://stg-ums.geocomply.net/installer/url?version=4.0.0.4&os=mac&id=D59IW53zNF",
  // installer_link_win:
  //   "https://stg-ums.geocomply.net/installer/url?version=4.0.0.4&os=win&id=D59IW53zNF",
  // Secret_Key: "28443cb6c4f474b44507c91cd08d80e2",
  // env: "staging",
  // decryption_key: "755DFB9D55F0A36C78EA783B60D55725",
  // decryption_IV_key: "D2E1E2DE96A4A93B11B02C473E888C53",
  // oobeeUrl:'https://us3-stg-oobee-v2.geocomply.net/',
  // oobee_serviceUrl:'https://us3-stg-es.geocomply.net/oobee.php',
  // solus_cdnlink:'https://stg-cdn.geocomply.com/211/gc-html'
  
  // old credetials and urls
  // export const GeoComplyKey = {
  //   //staging env
  //   installer_link_mac:
  //     "https://stg-ums.geocomply.net/installer/url?version=4.0.0.4&os=mac&id=D59IW53zNF",
  //   installer_link_win:
  //     "https://stg-ums.geocomply.net/installer/url?version=4.0.0.4&os=win&id=D59IW53zNF",
  //   Secret_Key: "28443cb6c4f474b44507c91cd08d80e2",
  //   env: "staging",
  //   decryption_key: "755DFB9D55F0A36C78EA783B60D55725",
  //   decryption_IV_key: "D2E1E2DE96A4A93B11B02C473E888C53",
  //   oobeeUrl:'https://us3-stg-oobee-v2.geocomply.net/'


    //prroducvtion env
    installer_link_mac:
    "https://ums.geocomply.com/installer/url/?version=4.0.0.4&os=mac&id=D59IW53zNF",
  installer_link_win:
    "https://ums.geocomply.com/installer/url/?version=4.0.0.4&os=win&id=D59IW53zNF",
  Secret_Key: "370068045e836b460a5676cda425b7e2",
  env: "production",
  decryption_key: "257D68881A8C24D6B349D8D26AD1C339",
  decryption_IV_key: "AA27E73E52B476D137451745CEA600AA",
   oobeeUrl:'https://us3-oobee-v2.geocomply.com/'
};
