import React, { useState, Suspense, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loading from "./screens/Loading";
import { sportEndPoint } from "./constant/Environment";
import { fetchAllData } from "./Utility/API";
import { Actions } from "./redux/Actions/Actions";
import MobileBetSlip from "./screens/Sportsbook/NewMobileBetSlip";
import MobileProfile from "./screens/Sportsbook/MobileProfile";
import NotFound from "./screens/NotFound";
import Activation from "./screens/Profile/Activation";
import PlayerGames from "./screens/Sportsbook/PlayerGames";
import PromotionSidebar from "./screens/Sportsbook/PlayerGames/PromotionSidebar";
import { isMobile } from "react-device-detect";
import Login from "./screens/Login";
import UnderMaintenance from "./screens/UnderMaintenance";
import PrivateRoute from "./PrivateRoute";
import MobilePromo from "./screens/PromotionList/MobilePromo";
const FantasyRoutes = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    defaultBetConfig();
  }, []);
  function defaultBetConfig() {
    let url = `${sportEndPoint.apiBaseUrl}${sportEndPoint.api.Default_Bet_Config}`;
    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        dispatch({ type: Actions.BET_CONFIG, data: response });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <Suspense fallback={<Loading />}>
        <div>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <PrivateRoute>
                  <PlayerGames />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/under-maintenance"
              element={<UnderMaintenance />}
            />
            <Route
              path="/activations/:userName/:token"
              element={<Activation />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
        
            <Route exact path="/login" element={<Login />} />
            <Route
              exact
              path="/notfound"
              element={
                <PrivateRoute>
                  <NotFound />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/MobileBetSlip"
              element={
                <PrivateRoute>
                  <MobileBetSlip />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/promotions"
              element={
                isMobile? <MobilePromo/> : <PromotionSidebar />
              }
            />

            <Route
              exact
              path="/profile"
              element={
                <PrivateRoute>
                  <MobileProfile />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </Suspense>
    </div>
  );
};

export default FantasyRoutes;
