import { Actions } from "../Actions/Actions";

const initialState = {
  betslips: [],
  selectedBetslipBtn: [],
  betConfiguration: {},
  parlayBets: false,
  playerdata: [],
  playerid: "",
  league: [],
  sports: [],
  isVisible: true,
  Message:true,
  isSelected:false,
  isteam:false,
  dateMsg:false,
  btnVisbile:true,
  modalvisible:false,
  showmsg:false,
  isStatchk:true,
  popupvisible:false,
  popupdata:[],
  promoPage:false

};

export default function selectedBetslipReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SELECTED_BETSLIP:
      return {
        ...state,
        betslips: [...state.betslips, action.data],
        selectedBetslipBtn: [...state.selectedBetslipBtn, action.selectedBtn],
      };
    case Actions.SINGLE_BET_UPDATE: {
      const existingBet = state.betslips.map((item) => {
        // let isMore =  action.data.isMore;
        // let isLess = action.data.isLess;
        // if(item.id == action.data.id){
        //   isMore=  action.data.isMore;
        //   isLess =  action.data.isLess;
        // }
        return {
          ...item,
          isMore: item.id == action.data.id ? action.data.isMore : item.isMore,
          isLess: item.id == action.data.id ? action.data.isLess : item.isLess,
          isShowing:item.id == action.data.id ? action.data.isShowing : item.isShowing
        };

        // if(item.id == action.data.id){
        //   item.isMore =  action.data.isMore;
        //   item.isLess =  action.data.isLess;
        // }
        // return item
      });

      // console.log("existingBet", existingBet);
      return {
        ...state,
        betslips: existingBet,
      };
    }

    case Actions.SELECTED_REFRESH_BETSLIP:
      // console.log('action', action)
      const { fixtureId, odds, betId , marketId } = action;
      const existingBet = state.betslips.map((user) => {
        return {
          ...user,
          //t1Spread2 :  odds ,
          points:
            user.playerId == betId && user.marketId == marketId
               ? odds
              : user.points,
              isMore:   user.playerId == betId && user.marketId == marketId ? false : user.isMore,
              isLess:  user.playerId == betId && user.marketId == marketId ? false : user.isLess,
              isShowing: user.playerId == betId && user.marketId == marketId ? false : user.isShowing,

        };
      });
    // console.log('existingBet',existingBet)
      return {
        ...state,
       betslips: existingBet,
      };
    // userUpdated(state, action) {

    // },

    case Actions.REMOVE_SELECTED_BETSLIP:
      let newslipArray = [...state.betslips];
      newslipArray.splice(action.removeEleIndex, 1);

      let newSelectedBetslipBtnArray = [...state.selectedBetslipBtn];
      newSelectedBetslipBtnArray.splice(action.removeEleIndex, 1);
      return {
        ...state,
        betslips: newslipArray,
        selectedBetslipBtn: newSelectedBetslipBtnArray,
      };
    case Actions.REMOVE_ALL_BETSLIP:
      return {
        ...state,
        betslips: [],
        selectedBetslipBtn: [],
      };
    case Actions.BETSLIP_RATES:
      return {
        ...state,
        betslips: action.data,
      };
    case Actions.BET_CONFIG:
      return {
        ...state,
        betConfiguration: action.data,
      };
    case Actions.PARLAY_BETS:
      return {
        ...state,
        parlayBets: action?.data,
      };
      case Actions.RECECIPT_VISIBLE:
        return {
          ...state,
          isVisible: action?.data,
        };
      case Actions.CLEAR_MSSG:
          return {
            ...state,
            Message: action?.data,
          };
      case Actions.SELECTED:
          return {
            ...state,
            isSelected: action?.data,
          };
      case Actions.TEAMCHECK:
          return {
            ...state,
            isteam: action?.data,
          };
      case Actions.DATE_MSG:
          return {
            ...state,
            dateMsg: action?.data,
          };
      case Actions.MORE_LESS_VISIBLE:
          return {
            ...state,
            btnVisbile: action?.data,
          };
      case Actions.GEOCOMPLYMODAL:
          return {
            ...state,
            modalvisible: action?.data,
          };
      case Actions.DISPLAY_TOAST:
       return {
          ...state,
          showmsg: action?.data,
        };
      case Actions.STAT_CHECK:
       return {
          ...state,
          isStatchk: action?.data,
        };
      case Actions.POPUP_MODAL:
        return{
            ...state,
            popupvisible:action?.data
        }
      case Actions.POPUP_DATA:
          return{
            ...state,
            popupdata:action?.data
        } 
      case Actions.PROMO_PAGE :
        return {
          ...state,
          promoPage:action.data
        }
    default:
      return  state;

  }
}
