// SignUpForm.js
import "./signup.css";
import React, { useEffect, useState } from "react";
import BettDraftInput from "../../components/BettDraftInput";
import { Form, Button } from "react-bootstrap";
import { FcGoogle } from "react-icons/fc";
import license from "../../assests/new/license.png";
import Calender from "../../screens/Joinnow/calender";
import PasswordChecklist from "./PasswordChecklist";
import { useGoogleLogin } from "@react-oauth/google";
import { addData, getAllData } from "../../Utility/API";
import { Actions } from "../../redux/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { ErrorToast, SuccesToast } from "../../components/Toast/message";
import axios from "axios";
import { Navigate, useNavigate } from "react-router";
import { endPoints } from "../../constant/Environment";
import { decryptUsingAES128, geoClientMob } from "../../Utility/ConfigData";
import {
  isMobile,
  isChrome,
  isFirefox,
  isSafari,
  isIE,
  isEdge,
} from "react-device-detect";
import { fetchAllData } from "../../Utility/API";
import Loader from "../../components/Loader";
import { getCookie, validateNoSpecialChar,validateEmail, validateOnlyLetters, validatePassword } from "../../Utility/functions/Helper";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useLocation } from "react-router";

var geoClient = null;

var result = {};

const SignUpForm = (props) => {
  const location = useLocation();

  const alldata = location.state;

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    username: "",
    promo: "",
    address: "",
    mobile: "",
    password: "",
    country: "",
    state: "",
    zip: "",
    city: "",
    street: "",
    google_code: "",
    AddressonUi:"",
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const state = useSelector((state) => state);

  const [errors, setErrors] = useState({});
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [day, setDay] = useState("");
  const [isLoading, setIsLoadinig] = useState("");
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [googleUser, setGoogleUser] = useState(false);
  const [checkboxage, setCheckboxAge] = useState(false);
  const [CheckboxPolicy, setchecboxPolicy] = useState(false);
  const [uniquename, setUniqueName] = useState(false);
  const [uniquemail, setUniquemail] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [passwordShow, setPasswordShow] = useState(false);
  const [selectAddress, setSlectedAddress] = useState(false);

  let deviceId = "web";
  let operatingPlatform = navigator.platform;
  let browsername = window.navigator;
  function devicetype() {
    if (isMobile) return "Mobile web";
    else return "web";
  }

  function BrowserInfo() {
    if (isChrome) return "Google Chrome";
    if (isFirefox) return "Mozilla Firefox";
    if (isSafari) return "Apple Safari";
    if (isEdge) return "Microsoft Edge";
    if (isIE) return "Internet Explorer";
    return "Unknown Browser";
  }

  const handleNext = async(e) => {
    e.preventDefault();
    let result = await validateForm();
    if (result) {

      if (step < 4) {
        setStep(step + 1);
      } else {
        Submithandler();
      }
    }
  };

  useEffect(() => {
    if (alldata) {
      setGoogleUser(true);
      setStep(2);
      setFormData((prevData) => ({
        ...prevData,
        email: alldata.email,
        firstName: alldata.name,
        lastName: alldata.surname,
        password: alldata.passwd,
        google_code: alldata.passwd,
      }));
    }
  }, []);

  const handleageCheckbox = (e) => {
    setCheckboxAge(e.target.checked); // Toggle the state
  };

  const handlePolicycheckbox = (e) => {
    setchecboxPolicy(e.target.checked); // Toggle the state
  };

  const Submithandler = async (event) => {
    let DateOfBirth = formatDate(day, month, year);

    let url = `${endPoints.api.USER_SIGNUP}`;

    setIsLoadinig(true);


    let data = {
      login: formData.username,
      passwd: formData.password,
      name: formData.firstName,
      surname: formData.lastName,
      email: formData.email,
      birthDate: DateOfBirth,
      mobile:formData.mobile,
      address: formData.address,
      deviceId: deviceId,
      platform: operatingPlatform,
      zipCode: formData.zip,
      state: formData.state,
      country: formData.country,
      city: formData.city,
      google_code: formData.google_code,
      browser: BrowserInfo(),
      device: devicetype(),
      referral: getCookie("ref"),
      campaign: getCookie("campaign"),
      promoCode: formData.promo,
      promoId: 0,
      
    };

    await addData(url, data)
      .then((response) => {
        setIsLoadinig(false);
        if (response.data.status == "success") {
          if (response.data.data.kycStatus.toLowerCase() == "kycpassed") {
            geoClient = geoClientMob.createClient();

            // Set up parameters
            geoClient.setUserId(response.data.data.idUser);
            geoClient.setReason("SIGNUP");
            geoClient.setLicense(localStorage.getItem("license2"));

            // console.log("request",geoClient.data);

            geoClient.events
              .on("hint", function (BROWSER_GEOLOCATION_DENIED, data) {
                //e.g. show hint messages to users
              })
              .on("engine.success", function (text, xml) {
                // $.post('/your-service-to-decrypt-and-verify-the-response', function(text) {
                // //e.g. fail or pass your user
                // });
                // console.log("text",text);

                let payload = {
                  deviceId: deviceId,
                  userId: response.data.data.idUser,
                  userName: `${response.data.data.name} ${response.data.data.surname}`,
                };

                let decryptResponse = decryptUsingAES128(text, payload);
                // console.log("result",decryptResponse);

                console.log("response", decryptResponse);
              })
              .on("*.failed", function (code, message) {
                if (
                  this.event === "revise.failed" ||
                  this.event === "config.failed" ||
                  this.event === "engine.failed"
                ) {
                  if (
                    code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION
                  ) {
                    //network issue, we can add retry logic if needed
                    // alert(message);
                    ErrorToast(message);
                  } else if (
                    code ===
                      geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT ||
                    code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED
                  ) {
                    // alert(message);
                    // ErrorToast(message);
                    //e.g. update license and retry geolocating
                  } else if (
                    code ===
                    geoClient.ErrorCodes
                      .CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS
                  ) {
                    ErrorToast(message);
                    //it's safe to be ignored, or we can wait for previous request to complete and try again
                  } else {
                    //something went wrong with GeoComply service, your license or your custom data
                    //e.g. submit log to record the issue
                    ErrorToast(message);
                  }
                } else if (this.event === "browser.failed") {
                  // alert(message);
                  // ErrorToast(message);
                  //it's safe to be ignored, you can remove this block or add your own logic
                }
              });
            // Request geolocation
            geoClient.request();

            SuccesToast(response.data.message);
            // localStorage.setItem(
            //   "walletUserId",
            //   JSON.stringify(response.data.data.account.fwUserId)
            // );
            localStorage.setItem("session", response.data.data.sessionId);

            localStorage.setItem("user", JSON.stringify(response.data.data));
            sessionStorage.setItem("user", JSON.stringify(response.data));

            dispatch({
              type: Actions.LOGIN,
              data: { user: response.data.data },
            });
            // navigate("/", {
            //   state: {
            //     idUser: response.data.data.idUser,
            //     login: response.data.data.login,
            //   },
            // });
            navigate({
              pathname: '/',
              search: '?signup=complete',
              state: {
                idUser: response.data.data.idUser,
                login: response.data.data.login,
              },
            });

            dispatch({
              type: Actions.TEST_CASE,
              data: false,
            });
          } else if (
            response.data.data.kycStatus.toLowerCase() == "kycfailed"
          ) {
            geoClient = geoClientMob.createClient();

            // Set up parameters
            geoClient.setUserId(response.data.data.idUser);
            geoClient.setReason("SIGNUP");
            geoClient.setLicense(localStorage.getItem("license2"));

            // console.log("request",geoClient.data);

            geoClient.events
              .on("hint", function (BROWSER_GEOLOCATION_DENIED, data) {
                //e.g. show hint messages to users
              })
              .on("engine.success", function (text, xml) {
                // $.post('/your-service-to-decrypt-and-verify-the-response', function(text) {
                // //e.g. fail or pass your user
                // });
                // console.log("text",text);

                let payload = {
                  deviceId: deviceId,
                  userId: response.data.data.idUser,
                  userName: `${response.data.data.name} ${response.data.data.surname}`,
                };

                let decryptResponse = decryptUsingAES128(text, payload);
                // console.log("result",decryptResponse);

                console.log("response", decryptResponse);
              })
              .on("*.failed", function (code, message) {
                if (
                  this.event === "revise.failed" ||
                  this.event === "config.failed" ||
                  this.event === "engine.failed"
                ) {
                  if (
                    code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION
                  ) {
                    //network issue, we can add retry logic if needed
                    // alert(message);
                    ErrorToast(message);
                  } else if (
                    code ===
                      geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT ||
                    code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED
                  ) {
                    // alert(message);
                    // ErrorToast(message);
                    //e.g. update license and retry geolocating
                  } else if (
                    code ===
                    geoClient.ErrorCodes
                      .CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS
                  ) {
                    ErrorToast(message);
                    //it's safe to be ignored, or we can wait for previous request to complete and try again
                  } else {
                    //something went wrong with GeoComply service, your license or your custom data
                    //e.g. submit log to record the issue
                    ErrorToast(message);
                  }
                } else if (this.event === "browser.failed") {
                  // alert(message);
                  // ErrorToast(message);
                  //it's safe to be ignored, you can remove this block or add your own logic
                }
              });
            // Request geolocation
            geoClient.request();

            // ErrorToast(response.data.message);

            localStorage.setItem("session", response.data.data.sessionId);

            localStorage.setItem("user", JSON.stringify(response.data.data));
            sessionStorage.setItem("user", JSON.stringify(response.data));

            dispatch({
              type: Actions.LOGIN,
              data: { user: response.data.data },
            });
            navigate("/info");
          }
        } else {
          ErrorToast(response.data.message);
          //  console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        ErrorToast(
          "Issue connecting to server (error code 15). Please contact support for further assistance."
        );
      });
  };

  const getaddress = () => {
    let url = `${endPoints.apiBaseUrl}${endPoints.api.RADAR_API}?query=${formData.AddressonUi}&latitude=${latitude}&longitude=${longitude}`;
    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        if (response.status == "success") {
          setSearchResults(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (formData.AddressonUi.length > 2 && !selectAddress) {
      getaddress();
    }
  }, [formData.AddressonUi]);

  function getGeolocation2() {
    setIsLoadinig(true);
    let url = `${endPoints.apiBaseUrl}${endPoints.api.geocomplyLience1}`;

    axios
      .get(url)
      .then((response) => {
        let result = response?.data?.data?.split('">')[1];
        result = result?.split("</")[0];
        localStorage.setItem("license2", result);
        setIsLoadinig(false);
      })
      .catch((error) => {
        setIsLoadinig(false);

        console.error(error);
      });
  }

  useEffect(() => {
    getGeolocation2();
  }, []);

  function CheckUsername(username) {
    let url = `${endPoints.apiBaseUrl}${endPoints.api.USER_AVAILABILTY}/${username}`;
    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        if (response.status == "success") {
          setUniqueName(false);
        } else if (response.status == "failure") {
          setUniqueName(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function Checkemail(email) {
    let url = `${endPoints.apiBaseUrl}${endPoints.api.EMAIL_AVAIALBILTY}/${email}`;
    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        if (response.status == "success") {
          setUniquemail(false);
        } else if (response.status == "failure") {
          setUniquemail(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const isUsernameValid = async () => {
    let isValid = true;
    let url = `${endPoints.api.USER_AVAILABILTY}/${formData.username}`;
    if (formData.username) {
      try {
        setIsLoadinig(true);
        let response = await getAllData(url);
        console.log("isUsernameValid", response);
        setIsLoadinig(false);
        if (response.status == "success") {
        } else if (response.status == "failure") {
          isValid = false;
        }
        return isValid;
      } catch (err) {
        console.log(err);
         setIsLoadinig(false);
      }
    }
  };
  const isEmailValid = async () => {
    let isValid = true;
    let url = `${endPoints.api.EMAIL_AVAIALBILTY}/${formData.email}`;
    if (formData.email) {
      try {
        setIsLoadinig(true);
        let response = await getAllData(url);
        setIsLoadinig(false);
        if (response.status == "success") {
        } else if (response.status == "failure") {
          isValid = false;
        }
        return isValid;
      } catch (err) {
        console.log(err);
        setIsLoadinig(false);
      }
    }
  };

  const googleSignUp = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // console.log("Google googleSignUp successful", tokenResponse);
      // You can now use the tokenResponse to authenticate the user in your app
      setIsLoadinig(true);
      if (tokenResponse.access_token) {
        const userInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
        );
        const resultData = userInfo.data;
        const { email, picture, family_name, given_name, sub } = resultData;
        let data = {
          firstname: given_name,
          lastname: family_name,
          email: email,
          picture: picture,
          username: "",
          birthDate: "",
          promoCode: "",
          google_code: sub,
          deviceId: deviceId,
          platform: operatingPlatform,
          browser: BrowserInfo(),
          device: devicetype(),
        };

        let url = `${endPoints.api.GOOGLE_LOGIN}`;
        await addData(url, data)
          .then(async (response) => {
            if (response.data.status === "success") {
              if (response.data.data.userStatus == "1") {
                localStorage.setItem("session", response.data.data.sessionId);
                dispatch({
                  type: Actions.GOOGLE_LOGIN,
                  data: { googleUser: response.data.data },
                });
                setIsLoadinig(false);
                setGoogleUser(true);
                setStep(2);
                setFormData((prevData) => ({
                  ...prevData,
                  email: response.data.data.email,
                  firstName: response.data.data.name,
                  lastName: response.data.data.surname,
                  password: response.data.data.passwd,
                  google_code: response.data.data.passwd,
                }));
                // navigate("/join-now-pre");
              } else {
                let maintenanceData = state?.auth.maintenanceData;
                if (
                  maintenanceData?.specifiers?.maintenance_mode &&
                  !maintenanceData?.whiteList.includes(
                    response.data.data.idUser
                  )
                ) {
                  navigate("/under-maintenance");
                  return;
                }
                localStorage.setItem(
                  "walletUserId",
                  JSON.stringify(response.data.data.account.fwUserId)
                );
                localStorage.setItem("session", response.data.data.sessionId);
                localStorage.setItem(
                  "user",
                  JSON.stringify(response.data.data)
                );
                sessionStorage.setItem("user", JSON.stringify(response.data));

                if (response.data.data.validation === "1") {
                  // if validation 0 then naviagte verficatoin pageuse vadlaition 1 to check
                  //   dispatch({
                  //     type: Actions.LOGIN,
                  //     data: { user: response.data.data },
                  //   });
                  //   navigate("/verification");
                  // }

                  // else {
                  result = response.data.data;
                  dispatch({
                    type: Actions.LOGIN,
                    data: { user: response.data.data },
                  });

                  dispatch({
                    type: Actions.TEST_CASE,
                    data: false,
                  });

                  geoClient = geoClientMob.createClient();

                  // Set up parameters
                  geoClient.setUserId(result.idUser);
                  geoClient.setReason("LOGIN");
                  geoClient.setLicense(localStorage.getItem("license2"));

                  geoClient.events
                    .on("hint", function (BROWSER_GEOLOCATION_DENIED, data) {
                      //e.g. show hint messages to users
                    })
                    .on("engine.success", function (text, xml) {
                      // $.post('/your-service-to-decrypt-and-verify-the-response', function(text) {
                      // //e.g. fail or pass your user
                      // });

                      let decryptResponse = decryptUsingAES128(text);
                    })
                    .on("*.failed", function (code, message) {
                      if (
                        this.event === "revise.failed" ||
                        this.event === "config.failed" ||
                        this.event === "engine.failed"
                      ) {
                        if (
                          code ===
                          geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION
                        ) {
                          //network issue, we can add retry logic if needed
                          // alert(message);
                          ErrorToast(message);
                        } else if (
                          code ===
                            geoClient.ErrorCodes
                              .CLNT_ERROR_LICENSE_INVALID_FORMAT ||
                          code ===
                            geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED
                        ) {
                          // alert(message);
                          // ErrorToast(message);
                          //e.g. update license and retry geolocating
                        } else if (
                          code ===
                          geoClient.ErrorCodes
                            .CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS
                        ) {
                          ErrorToast(message);
                          //it's safe to be ignored, or we can wait for previous request to complete and try again
                        } else {
                          //something went wrong with GeoComply service, your license or your custom data
                          //e.g. submit log to record the issue
                          ErrorToast(message);
                        }
                      } else if (this.event === "browser.failed") {
                        // alert(message);
                        // ErrorToast(message);
                        //it's safe to be ignored, you can remove this block or add your own logic
                      }
                    });
                  // Request geolocation
                  geoClient.request();

                  // SuccesToast("Successfully login.");

                  navigate("/");
                } else {
                  ErrorToast("Your account has not passed our KYC checks. Please contact customer support.");
                  setIsLoadinig(false);
                }
              }
            } else {
              setIsLoadinig(false);
              ErrorToast(response.data.message);
            }
          })
          .catch((error) => {
            setIsLoadinig(false);
            console.error(error);
          });
      }
    },
    onError: () => {
      console.error("Google login failed");
      // Handle login errors here
      ErrorToast("Google login failed");
    },
    flow: "implicit", // Use 'auth-code' for the authorization code flow
  });

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSelectAddress = (fulldata) => {
    setFormData({
      ...formData,
      AddressonUi:fulldata.formattedAddress, //value to show on UI
      address: fulldata.addressLabel, //  formattedAddress,
      country: fulldata.countryCode,
      zip: fulldata.postalCode,
      state: fulldata.stateCode,
      street: fulldata.street,
      city:fulldata.city
    });
    setSearchResults([]);
    setSlectedAddress(true);
  };

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = Array.from(
    { length: 100 },
    (v, i) => new Date().getFullYear() - i
  );

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;
    setMonth(selectedMonth);
    updateDaysInMonth(selectedMonth, year);
    if (day && !daysInMonth.includes(parseInt(day))) {
      setDay("");
    }
  };

  const handleDayChange = (e) => {
    const selectedDay = e.target.value;
    setDay(selectedDay);
  };

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    setYear(selectedYear);
    updateDaysInMonth(month, selectedYear);
    if (day && !daysInMonth.includes(parseInt(day))) {
      setDay("");
    }
  };

  const updateDaysInMonth = (selectedMonth, selectedYear) => {
    const days = new Date(selectedYear, selectedMonth, 0).getDate();
    const daysArray = Array.from({ length: days }, (_, i) => i + 1);
    setDaysInMonth(daysArray);
  };

  function calculateAge(month, day, year) {
    // Combine month, day, and year into a date string in the format 'YYYY-MM-DD'
    const dateString = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;

    // Convert the date string to a Date object
    const birthDate = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the age
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust age if the current date hasn't occurred yet this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  function formatDate(day, month, year) {
    // Ensure leading zeros for day and month if necessary
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `${month}` : `${month}`;

    // Concatenate year, month, and day with hyphens to form the desired format
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  function formatPhoneNumber(input) {
    // Remove non-numeric characters
    const digits = input.replace(/\D/g, "");

    // Format as (AAA) XXX-YYYY
    return digits.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  const formatMobileNumber = (number, previousValue = "") => {
    const cleaned = number.replace(/\D/g, "");

    if (!cleaned || cleaned.length < 10) {
      return number;
    }

    const formattedNumber = cleaned.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );

    return formattedNumber;
  };

  const validateForm=  async() => {
    let isValid = true;
    const newErrors = {};
// debugger
    // Simple validation, you can implement more complex validations here
    // if (step == 1) {
    //   if (!formData.username.trim()) {
    //     newErrors.username = "Username is required";
    //     isValid = false;
    //   } else {
    //     if (
    //       formData.username.trim().length < 6 ||
    //       formData.username.trim().length > 14
    //     ) {
    //       newErrors.username =
    //         "Your Username must be between 6 - 14 characters long";
    //       isValid = false;
    //     } else {

    //       let regUsername = /^[a-zA-Z0-9]+$/;

    //       if (!formData.username.trim().match(regUsername)) {
    //         newErrors.username =
    //           "Your Username can only contain Numbers and Letters. Special characters cannot be used. ";
    //         isValid = false;
    //       } else {
    //         CheckUsername(formData.username);
    //         if (uniquename) {
    //           newErrors.username =
    //             "The Username you entered is already taken. Please try another Username to continue";
    //           isValid = false;
    //         }
    //       }
    //     }
    //   }

    //   if (!formData.email.trim()) {
    //     newErrors.email = "Email is required";
    //     isValid = false;
    //   } else {
    //     Checkemail(formData.email);
    //     if (uniquemail) {
    //       newErrors.email =
    //         "The Email you entered is already taken. Please try another Email to continue";
    //       isValid = false;
    //     }
    //   }
    // }
    if (step == 1) {
      if (validateNoSpecialChar(formData.username.trim())) {
        newErrors.username =
          "Your Username can only contain Numbers and Letters. Special characters cannot be used.";
        isValid = false;
      }
      if (
        // formData.username.trim().length <= 5 ||
        // formData.username.trim().length >= 14
          formData.username.trim().length < 6 ||
          formData.username.trim().length > 14
      ) {
        newErrors.username =
          "Your Username must be between 6 – 14 characters long.";
        isValid = false;
      }
      if (
        !(
          formData.username.trim().length < 6 ||
          formData.username.trim().length > 14
        ) &&
        !validateNoSpecialChar(formData.username.trim()) &&
        validateEmail(formData?.email) &&
        formData.email.trim()
      ) {
        let usernameResult = await isUsernameValid();
        if (!usernameResult) {
          newErrors.username =
            "The Username you entered is already taken. Please try another Username to continue.";
          isValid = false;
        }
        let emailResult = await isEmailValid();
        if (!emailResult) {
          newErrors.email = "Email already exists.";
          isValid = false;
        }
      }

      if (!validateEmail(formData?.email)) {
        newErrors.email = "Please enter a valid email address.";
        isValid = false;
      }
      if (!formData.email.trim()) {
        newErrors.email = "Please enter a valid email address.";
        isValid = false;
      }
    } 


    
    else if (step == 2) {
      // if (!formData.firstName.trim()) {
      //   newErrors.firstName = "First Name is required";
      //   isValid = false;
      // } else {
      //   if (!/^[a-zA-Z]*$/.test(formData.firstName)) {
      //     newErrors.firstName = "Your Name can only include letters.";
      //     isValid = false;
      //   }
      // }

      // if (!formData.lastName.trim()) {
      //   newErrors.lastName = "Last Name is required";
      //   isValid = false;
      // } else {
      //   if (!/^[a-zA-Z]*$/.test(formData.lastName)) {
      //     newErrors.lastName = "Your Name can only include letters.";
      //     isValid = false;
      //   }
      // }
      if (validateOnlyLetters(formData.firstName.trim())) {
        newErrors.firstName = "Your Name can only include letters.";
        isValid = false;
      }
      if (!formData.firstName.trim()) {
        newErrors.firstName = "Your Name can only include letters.";
        isValid = false;
      }
      if (validateOnlyLetters(formData.lastName.trim())) {
        newErrors.lastName = "Your Name can only include letters.";
        isValid = false;
      }
      if (!formData.lastName.trim()) {
        newErrors.lastName = "Your Name can only include letters.";
        isValid = false;
      }

      // if (!month || !day || !year) {
      //   newErrors.dob =
      //     "Your Date of Birth is incomplete, please update to continue.";
      //   isValid = false;
      // } else {
      //   if (calculateAge(month, day, year) < 18) {
      //     newErrors.dob =
      //       "You must be 18 years old or older to create an account.";
      //     isValid = false;
      //   }
      // }
    
      if (month == "" || day == "" || year == "") {
         newErrors.dob =
          "Your Date of Birth is incomplete, please update to continue.";
        isValid = false;
      }
      else {
        if (calculateAge(month, day, year) < 18) {
          newErrors.dob =
            "You must be 18 years old or older to create an account.";
          isValid = false;
        }
      }





    } else if (step == 3) {
      if (!formData.AddressonUi.trim()) {
        newErrors.address = "Please add your residential address to continue. ";
        isValid = false;
      }
      if (!formData.state.trim() && !formData.zip.trim()) {
        newErrors.address = "Please enter your ZIP code first to see available address options.";
        isValid = false;
      }
      if (!formData.mobile.trim()) {
        newErrors.mobile =  "Please enter your full mobile phone number.";
        isValid = false;
      } 
      else if (formData.mobile.length < 10 || formData.mobile.length > 10) {
        newErrors.mobile = "Please enter your full mobile phone number.";
        isValid = false;
      }
    
      // if (!formData?.mobile.trim()) {
      //   newErrors.mobile = "Please enter your full mobile phone number.";
      //   isValid = false;
      // }
      // if (formData?.mobile > 14) {
      //   newErrors.mobile = "Please enter your full mobile phone number.";
      //   isValid = false;
      // }

      //  let formatNo = await formatPhoneNumberForApi(formData?.mobile)
      // if (formatNo.length > 10) {
      //   newErrors.mobile = "Please enter your full mobile phone number.";
      //   isValid = false;
      // }




    } else if (step == 4) {
      if (googleUser) {
        // if (!formData.username.trim()) {
        //   newErrors.username = "Username is required";
        //   isValid = false;
        // } else {
        //   if (
        //     formData.username.trim().length < 6 ||
        //     formData.username.trim().length > 14
        //   ) {
        //     newErrors.username =
        //       "Your Username must be between 6 - 14 characters long";
        //     isValid = false;
        //   } else {
        //     let regUsername = /^[a-zA-Z0-9]+$/;

        //     if (!formData.username.trim().match(regUsername)) {
        //       newErrors.username =
        //         "Your Username can only contain Numbers and Letters. Special characters cannot be used. ";
        //       isValid = false;
        //     } else {
        //       CheckUsername(formData.username);
        //       if (uniquename) {
        //         newErrors.username =
        //           "The Username you entered is already taken. Please try another Username to continue";
        //         isValid = false;
        //       }
        //     }
        //   }
        // }
        if (validateNoSpecialChar(formData.username.trim())) {
          newErrors.username =
            "Your Username can only contain Numbers and Letters. Special characters cannot be used.";
          isValid = false;
        }
        if (
          formData.username.trim().length < 6 ||
          formData.username.trim().length > 14
        ) {
          newErrors.username =
            "Your Username must be between 6 – 14 characters long.";
          isValid = false;
        }
        if (
          !(
            formData.username.trim().length < 6 ||
            formData.username.trim().length > 14
          ) &&
          !validateNoSpecialChar(formData.username.trim())
        ) {
          let usernameResult = await isUsernameValid();
          if (!usernameResult) {
            newErrors.username =
              "The Username you entered is already taken. Please try another Username to continue.";
            isValid = false;
          }
        }
      } else {
        if (!validatePassword(formData.password)) {
          newErrors.password = "Please create a password for your account.";  
          isValid = false;
        }
        if (!formData.password.trim()) {
          newErrors.password = "Please create a password for your account.";
          isValid = false;
        }
        // if (!formData.password.trim()) {
        //   newErrors.password = "Please create a password for your account.";
        //   isValid = false;
        // } else {
        //   if (formData.password.length < 8 || formData.password.length > 21) {
        //     newErrors.password =
        //       "Your Password must contains 8 to 20 characters";
        //     isValid = false;
        //   } else {
        //     const regex =
        //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        //     if (!regex.test(formData.password)) {
        //       newErrors.password =
        //         "Please clear all the below conditions required for passwords";
        //       isValid = false;
        //     }
        //   }
        // }
      }
      if (!checkboxage) {
        newErrors.checkbox =
          "Please review and agree to our Terms and Conditions and validate your age to continue.";
        isValid = false;
      }

      if (!CheckboxPolicy) {
        newErrors.checkbox =
          "Please review and agree to our Terms and Conditions and validate your age to continue.";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };



  const newFormatMobileNumber = (number) => {
    // Remove all non-digit characters
    const cleaned = number.replace(/\D/g, "");
    // Check if the length of the cleaned number is 10 digits
    if (cleaned) {
      // Format the number as (XXX) XXX-XXXX
      const formattedNumber = cleaned.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "($1) $2-$3"
      );
      return formattedNumber;
    } else {
      // If the number is not 10 digits, return it as is
      return number;
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobile') {
      const numericInput = value.replace(/\D/g, '');
      const truncatedInput = numericInput.slice(0, 10);
      setFormData({ ...formData, [name]: truncatedInput });
    }else
    if (name === "mobile") {
      const numericInput = value.replace(/\D/g, "");
      const truncatedInput = numericInput.slice(0, 10);

      const formattedMobile = formatMobileNumber(
        truncatedInput,
        formData.mobile
      );

  
      setFormData({ ...formData, [name]: truncatedInput });

      const newPosition = formattedMobile.length;
      e.target.setSelectionRange(newPosition, newPosition);
    } else
     if (name === "address") {
      setSlectedAddress(false);

      // Update form data
      setFormData({ ...formData, [name]: value, AddressonUi:value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const formatPhoneNumberForApi = async() => {
    // Regular expression to match the format (123) 456-789
    const regex = /^\(\d{3}\) \d{3}-\d{3}$/;

    if (regex.test(formData.mobile)) {
      // Remove the parentheses, space, and hyphen
      const formattedNumber = formData.mobile.replace(/\D/g, '');
    return formattedNumber
    } else {
      return formData.mobile
      }
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission
      console.log("Form submitted with data:", formData);
    } else {
      console.log("Form has errors");
    }
  };
  const handleBlur = async (e) => {
    e.preventDefault();
    // let result = await validateForm();
    // if (result) {
    // }
  };

  

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="row justify-content-center signup-form first-step">
            <div className="col-md-12">
              <div className="mt-3  google-icon">
                <Button
                  // variant="primary"
                  onClick={() => googleSignUp()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#4285F4",
                    color: "white",
                    padding: "0px",
                    border: "2px solid #4285F4",
                    borderRadius: "0px",
                    cursor: "pointer",
                  }}
                >
                  {/* <FcGoogle size={24} /> */}
                  <div style={{ backgroundColor: "white", padding: "7px" }}>
                    <FcGoogle size={24} style={{ color: "#4285F4" }} />
                  </div>
                  <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                    Sign up with Google
                  </span>
                </Button>
              </div>

              <p className="option-signup center-text">or sign up below</p>

              <h2 className="blue-text mainheading-signup">
                Create Your Account
              </h2>
              </div>

              <div className="col-md-12 steps-input">
              <label className="signup-label">Username:</label>
              <BettDraftInput
                type="text"
                label="Username:"
                placeholder="Enter a Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.username}
              />
              </div>
            <div className="col-md-12 steps-input">
              <label className="signup-label">Email:</label>
              <BettDraftInput
                label="Email:"
                type="email"
                placeholder="Enter your Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
              />
              <div className="join-p mail-heading">
                Your email is used to recover access to your account.
              </div>
            </div>
            <div className="col-md-12 steps-input">
              <label className="signup-label">
                Refer a Friend or Promo Code:
              </label>
              <BettDraftInput
                type="text"
                label="Refer a Friend or Promo Code:"
                placeholder="Enter a Promo Code or Referral Code (Optional)"
                name="promo"
                value={formData.promo}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.promo}
              />
            </div>
            <div className="col-md-4 signup-btn-div">
              <Button className="signupButton" variant="primary" type="submit">
                Continue
              </Button>
            </div>
            <div className="col-md-12">
              <div className="signup-p">
                <span className="small-size">Already Have an Account? </span>{" "}
                <span
                  className="signup-a mt-0"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Log in Here
                </span>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="row justify-content-center signup-form">
            <div className="col-md-12">
              <h2 className="blue-text mainheading-signup2 center-text" style={{marginBottom:"17px!important"}}>
                {googleUser?"Please Confirm your Details":"Enter Your Full Name"}
              </h2>

              <div className="alertmaessage mb-3">
                <img src={license} className="license-img" />
                <i
                  class="fa fa-2x fa-exclamation-triangle warning-icon"
                  aria-hidden="true"
                ></i>
                <div className="information">
                  <span className="first-heading">IMPORTANT</span>
                  <br />
                  <span className="second-heading">
                    Make sure your name matches your drivers license.
                  </span>
                </div>
              </div>
            </div>

              <div className="col-md-12 steps-input">
              <label className="signup-label">First Name:</label>
              <BettDraftInput
                type="text"
                label="First Name:"
                placeholder="Enter your First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                error={errors.firstName}
              />
              </div>

            <div className="col-md-12 steps-input">
              <label className="signup-label">Last Name:</label>
              <BettDraftInput
                label="Last Name:"
                type="text"
                placeholder="Enter your Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                error={errors.lastName}
              />
            </div>

            <div className="col-md-12 last-input">
              <label className="signup-label">Date of Birth:</label>
              <div className="row">
                <div className={isMobile?"col-4":"col-3"}>
                  <Form.Group controlId="month">
                    {/* <Form.Label className="my-form-label mt-3">Month</Form.Label> */}
                    <Form.Select
                      aria-label="Default select example"
                      value={month}
                      name="month"
                      onChange={handleMonthChange}
                      className="bettdraft-select"
                      error={errors.month}
                    >
                      <option value="">Month</option>
                      {months.map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className={isMobile?"col-4":"col-3"}>
                  <Form.Group
                    controlId="day"
                    value={day}
                    name="day"
                    onChange={handleDayChange}
                    error={errors.day}
                  >
                    {/* <Form.Label className="my-form-label mt-3">Day</Form.Label> */}
                    <Form.Select
                      aria-label="Default select example"
                      className="bettdraft-select"
                    >
                      <option value="">Day</option>
                      {daysInMonth.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className={isMobile?"col-4":"col-3"}>
                  <Form.Group controlId="year">
                    {/* <Form.Label className="my-form-label mt-3">Year</Form.Label> */}
                    <Form.Select
                      aria-label="Default select example"
                      value={year}
                      onChange={handleYearChange}
                      className="bettdraft-select"
                      name="year"
                      error={errors.year}
                    >
                      <option value="">Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-12 mt-1">
                  <span className="errors">{errors.dob}</span>
                </div>
              </div>
            </div>

            <div className="col-md-4 signup-btn-div">
              <Button className="signupButton" variant="primary" type="submit">
                Continue
              </Button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="row justify-content-center signup-form">
            <div className="col-md-12">
              <h2 className="blue-text mainheading-signup2">
                Enter Your Address
              </h2>

              <div className="alertmaessage mb-3">
                <img src={license} className="license-img" />
                <i
                  class="fa fa-2x fa-exclamation-triangle warning-icon"
                  aria-hidden="true"
                ></i>
                <div className="information">
                  <span className="first-heading">IMPORTANT</span>
                  <br />
                  <span className="second-heading">
                    Make sure your address matches your drivers license.
                  </span>
                </div>
              </div>
              </div>

              <div className="col-md-12 steps-input">
              <label className="signup-label">Address:</label>
              <BettDraftInput
                type="text"
                label="First Name"
                placeholder="Start typing to search your address"
                name="address"
                value={formData.AddressonUi}
                onChange={handleChange}
                error={errors.address}
              />
              {searchResults.length > 0 && formData.AddressonUi !== "" && (
                <div className="card address-card">
                  {searchResults.map((result, index) => (
                    <div
                      key={index}
                      onClick={() => handleSelectAddress(result)}
                    >
                      <span className="formatted-address">
                        {result.formattedAddress}
                      </span>
                      {index < searchResults.length - 1 && (
                        <hr className="separator" />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="col-md-12 address-input">
              <label className="signup-label">Mobile Number:</label>
              <BettDraftInput
                label="Mobile Number"
                type="text"
                placeholder="Enter your Mobile Number"
                name="mobile"
                // value={formData.mobile}
                value={formatMobileNumber(formData.mobile)}
                maxLength={14}
                onChange={handleChange}
                error={errors.mobile}
              />
            </div>

            <div className="col-md-4 signup-btn-div">
              <Button className="signupButton" variant="primary" type="submit">
                Continue
              </Button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="row justify-content-center signup-form">
            {/* <div> */}
            {googleUser ? (
              <>
                <div className="col-md-12">
                  <h2 className="blue-text mainheading-signup2">
                  Create your Username
                  </h2>
                  </div>

                  <div className="col-md-12">
                  <label className="signup-label">Username:</label>
                  <BettDraftInput
                    type="text"
                    label="Username"
                    placeholder="Enter a Username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.username}
                  />
                </div>
                <div className="col-md-12">
                  <label className="signup-label">
                    Refer a Friend or Promo Code:
                  </label>
                  <BettDraftInput
                    type="text"
                    label="Refer a Friend or Promo Code:"
                    placeholder="Enter a Promo Code or Referral Code (Optional)"
                    name="promo"
                    value={formData.promo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.promo}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-md-12">
                  <h2 className="blue-text mainheading-signup2">
                    Create Your Password
                  </h2>
                  <div className="col-md-12">
                  <div className="input-container">
                    <label className="signup-label">Password:</label>
                    <BettDraftInput
                      type={passwordShow ? "text" : "password"}
                      label="Password"
                      placeholder="Create a Secure Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      error={errors.password}
                      maxLength={20}
                    />
                    <div
                      className="show-icon-signup"
                      onClick={() => {
                        setPasswordShow(!passwordShow);
                      }}
                    >
                      {passwordShow ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </div>
                    <div className="password-heading">Your Password must contain between 8 to 20 characters and include:</div>
                  </div>
                  </div>
                  <PasswordChecklist password={formData.password} />
                </div>
              </>
            )}
            <div className="col-md-12 mt-3 last-input">
              <div className="mb-3">
                <Form.Check
                  className="requirement"
                  // required
                  type="checkbox"
                  // error={errors.CheckboxPolicy}
                  value={CheckboxPolicy}
                  onChange={handlePolicycheckbox}
                  label={
                    <span>
                      * I agree to the{" "}
                      <a
                        href="https://dfs.bettdraft.com/terms-conditions"
                        target="a_blank"
                        className="red-link"
                      >
                        Terms and Conditions
                      </a>
                      ,{" "}
                      <a
                        href="https://dfs.bettdraft.com/privacy-policy"
                        target="a_blank"
                        className="red-link"
                      >
                        Privacy Policy
                      </a>
                      , and{" "}
                      <a
                        href="https://dfs.bettdraft.com/house-rules"
                        target="a_blank"
                        className="red-link"
                      >
                        House Rules.
                      </a>
                    </span>
                  }
                ></Form.Check>
              </div>
              <div className="mb-3">
                <Form.Check
                  className="requirement"
                  // required
                  // error={errors.checkboxage}
                  type="checkbox"
                  value={checkboxage}
                  onChange={handleageCheckbox}
                  label="* I confirm that I am at least 18 years of age and that the information I have provided is accurate."
                ></Form.Check>
                {errors.checkbox && (
                  <div className="error-message">{errors.checkbox}</div>
                )}
              </div>
            </div>
            <div className="col-md-4 signup-btn-div">
              <Button
                className="signupButton"
                variant="primary"
                type="submit"
                onSubmit={Submithandler}
              >
                Create Account
              </Button>
            </div>
            <div className="col-md-12 responsible-alert">
              <div className="responsible-msg login-message">
                By Clicking "create account" you agree to receiving messages and
                offers from BettDraft.<br/>
                This is a real-money gambling app. Please gamble responsibly and
                only bet what you can afford.For <br/>
                gambling addiction help and
                support, please contact at{" "}
                <span className="telno">1-800-522-4700</span>, or visit
                <span>
                <a
                  className="red-link login-message blue-color"
                  href="https://www.ncpgambling.org"
                  target="_blank"
                >
                  {" "}
                  ncpgambling.org
                </a>
              </span>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <section className="signup-section">
      {isLoading && <Loader spinner={true} visible={isLoading} />}
      <div className="row justify-content-center main-section">
        <div className="col-12">
          <form onSubmit={handleNext}>{renderStep()}</form>
        </div>
      </div>
    </section>
  );
};

export default SignUpForm;
