import React, { useState, useRef, useEffect } from "react";
import "../Sportsbook.css";
import "../../../../src/css/main.css";
import { API_URL, sportEndPoint } from "../../../constant/Environment";
import { useSelector, useDispatch } from "react-redux";

import { fetchAllData, getAllBetData, getData } from "../../../Utility/API";
import { AiOutlineDollarCircle } from "react-icons/ai"
import { setCookie } from "../../../Utility/functions/Helper";
import NewPlayerCard from "../../../components/NewPlayerCard";
import { createBrowserHistory } from "history";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SPORTS } from "../../../constant/enum";
import {
  getSportImageUrl,
  getLeagueImageUrl,
  getMatchListData,
  getKeyPairList,
} from "../../../Utility/functions/Helper";
import { Actions } from "../../../redux/Actions/Actions";
import LabelWithImage from "../../../components/LabelWithImage";

import PromotionList from "../../PromotionList";
import { LeagueVisit, GameVisit } from "../../../Utility/API";
var leagueId = "";
var SportId = "";
var tabIndexValue = 0;
var tabdatevalue = 0;
var ActiveGameName = "";
let browsereventId = "";
let browserdate = "";
let browsermatch = "";
const PromotionSidebar = (props) => {
  let params = new URLSearchParams(window.location.search);
  let ref = params.get("ref") || "";
  let campaign = params.get("campaign") || "";
  if (ref) {
    setCookie("ref", ref, 30); // Set the cookie to expire in 30 days
  }
  if (campaign) {
    setCookie("campaign", campaign, 30); // Set the cookie to expire in 30 days
  }

  const history = createBrowserHistory();

  let deeplink = new URLSearchParams(window.location.search);

  browsereventId = deeplink.get("eventId") || "";
  browserdate = deeplink.get("date")?.replace(/,(\w{3})(\d+)/, ", $1 $2") || "";
  browsermatch = deeplink.get("match")?.replace(/vs/g, " vs ") || "";

  const state = useSelector((state) => state);

  const { auth, sidebar } = state;
  const [value, setValue] = useState(0);
  const [teamValue, setTeamValue] = useState(0);
  const [dateIndex, setDateIndex] = useState(0);

  const [datevalue, setDatevalue2] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [playerList, setPlayerList] = useState([]);
  const [activeIndex, setActiveIndex] = useState("");
  const [activeTabName, setActiveTabName] = useState("");

  const [activeshortTabName, setActiveshortTabName] = useState("");

  const [activeMatchTabName, setActiveMatchTabName] = useState("");
  const [activeTabDate, setActiveTabDate] = useState("");

  const [selectedSportId, setSelectedSportId] = useState("");
  const [selectedLeagueId, setSelectedLeagueId] = useState("");
  const [tabList, setTabList] = useState([]);

  const [dateList, setDateList] = useState([]);
  const [matchTabList, setMatchTabList] = useState([]);
  const [eventList, setEventList] = useState([]);

  const game = useSelector((state) => state).sidebar.game;
  const selectedGameId = useSelector((state) => state).sidebar.gameId;
  const selectedGame = useSelector((state) => state).sidebar.game;

  // console.log('selectedGame',selectedGame)

  const [dateIndexvalue, setDateIndexvalue] = useState(0);
  // const [promoPage,setPromoPage] = useState(false);

  const[openlength,setopenLength] = useState(0);

  const promoPage = state.betslip.promoPage;

  console.log("promotion", window.location.pathname);

  const [foundleague, setFoundleague] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    tabIndexValue = newValue;
  };

  const handleDateChange = (event, newValue) => {
    tabdatevalue = newValue;
    tabIndexValue = 0;

    setDateIndex(newValue);
    setValue(0);
  };

  const handleTeamChange = (event, newValue) => {
    setTeamValue(newValue);
    setValue(0);
  };

  const navigate = useNavigate();

  const [sportsList, setSportsList] = useState([]);
  const [league, setLeague] = useState([]);
  const dispatch = useDispatch();

  const [active, setactive] = useState(0);

  const statecode = state?.auth?.isAuthenticated
    ? state?.auth?.stateInfo?.stateCode
    : "";
  const statename = state?.auth?.isAuthenticated
    ? state?.auth?.stateInfo?.stateName
    : "";

  useEffect(() => {
    (async () => {
      let url = `${sportEndPoint.api.leagueList}`;
      const PopularLeague = await getAllBetData(url);

      // const filteredData = PopularLeague.filter(item => item.location !== statecode);

      const filteredData = PopularLeague.filter((item) => {
        if (Array.isArray(item.location)) {
          return !item.location.includes(statecode);
        } else {
          return item.location !== statecode;
        }
      });

      // console.log("filteredData",filteredData,PopularLeague);

      if (filteredData.length > 0) {
        leagueId = filteredData[0]?.id;
        setSelectedLeagueId(filteredData[0]?.id);
      }

      setIsLoading(true);
      let updated = filteredData.map((elem) => ({
        ...elem,
        label: elem?.name?.replace(/\s/g, "").toLowerCase(),
        img: getLeagueImageUrl(elem?.name?.replace(/\s/g, "_").toLowerCase()),
      }));
      setLeague(updated);

      if (updated.length > 0) {
        if (browsereventId) {
          const foundLeague = filteredData.find(
            (league) => league.id == browsereventId
          );
          const mainidname = foundLeague ? foundLeague.name : "";

          if (foundLeague) {
            dispatch({
              type: Actions.SET_GAME,
              data: mainidname,
              Id: browsereventId,
            });
            ActiveGameName = mainidname;
            fetchPlayerData(browsereventId, "league", true);
          } else {
            // dispatch({
            //   type: Actions.SET_GAME,
            //   data: updated[0]?.name,
            //   Id: updated[0]?.id,
            // });
            ActiveGameName = updated[0]?.name;
            fetchPlayerData(updated[0]?.id, "league", false);
          }
        } else {
          // dispatch({
          //   type: Actions.SET_GAME,
          //   data: updated[0]?.name,
          //   Id: updated[0]?.id,
          // });
          ActiveGameName = updated[0]?.name;
          fetchPlayerData(updated[0]?.id, "league", false);
        }
      }

      let url1 = `${sportEndPoint.api.sports}`;
      const PopularSport = await getAllBetData(url1);
      let updatedSport = PopularSport.map((elem) => ({
        ...elem,
        label: elem?.name?.replace(/\s/g, "").toLowerCase(),
        img: getSportImageUrl(elem?.name?.replace(/\s/g, "_").toLowerCase()),
      }));

      setSportsList(updatedSport);
      if (filteredData.length === 0) {
        // dispatch({
        //   type: Actions.SET_GAME,
        //   data: updatedSport[0]?.name,
        //   Id: updatedSport[0]?.id,
        // });
        ActiveGameName = updatedSport[0]?.name;
        SportId = updatedSport[0]?.id;
        setSelectedSportId(updatedSport[0]?.id);
        fetchPlayerData(updatedSport[0]?.id, "sport");
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [statecode]);

  const fetchPlayerData = async (selectedGameId, name, isLeagueorSport) => {
    if (selectedGameId != "") {
      if (browsereventId && isLeagueorSport) {
        selectedGameId = browsereventId;
        let url =
          name == "league"
            ? `${
                sportEndPoint.apiBaseUrl +
                sportEndPoint.api.playerleagueData +
                selectedGameId
              }`
            : `${
                sportEndPoint.apiBaseUrl +
                sportEndPoint.api.playersportsData +
                selectedGameId
              }`;

        try {
          let result = await getData(url);
          setIsLoading(false);
          if (result?.events?.length > 0) {
            let gameName =
              name == "league"
                ? result?.events[0]?.league.name
                : result?.events[0]?.sport.name;

            if (gameName == ActiveGameName || gameName == "NCAA Basketball") {
              const datevalue = result.dateList.indexOf(browserdate);

              const eventsArray = result.events;

              // Method to group the result.events array with dates

              function filterArrayByDate(eventsArray, browserdate) {
                return eventsArray.filter(
                  (item) => item.displayDate === browserdate
                );
              }

              const resultArray = filterArrayByDate(eventsArray, browserdate);

              const matchtabvalue = resultArray.findIndex(
                (obj) => obj.shortEventName === browsermatch
              );

              // Since findIndex returns -1 when values is not found in array so applying condition

              setDateIndexvalue(datevalue != -1 ? datevalue : dateIndexvalue);

              tabIndexValue =
                matchtabvalue != -1 && datevalue != -1
                  ? matchtabvalue
                  : tabIndexValue;

              let dateList = getKeyPairList(result.dateList);

              let matchList =
                datevalue != -1
                  ? getMatchListData(result.events, dateList[datevalue].name)
                  : getMatchListData(result.events, dateList[0].name);

              const findmatch = result.events.find(
                (league) => league.shortEventName == browsermatch
              );

              const mainmatch =
                findmatch && datevalue ? findmatch.eventName : "";

              setActiveMatchTabName(
                mainmatch && datevalue != -1
                  ? mainmatch
                  : matchList[tabIndexValue].name
              );
              setActiveshortTabName(browsermatch);
              setMatchTabList(matchList);
              setDateList(dateList);
              setPlayerList(result.events);
              setActiveTabName(
                datevalue != -1 ? browserdate : dateList[tabdatevalue].name
              );
            } else {
            }
          } else {
            if (result.status == "SUCCESS") {
              setPlayerList([]);
              setMatchTabList([]);
              setDateList([]);
              setActiveTabName("");
              setActiveMatchTabName("");
            }
          }
        } catch (error) {
          setIsLoading(false);
          console.error("Error:", error);
        }
      } else {
        let url =
          name == "league"
            ? `${
                sportEndPoint.apiBaseUrl +
                sportEndPoint.api.playerleagueData +
                selectedGameId
              }`
            : `${
                sportEndPoint.apiBaseUrl +
                sportEndPoint.api.playersportsData +
                selectedGameId
              }`;

        try {
          let result = await getData(url);
          setIsLoading(false);
          if (result?.events?.length > 0) {
            let gameName =
              name == "league"
                ? result?.events[0]?.league.name
                : result?.events[0]?.sport.name;

            if (gameName == ActiveGameName || gameName == "NCAA Basketball") {
              let dateList = getKeyPairList(result.dateList);
              let matchList = getMatchListData(
                result.events,
                dateList[tabdatevalue].name
              );
              setActiveMatchTabName(matchList[tabIndexValue].name);
              setActiveshortTabName(matchList[tabIndexValue].shortname);
              setMatchTabList(matchList);
              setDateList(dateList);
              setPlayerList(result.events);
              setActiveTabName(dateList[tabdatevalue].name);
            } else {
            }
          } else {
            if (result.status == "SUCCESS") {
              setPlayerList([]);
              setMatchTabList([]);
              setDateList([]);
              setActiveTabName("");
              setActiveMatchTabName("");
            }
          }
        } catch (error) {
          setIsLoading(false);
          console.error("Error:", error);
        }
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (selectedLeagueId || leagueId) {
        let id = leagueId;
        fetchPlayerData(id, "league");
      } else if (selectedSportId || SportId) {
        let id = SportId;
        fetchPlayerData(id, "sport");
      }
    }, SPORTS.GameTimeOut);
    return () => clearInterval(timer);
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const navigateToPromo = () => {
    navigate("/promotions");

    dispatch({
      type: Actions.SET_GAME,
      data: "promo",
      Id: "1",
    });
  };

  const navigateToEntries = () => {

    if(state.auth.isAuthenticated){
     navigate("/my-entries");
     dispatch({
       type: Actions.TEST_CASE,
       data: false,
     });
    }
    else{
     
    }
   };

  function handleAllsports(item, index, name) {
    LeagueVisit(state?.auth?.user?.idUser || "", item.name);

    history.replace("/");

    navigate("/");

    deeplink.delete("eventId");
    deeplink.delete("date");
    deeplink.delete("match");

    if (browsereventId || browserdate || browsermatch) {
      browsereventId = null;
      browserdate = null;
      browsermatch = null;
    }

    ActiveGameName = item?.name;

    dispatch({
      type: Actions.SET_GAME,
      data: item?.name,
      Id: item?.id,
    });
    tabdatevalue = 0;
    tabIndexValue = 0;
    setTabList([]);
    setPlayerList([]);
    setMatchTabList([]);
    setDateList([]);
    setActiveTabName("");
    setActiveMatchTabName("");
    setIsLoading(true);

    if (name == "sport") {
      setSelectedSportId(item.id);
      SportId = item.id;
      leagueId = "";
      setSelectedLeagueId("");
      fetchPlayerData(item.id, "sport");
    } else if (name == "league") {
      setSelectedLeagueId(item.id);
      leagueId = item.id;
      SportId = "";
      setSelectedSportId("");
      fetchPlayerData(item.id, "league");
    }
  }

  const clearall = () => {
    setSearchQuery("");
  };

  const pathname = window.location.pathname;

  const geo_access = state?.auth?.geoWallet;

  const geo_response = state?.auth?.geoResponse;

  const email_popup = state?.auth?.user?.email_popup;

  const Promostyles = {
    fontSize: "15px",
    fontFamily: "inter",
    fontWeight: 700,
    color: "white",
  };

  useEffect(()=>{
    fetchplayerOpen(state?.auth?.user?.idUser);
  })

  const fetchplayerOpen = (userId) => {
    let url = `${sportEndPoint.apiBaseUrl}${sportEndPoint.api.PENDING_BETS}/${userId}`; //&status=PENDING

    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        setopenLength(Object.keys(response)?.length);
      });
  };

  const gamevisit = (name) => {
    GameVisit(state?.auth?.user?.idUser || "", name.shortname);
  };

  return (
    <div fluid style={{ display: "flex", marginTop: "-7px" }}>
      <div
        className="col-md-2 col-lg-2 hide-ipad "
        style={{
          marginTop: pathname === "/" ? "5px" : "5px",
          marginLeft: "0px",
        }}
      >
        <div className="space-sidebar mt-1">
          <div>
            <div>
              <div className="sidebar-header">
                <h2
                  style={{
                    color: "#0D3862!important",
                    marginTop: "10px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    marginBottom: "15px!important",
                  }}
                >
                  Sports
                </h2>
              </div>
              <div className="sidebar-link-div">
                {league.map((ele, index) => {
                  return (
                    <div
                      className="sideBar-game"
                      onClick={() => handleAllsports(ele, index, "league")}
                    >
                      <LabelWithImage
                        data={ele}
                        onClick={() => handleAllsports(ele, index, "league")}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={"sidebar-Sportbook "}>
            <div
              className={
                auth.isAuthenticated && !sidebar.isSideBarOpen
                  ? "space-sidebar-collapse"
                  : "sidebar-Sportbook-without-coll"
              }
            >
              <div className="sidebar-header"></div>
              <div className="sidebar-link-div">
                {sportsList.map((ele, index) => {
                  return (
                    <div
                      className="sideBar-game"
                      onClick={() => handleAllsports(ele, index, "sport")}
                    >
                      <LabelWithImage
                        data={ele}
                        onClick={() => handleAllsports(ele, index, "sport")}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* comment the code for future release */}
        <div className="space-sidebar mt-2">
          <div className="">
            <div className="sideBar-game">
              <div
                className={"link-div-Sportbook pt-2 side-promo-link-selected"}
                onClick={() => navigateToPromo()}
              >
                <div className="promo-icon">
                  <AiOutlineDollarCircle size={20} color={"#FFFF"} />
                </div>

                <div className="Sport-count-name">
                  <span style={Promostyles}> {"Promotions"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="space-sidebar mt-2">
          <div className="">
            <div className="sideBar-game">
              <div
                className={"link-div-Sportbook pt-2"}
                onClick={() => navigateToEntries()}
                style={{display:"flex",justifyContent:"space-between"}}
              >
                {/* <div className="promo-icon">
                  <AiOutlineDollarCircle size={20} color={"#06457b"} />
                </div> */}

                <div className="Sport-count-name">
                  <span style={{color:"#27284D",fontSize:"15px",fontWeight:"700"}}> {"My Entries"}</span>
                </div>

                {state.auth.isAuthenticated?<div className="open-count">
                <span style={{color:"#C02640",fontSize:"15px",fontWeight:"700",textAlign:"end"}}> {openlength}</span>
                </div>:""}

              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="col-md-10 mainplayerbox mt-4 ipad-w100">
        <PromotionList />
      </div>
    </div>
  );
};

export default PromotionSidebar;
