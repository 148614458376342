import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function InfoModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton  onClick={props.onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
         Location Software Required
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    
        <p>
          1. Click Download to install "BettDraft Player Location Check" software on
          your device.
          <br></br>
          2. Locate the file in your downloads folder and double click to
          install.
          <br></br>
          3. Follow the instructions and once installed ensure PLC is running
          then login to continue playing.
        </p>
      </Modal.Body>

      <Modal.Footer>
         
        <Button
                className="login-btn-form"
                variant="primary"
                onClick={props.onSubmit}
              >
                Download
              </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InfoModal;
// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }
