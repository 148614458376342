import React from "react";
import "./Faq.css";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import { Form, Button, Accordion, Table } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { Helmet } from "react-helmet";
import desktop from "../../image/device/desktop.png";
import android from "../../image/device/android.png";
import ios from "../../image/device/apple.png";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { PageVisit } from "../../Utility/API";

const Faq = () => {
  const state = useSelector((state) => state);

  useEffect(() => {
    PageVisit(state?.auth?.user?.idUser, "Faq");
  }, []);

  return (
    <div>
      <Helmet>
        <title>FAQ's | BettDraft Fantasy Sports</title>

        <meta
          name="description"
          content="Check our FAQ's section and learn more about BettDraft."
        />

        <link rel="canonical" href="https://dfs.bettdraft.com/faq" />

        <script type="application/ld+json">
          {`
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What are the requirements to open an account with Bettdraft?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "To sign up and join BettDraft, you need to meet certain requirements:

Currently, BettDraft is available for residents of Texas and Illinois. To create an account with BettDraft, you must be physically located within one of these Authorized Jurisdictions, be 18 years of age or older and not be classified as a Prohibited person, self-excluded, or otherwise excluded by applicable laws or regulations.

To ensure eligibility, we may require individuals to provide the necessary documentation, such as a state-issued ID and/or Passport, as part of the account verification process.

Only eligible individuals who meet the requirements mentioned above are permitted to enter contests on BettDraft."
    }
  },{
    "@type": "Question",
    "name": "What states can I play from?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "At BettDraft, we're currently accepting customers from the states of Texas and Illinois."
    }
  },{
    "@type": "Question",
    "name": "How can I stay updated on when BettDraft will be available in my state?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "To stay informed and be the first to know when BettDraft goes live in your state, we recommend signing up for our email waiting list. By joining the waiting list, you will receive a confirmation email once we launch in your state. It's the best way to stay connected and ensure you don't miss out on the action."
    }
  },{
    "@type": "Question",
    "name": "What kind of information does BettDraft collect from Visitors and Players?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "At BettDraft, we collect various types of information, including:

Personal data to validate that you are of legal age and that you're permitted to have an account at BettDraft. Information about your internet connection and the equipment you use to access our Services is collected. This helps us optimize your experience and ensure smooth functionality. We may collect the location of you or your device when accessing certain services or sections of our site or app as required by law."
    }
  },{
    "@type": "Question",
    "name": "Can I edit my personal information?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "To review or modify your personal information, you can access your account profile page. You can only modify your email, physical address and password. You may also request access to, correction or deletion of any personal information by emailing us. However, we may decline a request to change information if we believe it would violate any legal requirement or cause the information to be incorrect."
    }
  },{
    "@type": "Question",
    "name": "What is a contest at BettDraft?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A contest at BettDraft is based on building a team comprised of real-life players from professional sports leagues. These teams are constructed based on strategical results and predictions made by each player and their performance in an individual game."
    }
  },{
    "@type": "Question",
    "name": "What is an entry fee?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "An entry fee refers to the amount paid by participants to enter a contest on BettDraft. Entry fees and prize pools can vary depending on the number of players on your team."
    }
  },{
    "@type": "Question",
    "name": "What is the minimum entry fee?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "BettDraft determines the minimum entry fees for all contests, which may vary on a market-by-market or patron-by-patron basis. The minimum entry is $1"
    }
  },{
    "@type": "Question",
    "name": "What is the maximum entry fee?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "BettDraft determines the maximum entry fees for all contests, which may vary on a market-by-market or patron-by-patron basis. The maximum entry is $100 but may vary by contest."
    }
  },{
    "@type": "Question",
    "name": "How do I build my virtual teams?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "To build your virtual team: Find and select your players, click their stat and it will automatically add to your team. Add a minimum of 2 and up to 10 players. You can add players from multiple teams, multiple games and even multiple sports."
    }
  },{
    "@type": "Question",
    "name": "What sports are available for fantasy contests at BettDraft?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "BettDraft offers a wide range of sports for fantasy contests. You can choose from the following sports:

Football

Basketball

Baseball

Hockey

Soccer

Golf

Tennis

Cricket

NASCAR

Formula 1

Boxing

MMA


Not all sports are available through oughtthroughout the year, some may be available during the season. Please see the site for current sports listings."
    }
  },{
    "@type": "Question",
    "name": "How can I find the payout amounts for a contest?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Payout amounts are determined in the entry fee and number of players selected in your team, once you build your team and select the entry fee amount. The payout amount will be clearly displayed prior to you submitting your entry. These are also displayed in the My bets section for existing bets."
    }
  },{
    "@type": "Question",
    "name": "How are payouts credited to my BettDraft account?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Payouts are directly credited to your BettDraft Account Balance. Once the contest concludes and the results are finalized, the payout amounts will be reflected in your account balance."
    }
  },{
    "@type": "Question",
    "name": "How can I find my completed contests?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "To find your completed contests on BettDraft, simply:

Go to the top right side of the website and click on your profile icon.
From the dropdown menu, select My Entries.
Within the My Entries section, you'll find tabs for Open Entries and Past Entries.
Click on Open entries for pending and recently placed entries or, on the Past Entries tab to view your completed contests."
    }
  },{
    "@type": "Question",
    "name": "What are the terms and condition for the Ticket Giveaway promotion?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The terms and conditions for the Tickets Giveaway are:

The offer is available for all eligible customers.
Blocked or terminated accounts are automatically disqualified
Only one account is permitted to participate per person. Any attempt to open multiple accounts will render all accounts invalid.
Only available to customers who are over 18 years of age.
Participants must be located within AK, CA, DC, GA, IL, KS, KY, MN, ND, NE, NM, OK, OR, RI, SC, SD, TX, UT or WI, to submit a contest entry and participate in the giveaway.
Each contest entry of $20 or more will earn you an entry into the ticket giveaway.
Voided contests will not qualify for the giveaway entries
Each entry is assigned a unique ID. One entry will be selected at random and the owning account holder will be contacted
Once the winner is announced, BettDraft will reach out to coordinate the purchase and delivery of the tickets
The maximum value per ticket is of $250 for a total cost amount of $500.
Winners may select the sporting or entertainment event they would like tickets for. Availability and limitations may apply.
BettDraft reserves the right to request additional information or documentation prior to the delivery of the tickets.
By accepting this promotion, you agree to the full site terms and conditions, privacy policy and house rules.
BettDraft reserves the right to void the won tickets at any time and without prior notice or reason.
BettDraft reserves the right to amend or terminate this promotion at any time and without prior notice.
Offer valid through November 2023."
    }
  },{
    "@type": "Question",
    "name": "Why do I need do install a Geo Location software to play at BettDraft?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "BettDraft is required by All State regulators to confirm your location before accessing and entering contests. To ensure you're located in a state that is permitted for contest entries:

Install the location check software. For computers, check the download folders. Depending on your mobile device, you may need to install an application provided by our partner GeoComply.
Follow the instructions. You may need to allow access to your device's locations services for the software to work correctly
Once the installation is complete and your location confirmed, you'll be able to login to your account and start entering contests."
    }
  },{
    "@type": "Question",
    "name": "What are the terms and conditions for the $10 Free sign-up bonus?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The terms and conditions for the $10 Free sign-up bonus are:

This offer is available for new customers only.
Only one account is permitted per person. Any attempt to open multiple accounts will render all accounts invalid.
Only available to customers who are over 18 years of age.
Customers must be in the states of AK, CA, DC, GA, IL, KS, KY, MN, ND, NE, NM, OK, OR, RI, SC, SD, TX, UT or WI to submit a contest entry.
$10 credit is instantly credited to your account on registration.
Any unused credits will be forfeited if not used after 30 days.
Bonus funds have no cash value and cannot be withdrawn.
BettDraft reserves the right to remove or expire bonus funds at any time and without prior notice or reason.
Any winnings from bonus funds will be paid to your cash balance.
In order to withdraw any winnings, you will need to register and validate your wallet by providing government issued identification.
BettDraft reserves the right to request additional information or documentation prior to withdrawal.
By accepting this promotion, you agree to the full site terms and conditions, privacy policy and house rules.
BettDraft reserves the right to amend or terminate this promotion at any time and without prior notice."
    }
  },{
    "@type": "Question",
    "name": "What are the terms and conditions for the 100% Matchup bonus?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The terms and conditions for the 100% Match-up bonus are:

This offer is available for your firstdeposit only.
Only one account is permitted per person. Any attempt to open multiple accounts will render all accounts invalid.
Only available to customers who are over 18 years of age.
Customers must be in the states ofAK, CA, DC, GA, IL, KS, KY, MN, ND, NE, NM, OK, OR, RI, SC, SD, TX, UT or WI to deposit funds.
The Match-up bonus is capped to $100. Deposits higher than the cap will receive a maximum bonus credit of $100.
The bonus will be credited 24 hours after a qualifying deposit.
Withdrawal of your initial deposit prior to completing a minimum play of your bonus funds value plus the matched deposit value will render the bonus invalid and the bonus, plus any associated winnings from play using these bonus funds may be forfeited.
Any unused bonus credits will be forfeited if not used within 30 days.
Bonus funds have no cash value and cannot be withdrawn.
BettDraft reserves the right to remove or expire bonus funds at any time and without prior notice or reason.
Any winnings from bonus funds will be paid to your cash balance.
BettDraft reserves the right to request additional information or documentation prior to withdrawal.
By accepting this promotion, you agree to the full site terms and conditions, privacy policy and house rules.
BettDraft reserves the right to amend or terminate this promotion at any time and without prior notice."
    }
  }]
}
`}
        </script>
      </Helmet>
      <div className="container bg-color space-bottom">
        <div className="inner-smallspace">
          <div className="row" style={{ marginTop: "35px!important" }}>
            <div className="col-md-12">
              <div className="content-page-design">
                <h2 style={{ fontSize: "26px" }}>Frequently asked questions</h2>
              </div>
              <div className="custom-collapse-div">
                <Accordion defaultActiveKey={["0"]} alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h4>
                        What are the requirements to open an account with
                        Bettdraft?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        To sign up and join BettDraft, you need to meet certain
                        requirements:
                      </p>
                      <p>
                        <li>
                          To create an account with BettDraft, you must be
                          physically located within one of the Authorized
                          Jurisdictions (Alaska, California, District of
                          Columbia, Georgia, Illinois, Kansas, Kentucky,
                          Minnesota, North Dakota, Nebraska, New Mexico,
                          Oklahoma, Oregon, Rhode Island, South Carolina, South
                          Dakota, Texas, Utah, Wisconsin & Vermont) be 18 years
                          of age or older and not be classified as a Prohibited
                          person, self-excluded, or otherwise excluded by
                          applicable laws or regulations.
                        </li>
                        <br />
                        <li>
                          To ensure eligibility, we may require individuals to
                          provide the necessary documentation, such as a
                          state-issued ID and/or Passport, as part of the
                          account verification process.
                        </li>
                        <br />
                        <li>
                          Only eligible individuals who meet the requirements
                          mentioned above are permitted to enter contests on
                          BettDraft.
                        </li>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h4>What states can I play from?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        At BettDraft, we're currently accepting customers from
                        the states of Alaska, California, District of Columbia,
                        Georgia, Illinois, Kansas, Kentucky, Minnesota, North
                        Dakota, Nebraska, New Mexico, Oklahoma, Oregon, Rhode
                        Island, South Carolina, South Dakota, Texas, Utah,
                        Wisconsin & Vermont.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h4>
                        Will BettDraft expand its coverage to other states?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Absolutely! We have plans to expand our coverage to many
                        other states in the next few months and bring the thrill
                        of BettDraft to sports fans across the country.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <h4>
                        What kind of information does BettDraft collect from
                        Visitors and Players?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        At BettDraft, we collect various types of information,
                        including: personal data to validate that you are of
                        legal age and that you're permitted to have an account
                        at BettDraft; information about your internet connection
                        and the equipment you use to access our Services to help
                        us optimize your experience and ensure smooth
                        functionality; and the location of you or your device
                        when accessing certain services or sections of our site
                        or app as required by law.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <h4>Can I edit my personal information?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        To review or modify your personal information, you can
                        access your account profile page. You can only modify
                        your email, physical address and password. You may also
                        request access to, correction of, or deletion of any
                        personal information by emailing us. However, we may
                        decline a request to change information if we believe it
                        would violate any legal requirement or cause the
                        information to be incorrect.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <h4>What is a contest at BettDraft?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        A contest at BettDraft is based on building a Roster
                        comprised of real-life players from professional sports
                        leagues. These Roster are constructed based on predicted
                        results made by each user.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      <h4>What is an entry fee?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        An entry fee refers to the amount paid by users to enter
                        a contest on BettDraft. Entry fees and prize pools can
                        vary depending on the number of players on your Roster.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      <h4>What is the minimum entry fee?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        BettDraft determines the minimum entry fees for all
                        contests, which may vary on a market-by-market or
                        patron-by-patron basis. 
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      <h4>What is the maximum entry fee</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        BettDraft determines the maximum entry fees for all
                        contests, which may vary based on the market, contest,
                        or user.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      <h4>How do I build my Roster?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Please see <a href="/how-to-play">how to play</a> for
                        more information on Roster construction.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="11">
                    <Accordion.Header>
                      <h4>
                        What sports are available for fantasy contests at
                        BettDraft?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        BettDraft offers a wide range of sports for fantasy
                        contests. You can choose from the following:{" "}
                      </p>

                      <p>
                        <li>NBA</li>
                        <li>MLB</li>
                        <li>NHL</li>
                        <li>NFL</li>
                        <li>EPL</li>
                        <li>MLS</li>
                        <li>UEFA Champions League</li>
                        <li>PGA</li>
                        <li>Tennis</li>
                        <li>Cricket</li>
                        <li>NASCAR</li>
                        <li>Formula 1</li>
                        <li>Boxing</li>
                        <li>MMA</li>
                        <li>UFC</li>
                      </p>

                      <p>
                        Not all sports are available throughout the year. Please
                        see the site for current sports offerings.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="13">
                    <Accordion.Header>
                      <h4>How can I find the payout amounts for a contest?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Payout amounts are determined based on the entry fee and
                        the number of players selected in your Roster. The
                        payout amount will be clearly displayed prior to entry
                        submission. These are also displayed in the "My bets"
                        section for existing bets.{" "}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="14">
                    <Accordion.Header>
                      <h4>How are payouts credited to my BettDraft account?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Payouts are directly credited to your BettDraft Account
                        Balance. Once the contest concludes and the results are
                        finalized, any payouts will be reflected in your account
                        balance.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="15">
                    <Accordion.Header>
                      <h4>How are payouts calculated?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table
                        className="content-table table-bordered"
                        style={{ width: "100%", border: "1px solid black" }}
                      >
                        <thead>
                          <tr>
                            <th>Players Selected</th>
                            <th>Min Correct for a Payout</th>
                            <th>
                              Payout Option 1<br /> No. Correct | Entry Multiply
                            </th>
                            <th>
                              Payout Option 2<br /> No. Correct | Entry Multiply
                            </th>
                            <th>
                              Payout Option 3<br /> No. Correct | Entry Multiply
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>2</td>
                            <td>2</td>
                            <td>2 - 3X</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>3</td>
                            <td>3 - 4X</td>
                            <td></td>
                            {/* <td>2 - 1.25X</td> */}
                            <td></td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>3</td>
                            <td>4 - 5X</td>
                            <td>3 - 1X</td>
                            <td></td>
                            {/* <td>2 - 1X</td> */}
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>4</td>
                            <td>5 - 10X</td>
                            <td>4 - 1.25X</td>
                            <td></td>
                            {/* <td>3 - 1X</td> */}
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>4</td>
                            <td>6 - 25X</td>
                            <td>5 - 2X</td>
                            <td>4 - 0.5X</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>5</td>
                            <td>7 - 40X</td>
                            <td>6 - 4X</td>
                            <td>5 - 0.75X</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>6</td>
                            <td>8 - 60X</td>
                            <td>7 - 5X</td>
                            <td>6 - 1X</td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>7</td>
                            <td>9 - 80X</td>
                            <td>8 - 6X</td>
                            <td>7 - 1X</td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>8</td>
                            <td>10 - 125X</td>
                            <td>9 - 7X</td>
                            <td>8 - 1.5X</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="16">
                    <Accordion.Header>
                      <h4>How can I find my completed contests?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        To find your completed contests on BettDraft, simply:
                      </p>

                      <p>
                        <li>
                          Go to the top right side of the website and click on
                          your profile icon.
                        </li>
                        <li>From the dropdown menu, select "My Entries".</li>
                        <li>
                          Within the "My Entries" section, you'll find tabs for
                          "Open Entries" and "Past Entries."
                        </li>
                        <li>
                          Click on “Open entries” for pending and recently
                          placed entries or, on the "Past Entries" tab to view
                          your completed contests.
                        </li>
                        <br />
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="17">
                    <Accordion.Header>
                      <h4>
                        What is the Refer-a-Friend promotion and how does it
                        work?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        BettDraft's Refer-a-Friend promotion rewards customers
                        for their referrals. You'll receive a $20 bonus credit,
                        and your referral will receive a $10 bonus credit. To
                        qualify, your account must have a minimum of one deposit
                        and one contest entry. Once we confirm the information,
                        your account will receive a $20 credit to draft a
                        Roster.
                      </p>
                      <p>Terms and conditions:</p>
                      <p>
                        Available to customers who are over 18 years of age.
                        Only one account is permitted per person. Any attempt to
                        open multiple accounts will render all accounts invalid.
                        $20 one-time credit for the referrer will be credited
                        after the referral account has at least one deposit and
                        successfully entered a contest. A $10 one-time credit
                        for the referral will be credited once the referral
                        account has at least one deposit and successfully
                        entered a contest. To refer a friend you have to be an
                        active customer with at least one deposit and contest
                        entry Customer must be located in an allowed state
                        (Alaska, California, District of Columbia, Georgia,
                        Illinois, Kansas, Kentucky, Minnesota, North Dakota,
                        Nebraska, New Mexico, Oklahoma, Oregon, Rhode Island,
                        South Carolina, South Dakota, Texas, Utah, Wisconsin &
                        Vermont) to submit a contest entry.
                      </p>
                      <p>
                        Bonus funds have no cash value and cannot be withdrawn.
                        BettDraft reserves the right to remove or expire bonus
                        funds at any time and without prior notice or reason.
                        BettDraft reserves the right to amend or terminate this
                        promotion at any time and without prior notice.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="20">
                    <Accordion.Header>
                      <h4>
                        What are the terms and conditions for the $10 Free
                        sign-up bonus?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        The terms and conditions for the $10 Free sign-up bonus
                        are:
                      </p>

                      <p>
                        This offer is available for new customers only. Only one
                        account is permitted per person. Any attempt to open
                        multiple accounts will render all accounts invalid. Only
                        available to customers who are over 18 years of age.
                      </p>

                      <p>
                        Customers must be in an authorized state (Alaska,
                        California, District of Columbia, Georgia, Illinois,
                        Kansas, Kentucky, Minnesota, North Dakota, Nebraska, New
                        Mexico, Oklahoma, Oregon, Rhode Island, South Carolina,
                        South Dakota, Texas, Utah, Wisconsin & Vermont) to
                        submit a contest entry. $10 credit is instantly credited
                        to your account upon registration. Any unused credits
                        will be forfeited if not used after 30 days. Bonus funds
                        have no cash value and cannot be withdrawn. BettDraft
                        reserves the right to remove or expire bonus funds at
                        any time and without prior notice or reason.BettDraft
                        reserves the right to amend or terminate this promotion
                        at any time and without prior notice.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="21">
                    <Accordion.Header>
                      <h4>
                        What are the terms and conditions for the 100% Matchup
                        bonus?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        The terms and conditions for the 100% Match Up bonus
                        are:
                      </p>

                      <p>
                        This offer is available for your first deposit only.
                        Only one account is permitted per person. Any attempt to
                        open multiple accounts will render all accounts invalid.
                        Only available to customers who are over 18 years of
                        age. Customers must be in an allowed state (Alaska,
                        California, District of Columbia, Georgia, Illinois,
                        Kansas, Kentucky, Minnesota, North Dakota, Nebraska, New
                        Mexico, Oklahoma, Oregon, Rhode Island, South Carolina,
                        South Dakota, Texas, Utah, Wisconsin & Vermont) to
                        deposit funds. The Match Up bonus is limited to $100.
                        Deposits higher than the cap will receive a maximum
                        bonus credit of $100. The bonus will be credited within
                        24 hours after a qualifying deposit.{" "}
                      </p>

                      <p>
                        Withdrawal of your initial deposit before completing a
                        minimum play of your bonus funds value plus the matched
                        deposit value will render the bonus invalid and the
                        bonus, plus any associated winnings from play using
                        these bonus funds may be forfeited. Any unused bonus
                        credits will be forfeited if not used within 30 days.
                        Bonus funds have no cash value and cannot be withdrawn.
                      </p>

                      <p>
                        BettDraft reserves the right to remove or expire bonus
                        funds at any time and without prior notice or reason.
                        Any winnings from bonus funds will be paid to your cash
                        balance. BettDraft reserves the right to amend or
                        terminate this promotion at any time and without prior
                        notice.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="22">
                    <Accordion.Header>
                      <h4>How does the Deposit $20, get $10 promotion work?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        This offer is available between March 20th , 2024 to
                        April 8th, 2024. The offer will be valid on a reload
                        deposit only. Only one account is permitted per person.
                        Maximum bonus reward per person is limited to $10. Bonus
                        will be credited within 24 hours after a qualifying
                        deposit. Withdrawal of your participating deposit prior
                        to use of the bonus funds will result in the bonus funds
                        being forfeited. Any unused bonus credits will be
                        forfeited if not used within 30 days. Bonus funds have
                        no cash value and cannot be withdrawn.
                      </p>
                      <p>
                        BettDraft reserves the right to remove or expire bonus
                        funds at any time and without prior notice or reason.
                        Any winnings from bonus funds will be paid to your cash
                        balance. BettDraft reserves the right to amend or
                        terminate this promotion at any time and without prior
                        notice. House rules and terms and conditions apply to
                        this promotion.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="23">
                    <Accordion.Header>
                      <h4>
                        What are the terms and conditions for the Submit $15
                        entry, get $5 back promotion?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        When you submit an entry for a contest between March
                        20th, 2024 and April 8th, 2024 with the minimum value of
                        $15, you will receive a $5 free play on the next 24-48
                        hours. Voided contests will not qualify for the
                        promotion.
                      </p>
                      <p>
                        Any unused bonus credits will be forfeited if not used
                        within 30 days. Bonus funds have no cash value and
                        cannot be withdrawn. BettDraft reserves the right to
                        remove or expire bonus funds at any time and without
                        prior notice or reason. Any winnings from bonus funds
                        will be paid to your cash balance. BettDraft reserves
                        the right to amend or terminate this promotion at any
                        time and without prior notice. House rules and terms and
                        conditions apply to this promotion.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="24">
                    <Accordion.Header>
                      <h4>How do Promo Codes work in BettDraft?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        When you enter a promo code at signup, you may qualify
                        for a free play bonus. In order to qualify for a free
                        play, the promo code must be valid and entered during
                        the validity period. Expired Promo Codes will not credit
                        a free play.
                      </p>
                      <p>
                        Any unused bonus credits will be forfeited if not used
                        within 30 days. Bonus funds have no cash value and
                        cannot be withdrawn. BettDraft reserves the right to
                        remove or expire bonus funds at any time and without
                        prior notice or reason. Any winnings from bonus funds
                        will be paid to your cash balance. BettDraft reserves
                        the right to amend or terminate this promotion at any
                        time and without prior notice. House rules and terms and
                        conditions apply to this promotion.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
