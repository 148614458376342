import React, { useEffect } from "react";
import "./style.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FaSquareMinus } from "react-icons/fa6";
import { BsPlusSquareFill } from "react-icons/bs";
import { FcCancel } from "react-icons/fc";
import { BsChevronLeft } from "react-icons/bs";
import { BsChevronRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import { PaymentEndpoints, endPoints } from "../../../constant/Environment";
import { getAllData } from "../../../Utility/API";
import Loader from "../../../components/Loader";
import Toaster from "../../../components/Toast";
import { SuccesToast, ErrorToast } from "../../../components/Toast/message";
import { useNavigate } from "react-router-dom";
import { addData, addBetData } from "../../../Utility/API";
import { useDispatch } from "react-redux";
import { Actions } from "../../../redux/Actions/Actions";
import { Modal,Col,Row,Button,Form } from "react-bootstrap";
import moment from "moment-timezone";
import ReactSpeedometer from "react-d3-speedometer";
import { updateBetUser } from "../../../Utility/API";
import { SkrillEnv } from "../../../constant/SkrillEnv";
import { isMobile } from "react-device-detect";
import { display } from "@mui/system";
import { formatAmount } from "../../../Utility/functions/Helper";
import { PageVisit } from "../../../Utility/API";
import { getIpAddress } from "../../../Utility/ConfigData";

const UserWallet = () => {
  const state = useSelector((state) => state);
  const [amt, setAmt] = useState("");
  const [withdrawAmt, setWithDrawAmt] = useState("");
  const [isLoading, setIsLoadinig] = useState(false);
  const[visible,setvisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Skrill');
  const[acno , setacno] = useState("");
  const[cacno , setcacno] = useState("");
  const[achamount , setachamount] = useState("");
  const[routing , setrouting] = useState("");
  const[betlimit,setbetlimit] = useState(0);
  const[remainingbetlimit,setremainingbetlimit] = useState(0);
  const[depositlimit,setdepositlimit] = useState(0);
  const[remaingdepositlimit,setremaingdepositlimit] = useState(0);
  const[withdrawAlert,setwithdrawAlert] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [fwdId, setFwdID] = useState(state?.auth?.user?.account?.fwUserId || "");
  const[depositAmt , setdepositAmt] = useState("");
  const [name, setName] = useState(state?.auth?.user?.name || "");
  const [email, setEmail] = useState(state?.auth?.user?.email || "");
  const [surname, setSurname] = useState(state?.auth?.user?.surname || "");
  const [date, setDate] = useState(state?.auth?.user?.birthDate || "");
  const [nation, setNation] = useState(state?.auth?.user?.nation || "");
  const [country, setCountry] = useState(state?.auth?.user?.country || "");
  const [zipcode, setZipcode] = useState(state?.auth?.user?.zipCode || "");
  const [phoneNumber, setPhoneNumber] = useState(state?.auth?.user?.phone || "");
  const [states, setStates] = useState([]);
  const [add1, setAdd1] = useState("");
  const [Occupation, setOccupation] = useState("");
  const [SSN, setSSN] = useState(state?.auth?.user?.ssn || "");
  const [town, setTown] = useState(state?.auth?.user?.town || "");
  const [address, setAddress] = useState(state?.auth?.user?.address || "");
  const[address1 , setAddress1] = useState("");
  const[address2 , setAddress2] = useState("");
  const[febimodal,setfebimodal] = useState(false);
  const[playdate,setPlayDate] = useState("");
  const[depositdate,setDepositDate] = useState("")
  const[page,setPage] = useState(1);
  const[totalPages,setTotalPages] = useState(1);
  const[corepopup,setCorePopup] = useState(false);
  const[cardnumber,setCardnumber] = useState("");
  const [formattedCardNumber, setFormattedCardNumber] = useState("");
  const[expirydate,setExpiryDate] = useState("");
  const[cvv,setCvv] = useState("");
  const[zip,setZip] = useState("");
  const[corepayamt,setCorepayamt] = useState("");
  const[accountno,setAccountno] = useState("");
  const[rountingno,setRoutingno] = useState("");
  const[corewithdrawl,setCorewithdrawl] = useState(false);
  const navigate = useNavigate();

  const RandomRefNum = (prefix) => {
    const randomNum = Math.floor(Math.random() * 900000) + 100000;
    return `${prefix}_${randomNum}`;
  };

  let count = 5;

  const Ach_validation = state?.auth?.user?.account?.ach_Id;

  const withDrawSubmit = async (e) => {
    e.preventDefault();
    let url =  `${endPoints.apiBaseUrl}${endPoints.api.SKRILL_WITHDRAW}`;
    // const merchantRefNum = RandomRefNum("skrill_test");
    const merchantRefNum = RandomRefNum(`bettdraft_${state?.auth?.user?.idUser}`)
    setIsLoadinig(true);
    let data = {
      merchantRefNum: merchantRefNum,
      amount: withdrawAmt,
      customerIp: "49.36.215.4",
      street:  state?.auth?.user?.address,  
      city:state?.auth?.user?.stateName,
      zip: state?.auth?.user?.zipCode,
      state: state?.auth?.user?.stateCode,
      country:state?.auth?.user?.country,
      emailId: state?.auth?.user?.email,
      id_user: state?.auth?.user?.idUser,
      firstName:state?.auth?.user?.name,
      lastName:state?.auth?.user?.surname,
      phone: state?.auth?.user?.mobile || state?.auth?.user?.phone
    };
    try {
      let response = await addBetData(url, data);
      setIsLoadinig(false);
      if(response.data.status == 'success' ){
        SuccesToast(response.data.message);
        setWithDrawAmt("");
        GetUserById();
        GetTrans(1);
        setPage(1);
        setwithdrawAlert(false);
        
      }else if(response.data.status == 'failure' ){
        ErrorToast(response.data.message);
      }
      
      else{
        ErrorToast(response.data.message);
      }

    } catch (err) {
      setIsLoadinig(false);
      console.log('err',err)
    }
  };

  const dispatch = useDispatch();

  useEffect(()=>{
    PageVisit(state?.auth?.user?.idUser || "","userwallet");
  },[]);

  const Deposit = (amt, e) => {
    if (amt == "") {
      e.preventDefault();
      ErrorToast("Please enter a deposit amount to continue.");
    } else {
      e.preventDefault();
      const merchantRefNum = RandomRefNum(`bettdraft_${state?.auth?.user?.idUser}`);
      checkout(amt, merchantRefNum);
    }
  };

  const GetUserById = () => {
    if (state?.auth?.user?.idUser) {
      let url = `${endPoints.api.GET_BY_USERID}/${state?.auth?.user?.idUser}`;
      getAllData(url).then((response) => {
        dispatch({
          type: Actions.LOGIN,
          data: { user: response.data },
        });
      });
    }
  };

  // const GetTrans = () => {
  //   if (state?.auth?.user?.idUser) {
  //     let url = `${endPoints.api.transactions}/${state?.auth?.user?.idUser}`;
  //     getAllData(url)
  //       .then((response) => {
  //         if (response.status == "success") {
  //           setTransactionList(response.data);
  //         }
  //         //
  //       })
  //       .catch((err) => {
  //         console.log("errr get trrans");
  //       });
  //   }
  // };



  const GetTrans = async (no) => {
    try {
      let url = `${endPoints.api.transactions}/${state?.auth?.user?.idUser}/${no}/${count}`;
      setIsLoadinig(true);
      let response = await getAllData(url);
      setIsLoadinig(false);
      if (response.status == "success") {
        let data = response.data;
        if (data.length > 0) {
          // setTransactionList(prevData => [...prevData, ...data]);
          setTransactionList(data);
          const ITEMS_PER_PAGE = 5;
          const totalCount = data[0]?.totalcount;
          const totalPagesCount = Math.ceil(totalCount / ITEMS_PER_PAGE);
          setTotalPages(totalPagesCount);
        }
      }
    } catch (err) {
      setIsLoadinig(false);
      console.log(err);
    }
    finally{
      setIsLoadinig(false);
    }
  };


  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
      GetTrans(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
      GetTrans(page - 1);
    }
  };

    useEffect(()=>{
      GetTrans(1);
      GetUserById();
    },[]);

  const SkrillAdd = async (depositamount, referenceNo) => {
    let url = `${endPoints.apiBaseUrl}${endPoints.api.SKRILL_PAYMENT}`;
    setIsLoadinig(true);
    let data = {
      id_skrill_wallet_transaction: 0,
      merchantRefNum: referenceNo,
      id_user: state?.auth?.user?.idUser,
      amount: Number((depositamount * 100).toFixed(2)),
      currencyCode: "USD",
      id_payment: 0,
      paymentHandleToken: "string",
      txnTime: "string",
      status: "string",
      transaction_id: "string",
      pay_from_email: "string",
      pay_to_email: "string",
      merchant_id: "string",
      gateway_id: "string",
      sid: "string",
    };
    try {
      setIsLoadinig(true);
      let response = await addData(url, data);
      if (response.data.status == "success") {
        window.paysafe.checkout.setup(
          API_KEY_MERCHANT_TEST,
          {
            currency: "USD", // 4 currencies currently supported by Paysafe Checkout - "USD", "CAD", "EUR", "GBP"
            amount: Number((depositamount * 100).toFixed(2)), // This qualifies as USD $ 100.00. Multiply Payment Amount by 100 and supply here.
            payout: false, // payout: true --> for Withdrawal (standalone credits); payout: false --> for Payments/Deposit
            payoutConfig: {
              // payoutConfig section required only when payout: true [for Withdrawal cases].
              maximumAmount: 100000000,
            },
            locale: "en_US",
            simulator: "EXTERNAL",
            imageUrl:
              "https://hosted.paysafe.com/checkout/resource/demo-store/images/logo.png", // Supply Your Logo URL here.
            environment: API_ENV, // environment: "LIVE" --> Production, "TEST" --> test environment for customers to try out Checkout & its features
            buttonColor: "#66cc99", // feel free to change the color of buttons (RGB value)
            companyName: "Bettdraft", // Supply Your Company Name here
            holderName: "John Smith", // Supply Customer's Name here
            customer: {
              firstName: state?.auth?.user?.name,
              lastName: state?.auth?.user?.surname,
              email: state?.auth?.user?.email,
              phone: state?.auth?.user?.mobile,
              dateofBirth: state?.auth?.user?.birthDate,
            },
            billingAddress: {
              // Supply customer's billing Address here.
              nickName: state?.auth?.user?.name,
              street: state?.auth?.user?.address,
              city: state?.auth?.user?.town,
              zip: state?.auth?.user?.zipCode,
              country: state?.auth?.user?.country,
              state: state?.auth?.user?.stateCode,
            },
            merchantRefNum: referenceNo, // Will be unique and must keep changing every transaction
            canEditAmount: true, // Makes the payment amount editable on Checkout screen. Make it false to open Checkout with a fixed non-editable amount.
            merchantDescriptor: {
              dynamicDescriptor: "XYZ",
              phone: "1234567890",
            },
            displayPaymentMethods: [
              "neteller",
              "skrill",
              "paysafecard",
              "paysafecash",
              "instantach",
              "paypal",
              "card",
              "vippreferred",
              "sightline",
              "ach",
              "eft",
            ],
            // displayPaymentMethods : Array serves two purposes. You can use it to restrict the payment methods that a customer can see.
            // You can also use it to order the payment methods based on your preference.
            // If this field is present, the customer will only see those payment methods in the order specified, hence,
            // Ensure that you provide all payment methods if you are using it only for the purpose of payment method ordering.
            paymentMethodDetails: {
              // Please read the Checkout Objects Documentation on developer.paysafe.com .....
              paysafecard: {
                // .... for details on paymentMethodDetails (including all supported mandatory and optional fields)
                consumerId: "123456",
              },
              paysafecash: {
                consumerId: "123456",
              },
              sightline: {
                consumerId: "12341231256",
                SSN: "123456789",
                last4ssn: "6789",
                //accountId: "1009688222"  // Supply Account ID only if multiple accounts are configured with same payment method
              },
              vippreferred: {
                consumerId: "120288765",
                //accountId: "1679688456"  // Supply Account ID only if multiple accounts are configured with same payment method
              },
              card: {
                //accountId: "1009688230"  // Supply Account ID only if multiple accounts are configured with same payment method
              },
              skrill: {
                consumerId: state?.auth?.user?.email,
                emailSubject: "Payout for Greg Neteller",
                emailMessage: "You Have Received Payout of $100.",
              },
              instantach: {
                consumerId: "john.doe@email.com",
                paymentId: "3aeb9c63-6386-46a3-9f8e-f452e722228a",
                emailSubject: "Instant ACH Payout",
                emailMessage:
                  "Your Instant ACH Payout request has been processed",
              },
              neteller: {
                consumerId: "netellertest_EUR@neteller.com",
                recipientDescription: "logo_url_alt_text",
                logoUrl: "http://www.paysafe.com/icon.jpg",
              },
            },
          },
          function (instance, error, result) {
            if (result && result.paymentHandleToken) {
              setIsLoadinig(false);
              SkrillPay(result.amount, referenceNo, result.paymentHandleToken);
              instance.close();
            } 
            else if(result.paymentHandleToken == null){
              SkrillFailed(depositamount,referenceNo)
              setIsLoadinig(false);
              instance.close();
            }
            else {
              // alert(error);
              ErrorToast(error);
              setIsLoadinig(false);
            }
          },
          function (stage, expired) {
            setIsLoadinig(false);
            switch (stage) {
              case "PAYMENT_HANDLE_NOT_CREATED": // Handle the scenario
              case "PAYMENT_HANDLE_CREATED": // Handle the scenario
              case "PAYMENT_HANDLE_REDIRECT": // Handle the scenario
              case "PAYMENT_HANDLE_PAYABLE": // Handle the scenario
              default: // Handle the scenario
            }
          },
          function (instance, amount, paymentMethod) {
            // use parameters amount and payment method to do riskCheck
            // Applicable to only Cards, PaySafe Cash, VIPP and Sightline
            if (amount >= 100) {
              instance.decline("Please use amount less than 100");
              setIsLoadinig(false);
              // ErrorToast("Please use amount less than 100");
            } else {
              instance.accept();
              setIsLoadinig(false);
            }
          }
        );
      } else {
        ErrorToast(response.message);
        setIsLoadinig(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoadinig(false);
      ErrorToast(
        "Issue connecting to server (error code 15). Please contact support for further assistance."
      );
    }
  };

  const SkrillPay = async (depositamount, referenceNo, tokenId) => {
    let url = `${PaymentEndpoints.apiBaseUrl}${PaymentEndpoints.api.SKRILL_WALLET}`;
    setIsLoadinig(true);
    let data = {
      merchantRefNum: referenceNo,
      amount: depositamount,
      accountid: "1002727400",
      paymentHandleToken: tokenId,
      currencyCode: "USD",
      id_user: state?.auth?.user?.idUser,
      settleWithAuth: true,
    };
    try {
      setIsLoadinig(true);
      let response = await addBetData(url, data);
      if (response.data.status == "success") {
        SuccesToast(response.data.message);
        GetUserById();
        GetTrans(1);
        setPage(1);
      } else {
        ErrorToast(response.data.message);
      }
    } catch (err) {
      console.log(err);
      ErrorToast(
        "Issue connecting to server (error code 15). Please contact support for further assistance."
      );
      setIsLoadinig(false);
    } finally {
      setAmt("");
      setIsLoadinig(false);
    }
  };

  const SkrillFailed = async (depositamount, referenceNo) => {
    let url = `${PaymentEndpoints.apiBaseUrl}${PaymentEndpoints.api.FAILED_DEPOSIT}`;
    setIsLoadinig(true);
    let data = {
      merchantRefNum: referenceNo,
      amount: depositamount,
      id_user: state?.auth?.user?.idUser,
      accountid: "string",
      paymentHandleToken: "string",
      currencyCode: "string",
      settleWithAuth: "string",
      status: "string",
      description: "string",
      tstatus: 0
    };
    try {
      setIsLoadinig(true);
      let response = await addBetData(url, data);
      if (response.data.status == "failure") {
        ErrorToast(response.data.message);
      } else {
        ErrorToast(response.data.message);
      }
    } catch (err) {
      console.log(err);
      ErrorToast(
        "Issue connecting to server (error code 15). Please contact support for further assistance."
      );
      setIsLoadinig(false);
    } finally {
      setAmt("");
      setIsLoadinig(false);
    }
  };


  let API_KEY_MERCHANT_TEST = `${SkrillEnv.keys}`

  let API_ENV = `${SkrillEnv.Env}`

  // console.log("key",API_KEY_MERCHANT_TEST,API_ENV)

  const checkout = (amt, refNum) => {
    SkrillAdd(amt, refNum);
  };

  const modalclose = () => {
    setvisible(false);
    setwithdrawAlert(false);
}

const modalcloseach = () => {
  setwithdrawAlert(false);
}

const handleOptionChange = (e) => {
  setSelectedOption(e.target.value);
  if (e.target.value === 'ach') {
    // setShowACHModal(true);
  } else {
    // setShowACHModal(false);
  }
};

const famount = (e) => {

  const myamount = e.target.value;

  const regex = /^\d*\.?\d{0,2}$/;

  if (regex.test(myamount)) {
   
    setachamount(myamount);
    }
  
}

      const Withdrawoption = async(event) => {

        event.preventDefault();
        if(selectedOption == "Skrill"){
          if (!withdrawAmt) {
            ErrorToast("Please enter an amount to withdraw to continue.");
            return; // Stop execution if amount is null
          }
          else if (parseInt(state?.auth?.user?.account?.ubalance)==0 || parseInt(withdrawAmt) > parseInt(state?.auth?.user?.account?.ubalance)) {
            ErrorToast("You do not have sufficient funds in your account to process this withdrawal.");
            return; // Stop execution if cash amount is null
          }
          if(parseInt(withdrawAmt) < 10){
            ErrorToast("Minimum withdrawal amount is $10.00.");
            return; // Stop execution if amount is less than 10
          }          

          else if(parseInt(state?.auth?.user?.account.bonus_balance) > 0){
            setwithdrawAlert(true);
            return;
          }
          else{
            withDrawSubmit(event);
          }
        }
        else if(selectedOption == "ach"){
          setwithdrawAlert(false);
          setCorewithdrawl(true);
        }
   
}

     const withdrawMoney = async() => {

      // e.preventDefault();
        if(( accountno != "" && achamount != "" && rountingno != "") || (Ach_validation > 0 && achamount != ""))
              {                      
                      if(rountingno.length != 9 && Ach_validation == 0){
                        ErrorToast("The routing number should be 9 digits.");
                        return;
                      }
                      
             const mycurrentip = await getIpAddress();

              setIsLoadinig(true);  
              let url = `${endPoints.apiBaseUrl}${endPoints.api.COREPAY_WITHDRAW}`;
              let allvalues = {
                id_user: state?.auth?.user?.idUser,
                accountName: `${state.auth.user.name} ${state.auth.user.surname}`,
                amount: achamount,
                accountNumber: accountno,
                routingNumber: rountingno,
                customerIp: mycurrentip.ip
              }  
              try{
       let response = await addData(url, allvalues)
       if (response.data.status === "success") {
            SuccesToast(response.data.message);
            setAccountno("");
            setachamount("");
            setRoutingno("")
            setIsLoadinig(false);
            setCorewithdrawl(false);
            GetUserById();
            GetTrans(1);
            setPage(1);
       }
   else {
     ErrorToast(response.data.message);
     setIsLoadinig(false);
               }}catch(error){
     console.log(error);
                }finally{
      setIsLoadinig(false); 
                }         
                 }
            else{
              ErrorToast("Your request is incomplete. Please check your details and try again."); 
            }   
            }


      const withdrawbank = async(event) => {

        event.preventDefault();

        if(selectedOption == "Skrill"){
          withDrawSubmit(event);
        }
        else if(selectedOption == "ach"){
          setwithdrawAlert(false);
          // setvisible(true);
          setCorewithdrawl(true);
        }
           }

      const achModal = (event) => {
            event.preventDefault();
            if (!achamount) {
              ErrorToast("Please enter an amount to withdraw to continue.");
              return; // Stop execution if amount is null
            }
            if(parseInt(achamount) < 10){
              ErrorToast("Minimum withdrawal amount is $10.00.");
              return; // Stop execution if amount is amount is less than 10
            }
            if(parseInt(state?.auth?.user?.account.bonus_balance) > 0){
              setwithdrawAlert(true);
            }
            else{
              // setvisible(true);
              setCorewithdrawl(true);
            }
          }

 useEffect(()=>{
  
  let bet_limit = state?.auth?.user?.rg_LimitsDto ? state?.auth?.user?.rg_LimitsDto.filter((item)=> item.rg_type == "1"):"";

  let deposit_limit = state?.auth?.user?.rg_LimitsDto ? state?.auth?.user?.rg_LimitsDto.filter((item)=> item.rg_type == "2"): "";

  setdepositlimit(deposit_limit[0]?.rg_limit_value);

  setremaingdepositlimit(deposit_limit[0]?.remaining_balance);

  setbetlimit(bet_limit[0]?.rg_limit_value);

  setremainingbetlimit(bet_limit[0]?.remaining_balance);
 
  setPlayDate(bet_limit[0]?.reset_date ? moment(bet_limit[0]?.reset_date).format('MMM DD, YYYY'):"Today");

  setDepositDate(deposit_limit[0]?.reset_date ? moment(deposit_limit[0]?.reset_date).format('MMM DD, YYYY'):"Today")

  // console.log("values",remaingdepositlimit.toLocaleString());

 })


const handleSubmit = (event) => {
 
  event.preventDefault();

  let data = {
    userId: state?.auth?.user?.account?.userId,
    email: state?.auth?.user?.email,
    firstName: state?.auth?.user?.name,
    lastName: state?.auth?.user?.surname,
    birthdate: state?.auth?.user?.birthDate,
    phoneNumber: `+1${state?.auth?.user?.phone}`, // "+19165389862",   //
    addressLine1: address1,
    addressLine2: address2,
    city: state?.auth?.user?.town,
    state: state?.auth?.user?.stateCode,
    country: state?.auth?.user?.country,
    zipCode: state?.auth?.user?.zipCode,
    ssn: SSN, //"001-02-0112",
    occupation: Occupation, // need to add these 2
  };

  if(data.occupation == ""){
    ErrorToast("Please enter a valid Occupation.");
  }
  else if(data.ssn == ""){
   ErrorToast("Please provide a valid SSN.");
  }
  else{
    UpdateUserDetail(data);
  }
};

const UpdateUserDetail = (data) => {
  let url = `${PaymentEndpoints.apiBaseUrl}${PaymentEndpoints.api.ACTIVATE}`;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  setIsLoadinig(true);
  fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      setIsLoadinig(false);
      setIsLoadinig(false);

      if (result.status == "success") {
        //window.open('')
        //window.open(result.data.enrollmentUrl, "", "width=900, height=900");
        window.location.href = result.data.enrollmentUrl;
      } else {
        ErrorToast(result.message);
      }
    })
    .catch((error) => {
      console.log("error", error);
      setIsLoadinig(false);
    });
};

const maxValue = 3000; // Numeric value

 const NavigateTomail = () => {

   navigate('/change-email');
   dispatch({
    type: Actions.TEST_CASE,
    data: false,
  });

 }   

 const corepayclose = () => {

      setCorePopup(false);
      setCorepayamt("");
 }

const handleCard = (e) => {
  const { value } = e.target;
  const cleaned = value.replace(/\D/g, '');
  setFormattedCardNumber(formatCardNumber(cleaned));
  setCardnumber(cleaned);
};


const formatCardNumber = (value) => {
  // Match groups of digits in chunks of 4
  const match = value.match(/.{1,4}/g);
  if (match) {
    // Join matched groups with spaces
    return match.join(' ');
  }
  return value;
};


const handleExpiry = (e) => {
  const { value } = e.target;
  setExpiryDate(formatExpiryDate(value));
};

const formatExpiryDate = (value) => {
  // Remove all non-digit characters
  const cleaned = value.replace(/[^0-9]/g, '');

  // Format the string to MM/YY
  if (cleaned.length <= 2) {
    // If the month part exceeds "12", keep the first digit
    if (parseInt(cleaned, 10) > 12) {
      return cleaned.slice(0, 1);
    }
    return cleaned;
  } else if (cleaned.length <= 4) {
    const month = cleaned.substring(0, 2);
    const year = cleaned.substring(2, 4);
    // If the month part exceeds "12", correct it
    if (parseInt(month, 10) > 12) {
      return `${month.slice(0, 1)}/${year}`;
    }
    return `${month}/${year}`;
  } else {
    const month = cleaned.substring(0, 2);
    const year = cleaned.substring(2, 4);
    // If the month part exceeds "12", correct it
    if (parseInt(month, 10) > 12) {
      return `${month.slice(0, 1)}/${year}`;
    }
    return `${month}/${year}`;
  }
};

const CorepayModal = (amt, e) => {

  e.preventDefault();
  if(amt==""){
    ErrorToast('Please enter a deposit amount to continue.');
    return;
  }
    setCorePopup(true);
    setCorepayamt(amt);
}

const CorepayDeposit = async () => {

  if(cardnumber==""|| cvv==""||expirydate==""||zip==""){

      ErrorToast("please enter all the mandaotry values");
      return;
  }
  let url =  `${PaymentEndpoints.apiBaseUrl}${PaymentEndpoints.api.COREPAY_DEPOSIT}`;
  setIsLoadinig(true);

  const currentip = await getIpAddress();

  let data = {
    idUser: state?.auth?.user?.idUser,
    amount: corepayamt,
    source: "Internet",
    level: 0,
    cardname: `${state.auth.user.name} ${state.auth.user.surname}`,
    cardnumber: cardnumber,
    cardexp: expirydate,
    cardcvv: cvv,
    country: state.auth.user.country,
    state: state.auth.user.stateCode,
    city: state.auth.user.town,
    street: state.auth.user.address,
    zip: zip,
    ipaddress: currentip.ip,
    name: `${state.auth.user.name} ${state.auth.user.surname}`,
    phone: state.auth.user.phone,
    email: state.auth.user.email,
    refrenceId:  "Bettdraft_"+state?.auth?.user?.idUser+"_"+Math.floor(10000 + Math.random() * 90000)
  };

  try {
    let response = await addBetData(url, data);
    setIsLoadinig(false);
    if(response.data.status == 'success' ){
      SuccesToast(response.data.message);
      setCorePopup(false);
      setCardnumber("");
      setFormattedCardNumber("");
      setExpiryDate("");
      setZip("");
      setCvv("");
      setCorepayamt("");
      GetUserById();
      GetTrans(1);
    }else if(response.data.status == 'failure' ){
      ErrorToast(response.data.message);
    }
    else{
      ErrorToast(response.data.message);
    }
  } catch (err) {
    setIsLoadinig(false);
    console.log('err',err)
  }
}

const corewithdrawclose = () => {

        setCorewithdrawl(false);
}



  return (
    <main id="wallet-page">
      <Toaster></Toaster>
      {isLoading && <Loader spinner={true} visible={isLoading} />}

                {/* Bonus forefiet functionality Modal */}
                   <Modal
                  size="md"
                     aria-labelledby="contained-modal-title-vcenter"
                     centered
                     backdrop="static"
                     keyboard={false}
                     show={withdrawAlert?true:""}
                    //  style={{paddingTop:"30px"}}
                   > 
                     <Modal.Body className="paddingtop">
                     <div className="test-area" style={{marginTop:"5px",padding:"25px"}}>
                     <h4 className="bonusconfirm" style={{textAlign:"center",fontSize:"24px!important"}}>
                      You currently have an active<br/> Bonus of ${state?.auth?.user?.account?.bonusBalance}.
                      </h4>
                        <p style={{fontSize:"18px",marginTop:"15px",textAlign:"center"}}>
                        <b>
                        If you continue with your withdrawal, you<br/>
                        will forfeit your bonus. Would you like to<br/>
                        continue with your withdrawal?
                        </b>
                        </p>
                      </div>
                     </Modal.Body>
                     <Modal.Footer className="bordernone" style={{marginTop:"-20px"}}>
                     <Col lg="12" xs="12" style={{textAlign:"center",paddingLeft:"20px",paddingRight:"20px"}}>
                      <Button
                      style={{cursor:'pointer',backgroundColor:"rgb(23, 150, 36)",border:"1px solid rgb(23, 150, 36)",width:"100%"}}
                      onClick={withdrawbank}
                     ><b>Withdraw Now</b>
                    </Button>
                      </Col>
                    <Col lg="12" xs="12" style={{textAlign:"center"}}>
                    <span style={{textAlign:"center",fontWeight:"600",color:"red",cursor:'pointer'}} onClick={modalcloseach}>Close</span>
                    </Col>
                     </Modal.Footer>
                   </Modal>

                {/* corepay deposit */}
                   <Modal
                  size="md"
                     aria-labelledby="contained-modal-title-vcenter"
                     centered
                     backdrop="static"
                     keyboard={false}
                     show={corepopup?true:""}
                    //  style={{paddingTop:"30px"}}
                   > 
                   {/* <Modal.Header style={{display:"flex!important",justifyContent:"center"}}>
                    
                   </Modal.Header> */}
                     <Modal.Body className="paddingtop2" style={{padding:"20px"}}>
                     <h5>
                    Complete your Deposit
                    </h5>
                      <h5>
                      Name on Card
                      </h5>
                      <h5 style={{color:"#E73959"}}>
                      {`${state.auth.user.name} ${state.auth.user.surname}`}
                      </h5>
                     <div className='col-lg-12'>
                    <p style={{color:"#413E3E!important" , fontSize:"10px",fontWeight:"300"}}>
                    *We can only accept cards held in your name. Please ensure your name appears on the card before submitting payment.
                    </p>
                     </div>
                     <div className="mt-1 form-space-r">
                  <Form
                    className="my-form-layout1"
                  >
                    <div className="row">
                    {/* <div className='col-lg-12'>
                    <p style={{color:"#0D3862!important" , fontSize:"20px",fontWeight:"600"}}>Please confirm the following information to continue:</p>
                     </div> */}
                      <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="phone">
                          <Form.Label className="corepay-label">
                            Credit Card Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="XXXX XXXX XXXX XXXX"
                            value={formattedCardNumber}
                            onChange={handleCard}
                            maxLength={19}
                            required
                          />
                        </Form.Group>
                      </div>
                      
                      <div className="col-md-6">
                        <div>      
                        <Form.Group className="mb-3" controlId="phone">
                          <Form.Label className="corepay-label">
                            Expiry Date
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="MM/YY"
                            value={expirydate}
                            name="expirydate"
                            onChange={handleExpiry}
                            // maxLength={5}
                            required
                          />
                        </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div>      
                        <Form.Group className="mb-3" controlId="phone">
                          <Form.Label className="corepay-label">
                            CVV
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="XXX"
                            value={cvv}
                            onChange={(e) => setCvv(e.target.value)}
                            maxLength={3}
                            required
                          />
                        </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div>      
                        <Form.Group className="mb-3" controlId="phone">
                          <Form.Label className="corepay-label">
                            Zip Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="XXXXXX"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            maxLength={6}
                            required
                          />
                        </Form.Group>
                        </div>
                      </div>
                      
                      <div className="col-md-12 mt-3" style={{border:"1px solid #D6CCCC"}}>
                      </div>
               
                  
                      <div className="col-md-6 mt-2">
                      <h5>
                       Deposit Amount
                      </h5>
                      </div>
                      <div className="col-md-6 mt-2">
                      <h5 style={{textAlign:"end"}}>
                      ${corepayamt}
                      </h5>
                      </div>
                    </div>
                  </Form>
                  </div>
                     </Modal.Body>
                     <Modal.Footer className="bordernone" style={{marginTop:"10px"}}>
                     <Col lg="12" xs="12" style={{textAlign:"center",paddingLeft:"20px",paddingRight:"20px"}}>
                     <Button
                        style={{cursor:'pointer',backgroundColor:"rgb(23, 150, 36)",border:"1px solid rgb(23, 150, 36)",width:"100%"}}
                          onClick={CorepayDeposit}
                          >
                            <span style={{fontSize:"17px",fontWeight:"700"}}>Make Payment</span>
                          </Button>
                      </Col>
                    <Col lg="12" xs="12" style={{textAlign:"center"}}>
                    <span style={{textAlign:"center",fontWeight:"600",color:"red",cursor:'pointer'}} onClick={corepayclose}>Close</span>
                    </Col>
                     </Modal.Footer>
                   </Modal>

               {/* corepay withdrawl */}
                   <Modal
                  size="md"
                     aria-labelledby="contained-modal-title-vcenter"
                     centered
                     backdrop="static"
                     keyboard={false}
                     show={corewithdrawl?true:""}
                    //  style={{paddingTop:"30px"}}
                   > 
                   {/* <Modal.Header style={{display:"flex!important",justifyContent:"center"}}>
                    
                   </Modal.Header> */}
                     <Modal.Body className="paddingtop2" style={{padding:"20px"}}>
                     <h5>
                     Complete your Withdrawal
                    </h5>
                      <h5>
                      Withdrawal amount:
                      </h5>
                      <h5 style={{color:"#E73959",fontWeight:"700"}}>
                      ${formatAmount(achamount)}
                      </h5>
                     <div className='col-lg-12'>
                    <p style={{color:"#413E3E!important" , fontSize:"10px",fontWeight:"300"}}>
                    *Payment can only be sent to bank accounts in your name. Please ensure your account and routing number are correct to avoid delays.
                    </p>
                     </div>
                     <div className="mt-1 form-space-r">
                    {Ach_validation == 0 ? 
                  <Form
                    className="my-form-layout1"
                  >
                    <div className="row">
                    {/* <div className='col-lg-12'>
                    <p style={{color:"#0D3862!important" , fontSize:"20px",fontWeight:"600"}}>Please confirm the following information to continue:</p>
                     </div> */}
                      <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="phone">
                          <Form.Label className="corepay-label">
                            Account Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="xxxxxxxx"
                            value={accountno}
                            onChange={(e)=>{setAccountno(e.target.value)}}
                            // maxLength={19}
                            required
                          />
                        </Form.Group>
                      </div>

                      <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="phone">
                          <Form.Label className="corepay-label">
                            Routing Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="xxxxxxxx"
                            value={rountingno}
                            onChange={(e)=>{setRoutingno(e.target.value)}}
                            maxLength={9}
                            required
                          />
                        </Form.Group>
                      </div>
                      
                    </div>
                  </Form> : ""}
                  </div>
                     </Modal.Body>
                     <Modal.Footer className="bordernone" style={{marginTop:"10px"}}>
                     <Col lg="12" xs="12" style={{textAlign:"center",paddingLeft:"20px",paddingRight:"20px"}}>
                     <Button
                        style={{cursor:'pointer',backgroundColor:"rgb(23, 150, 36)",border:"1px solid rgb(23, 150, 36)",width:"100%"}}
                          onClick={withdrawMoney}
                          >
                            <span style={{fontSize:"17px",fontWeight:"700"}}>Request Withdrawal</span>
                          </Button>
                      </Col>
                    <Col lg="12" xs="12" style={{textAlign:"center"}}>
                    <span style={{textAlign:"center",fontWeight:"600",color:"red",cursor:'pointer'}} onClick={corewithdrawclose}>Close</span>
                    </Col>
                     </Modal.Footer>
                   </Modal>


    {isMobile ?                                                 //differnt screen dispaly for mobile screen
    <section className="sec-padd">
        <div className="container">
          <h2 className="heading-c">Your BettDraft Wallet:</h2>
          <div className="row g-3">
            <div className="col-md-4">
              <div className="w_card">
                <h4>Total Balance</h4>
                <h2>${state?.auth?.user?.account?.totalBalance}</h2>
                <div className="flex_btn">
                  <div className="flex_1 bg_green_light">
                    <p>Account Type</p>
                    <h3>
                      <span>CASH</span>{" "}
                      <span>${state?.auth?.user?.account?.balance}</span>
                    </h3>
                  </div>
                  <div className="flex_1 bg_orange_light">
                    <p>Account Type</p>
                    <h3>
                      <span>BONUS</span>{" "}
                      <span>${state?.auth?.user?.account?.bonusBalance}</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>

           <div className="col-md-8 mb-4" style={{marginTop:"-10px"}}>
               {/* skrill Deposit */}
              <div className="wallet_balance_main">

              <div className="wallet_header" style={{display:"flex",justifyContent:"space-between",marginBottom:"5px"}}>
                <div>
                  <h2>
                    <b>Deposit</b> with{" "}
                    <span>
                      <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/skrill_new.png")}
                        alt="logo"
                      />
                    </span>
                  </h2>
                  <p>
                    Select a quick deposit amount or enter an amount to deposit:
                  </p>
                  </div>

                 {isMobile?"":
                  <div>
                   <span style={{fontSize:"10px"}}><b>Don't have Skrill?</b> Simply select a deposit amount and follow the steps to register.</span>
                   <br/>
                  <span style={{fontSize:"9px",float:isMobile?"initial":"right"}}>You must register the same email address as your BettDraft account.</span>
                  </div>
                  }

                </div>

                <div className="wallet_balance_info">
                  <div className="wallet_balance_flex">
                    <ul>
                      <li className="click-btn" onClick={(e) => Deposit("10", e)}>
                        <span>$10</span>
                      </li>
                      <li className="click-btn" onClick={(e) => Deposit("25", e)}>
                        <span>$25</span>
                      </li>
                      <li className="click-btn" onClick={(e) => Deposit("50", e)}>
                        <span>$50</span>
                      </li>
                      <li
                        className="click-btn"
                        onClick={(e) => Deposit("100", e)}
                      >
                        <span>$100</span>
                      </li>
                      <li
                        className="click-btn"
                        onClick={(e) => Deposit("200", e)}
                      >
                        <span>$200</span>
                      </li>
                      <li
                        className="click-btn"
                        onClick={(e) => Deposit("500", e)}
                      >
                        <span>$500</span>
                      </li>
                      <li>
                        <form>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Other Amount"
                            value={amt}
                            onChange={(e) => setAmt(e.target.value)}
                          />
                          <input
                            type="submit"
                            value={"Deposit"}
                            onClick={(e) => {
                              Deposit(amt, e);
                            }}
                          />
                          {/* <button className="btn btn-primary" >Deposit now</button> */}
                        </form>
                      </li>
                    </ul>
                  </div>
                </div>

                {isMobile?
                  <div className="compact-text-mobile">
                   <span style={{fontSize:"10px"}}><b>Don't have Skrill?</b> Simply select a deposit amount and follow the steps to register.</span>
                   <br/>
                  <span style={{fontSize:"9px",float:isMobile?"initial":"right"}}>You must register the same email address as your BettDraft account.</span>
                  </div>:""
                  }

                <div className="chage-email-option">
                  <span className="compact-text" style={{fontSize:"10px",lineHeight:"1.5",display:"inline-block"}}><b>Please note.</b> Your BettDraft registered email address <span style={{textDecoration:"underline"}}>MUST</span> match your Skrill account email address to deposit.<br/>
                  If your registered Skrill email address is not {state?.auth?.user?.email}, you can update your BettDraft email <b><a style={{textDecoration:"underline",cursor:"pointer"}} onClick={()=>{NavigateTomail()}}>here.</a></b>
                  </span>
                </div>
              </div>

              {/* Corepay Deposit */}
              {/* <div className="wallet_balance_main">
                <div className="wallet_header">
                  <h2>
                    <b>Deposit</b> with{" "}
                    <span>
                      <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/Visa.png")}
                        alt="logo"
                      />

                      <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/Mastercard.png")}
                        alt="logo"
                      />

                      <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/Discovery.png")}
                        alt="logo"
                      />
                    </span>
                  </h2>
                  <p>
                    Select a quick deposit amount or enter an amount to deposit:
                  </p>
                </div>
                <div className="wallet_balance_info">
                  <div className="wallet_balance_flex">
                    <ul>
                      <li className="click-btn" onClick={(e) => CorepayModal("10", e)}>
                        <span>$10</span>
                      </li>
                      <li className="click-btn" onClick={(e) => CorepayModal("25", e)}>
                        <span>$25</span>
                      </li>
                      <li className="click-btn" onClick={(e) => CorepayModal("50", e)}>
                        <span>$50</span>
                      </li>
                      <li className="click-btn" onClick={(e) => CorepayModal("100", e)}>
                        <span>$100</span>
                      </li>
                      <li className="click-btn" onClick={(e) => CorepayModal("200", e)}>
                        <span>$200</span>
                      </li>
                      <li className="click-btn" onClick={(e) => CorepayModal("500", e)}>
                        <span>$500</span>
                      </li>
                      <li>
                        <form>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Other Amount"
                            value={corepayamt}
                            onChange={(e) => setCorepayamt(e.target.value)}
                          />
                          <input type="submit" 
                          onClick={(e) => CorepayModal(corepayamt, e)}
                          value={"Deposit"}
                           />
                        </form>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}

              <div className="row g-3 walelet_main2">

                {/* <div className="col-md-6">
                  <div className="wallet_balance_main">
                    <div className="wallet_header">
                      <h2>
                        <b>Deposit</b> with{" "}
                        <span>
                          <img
                            className="w-100"
                            src={require("./../../../../src/assests/wallet/FABICash.png")}
                            alt="logo"
                          />
                        </span>
                      </h2>
                      <p>
                        Select a quick deposit amount or enter an amount to
                        deposit:
                      </p>
                    </div>

                      <div className="wallet_balance_info wallet_balance_2">
                      <div className="wallet_balance_flex">
                        <ul>
                          <li>
                            <form>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Amount"
                                value={depositAmt}
                                onChange={(e)=> setdepositAmt(e.target.value)}
                              />
                              <input type="submit" value={"Deposit"} onClick={(e)=> febiDeposit(e)} />
                              <input
                                type="submit"
                                className="bg_blue"
                                onClick={(e)=> addMoney(e)}
                                value={"Go to FABI"}
                              />
                            </form>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div> */}
                
                <div className="col-md-12">
                  <div className={isMobile?"wallet_balance_main-withdrawl-mobile":"wallet_balance_main-withdrawl"}>
                  <div className="wallet_header" style={{marginTop:"5px"}}>
                      <h2>
                        <b>Withdraw Funds</b> <span></span>
                      </h2>
                      <div className="wallet_radio" style={{marginTop:"3px"}}>
                        <p>Withdraw to:</p>
                        <div class="form-check">
                          <input type="radio" name="c_radio1" id="skrill_radio"  value="Skrill"  onChange={handleOptionChange} 
                          checked={selectedOption === "Skrill"}  />
                          <label class="form-check-label" for="c_radio1">
                            <img
                              className="w-100"
                              src={require("./../../../../src/assests/wallet/skrill.png")}
                              alt="logo"
                            />
                          </label>
                        </div>

                        {/* corepay withdrawal */}
                        {/* <div class="form-check">
                          <input type="radio" name="c_radio1" id="ach"  value="ach"  onChange={handleOptionChange} 
                          />
                          <label class="form-check-label" for="c_radio1">
                            <img
                              className="w-100"
                              src={require("./../../../../src/assests/wallet/ACH.png")}
                              alt="logo"
                            />
                          </label>
                        </div> */}

                      </div>
                    </div>

                    <div className="second-block">
                    {isMobile?"":
                            <div style={{marginTop:"-8px",marginBottom:"8px"}}>
                             <span style={{fontSize:"10px"}}><b>Don't have Skrill?</b> You will receive an email with your withdrawal with instructions on registering.</span>
                             <br/>
                            <span style={{fontSize:"9px",float:isMobile?"initial":"right"}}>Withdrawals are only sent to the email address as your BettDraft account.</span>
                            </div>
                          }
                    <div className="wallet_balance_info wallet_balance_2 mt-3">
                      <div className="wallet_balance_flex withdrawl_input_section">
                        <ul>
                          <li>
                            <p>
                              Max Withdrawal amount: <br /> <b className="max-withdrawl">${state?.auth?.user?.account?.balance}</b>
                            </p>
                            {/* <form>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Amount"
                                value={withdrawAmt}
                                onChange={(e) => setWithDrawAmt(e.target.value)}
                              />
                              <input
                                type="submit"
                                className="bg_blue"
                                value={"Withdraw"}
                                // onClick={() => {
                                //   withDrawSubmit();
                                // }}
                                onClick={(e) => {
                                  //Deposit(amt, e);
                                  withDrawSubmit(e);
                                }}
                              />
                            </form> */}
                                {selectedOption === 'Skrill' ? (
                  <form>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      value={withdrawAmt}
                      onChange={(e) => setWithDrawAmt(e.target.value)}
                    />
                    <input type="submit" className="bg_blue" value="Withdraw"
                     onClick={Withdrawoption}
                    />
                  </form>
                ) : (
                  <form>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      value={achamount}
                      onChange={famount}
                    />
                    <input
                      type="submit"
                      className="bg_blue"
                      value="Withdraw"
                      onClick={(event) => {achModal(event)}}
                    />
                  </form>
                )}
                          </li>
                        </ul>
                      </div>
                    </div>
                    </div>

                    {isMobile?
                  <div className="compact-text-mobile">
                   <span style={{fontSize:"10px"}}><b>Don't have Skrill?</b> You will receive an email with your withdrawal with instructions on registering.</span>
                   <br/>
                  <span style={{fontSize:"9px",float:isMobile?"initial":"right"}}>Withdrawals are only sent to the email address as your BettDraft account.</span>
                  </div>:""
                  }
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-4">
              <div className="play_card">
                <div className="row">
                  <div className="col-md-8 col-8">
                    <h3>Available Play Limits</h3>
                    <div className="row g-3">
                      <div className="col-md-6 col-6">
                        <h4>Limit Period:</h4>
                        <h5>Daily</h5>
                        <Link to="/responsible-gaming-settings">Edit Limits</Link>
                      </div>
                      <div className="col-md-6 col-6">
                        <h4>Limit Amount:</h4>
                        <h5>${betlimit?formatAmount(betlimit):""}</h5>
                        <h6>
                          Some limits may be applied by BettDraft and may not be
                          editable.{" "}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-4" style={{marginLeft:isMobile?"-40px":"-25px"}}>
                    <div className="limit_info">
                      {/* <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/meter-green.png")}
                        alt="logo"
                      /> */}
                       <ReactSpeedometer
                       forceRender={true}
                       width={150}
                       needleHeightRatio={0.5}
                       ringWidth={10}
                       maxValue={betlimit}
                       value={remainingbetlimit}
                       labelFontSize="10px"
                       valueTextFontSize="13px"
                       needleColor="green"
                       startColor="green"
                       segments={1}
                       endColor="green"
                       height={100}
                       currentValueText={remainingbetlimit?formatAmount(remainingbetlimit):""}
                       />                      
                      {/* <div className="flex_group">
                        <p>$0</p>
                        <h4>${betlimit}</h4>
                        <p>$500</p>
                      </div> */}
                      <h6 style={{marginLeft:"25px"}}>Limit Resets on {playdate}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="play_card">
                <div className="row">
                  <div className="col-md-8 col-8">
                    <h3>Available Deposit Limits</h3>
                    <div className="row g-3">
                      <div className="col-md-6 col-6">
                        <h4>Limit Period:</h4>
                        <h5>Daily</h5>
                        <Link to="/responsible-gaming-settings">Edit Limits</Link>
                      </div>
                      <div className="col-md-6 col-6">
                        <h4>Limit Amount:</h4>
                        <h5>${depositlimit?formatAmount(depositlimit):""}</h5>
                        <h6>
                          Some limits may be applied by BettDraft and may not be
                          editable.
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-4" style={{marginLeft:isMobile?"-40px":"-25px"}}>
                    <div className="limit_info">
                      {/* <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/meter-blue.png")}
                        alt="logo"
                      /> */}
                      <ReactSpeedometer
                      forceRender={true}
                       width={150}
                       needleHeightRatio={0.5}
                       ringWidth={10}
                       maxValue={depositlimit}
                       value={remaingdepositlimit}
                       labelFontSize="10px"
                       valueTextFontSize="13px"
                       needleColor="lightblue"
                       startColor="lightblue"
                       segments={1}
                       endColor="lightblue"
                       height={100}
                       currentValueText={remaingdepositlimit?formatAmount(remaingdepositlimit):""}
                       />
                      {/* <div className="flex_group">
                        <p>$0</p>
                        <h4>${depositlimit}</h4>
                        <p>$500</p>
                      </div> */}
                      <h6 style={{marginLeft:"25px"}}>Limit Resets on {depositdate}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3">
            

            <div className="col-md-4">
              <div className="history_main">
                <h3>Transaction History</h3>
                {/* <ul>
                  <li>
                    <div className="date_histroy">
                      {" "}
                      <p>
                        May <span>15</span>
                      </p>
                    </div>
                    <div className="date_info">
                      <h4>Contest Entry</h4>
                      <p>
                        Trans ID: <span>24478</span>
                      </p>
                    </div>
                    <div className="h_button_group">
                      <Link className="c_btn">$25.00</Link>
                      <Link className="red_c">
                        <FaSquareMinus />
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="date_histroy">
                      {" "}
                      <p>
                        May <span>15</span>
                      </p>
                    </div>
                    <div className="date_info">
                      <h4>Contest Entry</h4>
                      <p>
                        Trans ID: <span>24478</span>
                      </p>
                    </div>
                    <div className="h_button_group">
                      <Link className="c_btn">$25.00</Link>
                      <Link className="red_c">
                        <FaSquareMinus />
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="date_histroy">
                      {" "}
                      <p>
                        May <span>15</span>
                      </p>
                    </div>
                    <div className="date_info">
                      <h4>Contest Entry</h4>
                      <p>
                        Trans ID: <span>24478</span>
                      </p>
                    </div>
                    <div className="h_button_group">
                      <Link className="c_btn">$25.00</Link>
                      <Link className="green_c">
                        <BsPlusSquareFill />
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="date_histroy">
                      {" "}
                      <p>
                        May <span>15</span>
                      </p>
                    </div>
                    <div className="date_info">
                      <h4>Contest Entry</h4>
                      <p>
                        Trans ID: <span>24478</span>
                      </p>
                    </div>
                    <div className="h_button_group">
                      <Link className="c_btn">$25.00</Link>
                      <Link className="green_c">
                        <BsPlusSquareFill />
                      </Link>
                    </div>
                  </li>
                </ul> */}
                <ul>
              {transactionList.map((item) => {
                      return (
                        <li>
                    <div className="date_histroy">
                      {" "}
                      <p>
                      {moment(item.insertion_time).format(
                                "MMM"
                              )} <span>{moment(item.insertion_time).format(
                                "DD"
                              )}</span>
                      </p>
                    </div>
                    <div className="date_info" style={{textAlign:"initial"}}>
                      <h4>{item.description}</h4>
                      <p>
                        Trans ID: <span>{item.id_transaction}</span>
                      </p>
                    </div>
                    <div className="h_button_group">
                      <Link className={item.impressions=="CASH"?"c_btn bg_green_light":"c_btn bg_orange_light"}>${formatAmount(item.amount)}</Link>
                      <Link className="red_c" style={{color:item.operation == "DEPOSIT" || item.operation == "CREDIT"?"green":"red"}}>
                      {item.operation == "DEPOSIT" || item.operation == "CREDIT" ? <BsPlusSquareFill /> : <FaSquareMinus />}
                      </Link>
                    </div>
                       </li>
                     );
                    })}
                    </ul>
                <div className="c_pagination">
                  <Link 
                  className={page === 1?"option-disabled":"option"}
                  disabled={page === 1}
                  onClick={handlePrevPage}>
                    <BsChevronLeft /> Previous
                  </Link>

                  <Link 
                  className={page === totalPages?"option-disabled":"option"}
                  disabled={page === totalPages}
                  onClick={handleNextPage}>
                    Next <BsChevronRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
       :
      <section className="sec-padd">
        <div className="container">
          <h2 className="heading-c">Your BettDraft Wallet:</h2>
          <div className="row g-3">
            <div className="col-md-4">
              <div className="w_card">
                <h4>Total Balance</h4>
                <h2>${state?.auth?.user?.account?.totalBalance}</h2>
                <div className="flex_btn">
                  <div className="flex_1 bg_green_light">
                    <p>Account Type</p>
                    <h3>
                      <span>CASH</span>{" "}
                      <span>${state?.auth?.user?.account?.balance}</span>
                    </h3>
                  </div>
                  <div className="flex_1 bg_orange_light">
                    <p>Account Type</p>
                    <h3>
                      <span>BONUS</span>{" "}
                      <span>${state?.auth?.user?.account?.bonusBalance}</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="play_card">
                <div className="row">
                  <div className="col-md-8 col-8">
                    <h3>Available Play Limits</h3>
                    <div className="row g-3">
                      <div className="col-md-6 col-6">
                        <h4>Limit Period:</h4>
                        <h5>Daily</h5>
                        <Link to="/responsible-gaming-settings">Edit Limits</Link>
                      </div>
                      <div className="col-md-6 col-6">
                        <h4>Limit Amount:</h4>
                        <h5>${betlimit?formatAmount(betlimit):""}</h5>
                        <h6>
                          Some limits may be applied by BettDraft and may not be
                          editable.{" "}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-4" style={{marginLeft:isMobile?"-40px":"-25px"}}>
                    <div className="limit_info">
                      {/* <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/meter-green.png")}
                        alt="logo"
                      /> */}
                       <ReactSpeedometer
                       forceRender={true}
                       width={150}
                       needleHeightRatio={0.5}
                       ringWidth={10}
                       maxValue={betlimit}
                       value={remainingbetlimit}
                       labelFontSize="10px"
                       valueTextFontSize="13px"
                       needleColor="green"
                       startColor="green"
                       segments={1}
                       endColor="green"
                       height={100}
                       currentValueText={remainingbetlimit?formatAmount(remainingbetlimit):""}
                       />                      
                      {/* <div className="flex_group">
                        <p>$0</p>
                        <h4>${betlimit}</h4>
                        <p>$500</p>
                      </div> */}
                      <h6 style={{marginLeft:"25px"}}>Limit Resets on {playdate}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="play_card">
                <div className="row">
                  <div className="col-md-8 col-8">
                    <h3>Available Deposit Limits</h3>
                    <div className="row g-3">
                      <div className="col-md-6 col-6">
                        <h4>Limit Period:</h4>
                        <h5>Daily</h5>
                        <Link to="/responsible-gaming-settings">Edit Limits</Link>
                      </div>
                      <div className="col-md-6 col-6">
                        <h4>Limit Amount:</h4>
                        <h5>${depositlimit?formatAmount(depositlimit):""}</h5>
                        <h6>
                          Some limits may be applied by BettDraft and may not be
                          editable.
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-4" style={{marginLeft:isMobile?"-40px":"-25px"}}>
                    <div className="limit_info">
                      {/* <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/meter-blue.png")}
                        alt="logo"
                      /> */}
                      <ReactSpeedometer
                      forceRender={true}
                       width={150}
                       needleHeightRatio={0.5}
                       ringWidth={10}
                       maxValue={depositlimit}
                       value={remaingdepositlimit}
                       labelFontSize="10px"
                       valueTextFontSize="13px"
                       needleColor="lightblue"
                       startColor="lightblue"
                       segments={1}
                       endColor="lightblue"
                       height={100}
                       currentValueText={remaingdepositlimit?formatAmount(remaingdepositlimit):""}
                       />
                      {/* <div className="flex_group">
                        <p>$0</p>
                        <h4>${depositlimit}</h4>
                        <p>$500</p>
                      </div> */}
                      <h6 style={{marginLeft:"25px"}}>Limit Resets on {depositdate}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-md-8">

               {/* skrill Deposit */}
              <div className="wallet_balance_main">

              <div className="wallet_header" style={{display:"flex",justifyContent:"space-between",marginBottom:"5px"}}>
                <div>
                  <h2>
                    <b>Deposit</b> with{" "}
                    <span>
                      <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/skrill_new.png")}
                        alt="logo"
                      />
                    </span>
                  </h2>
                  <p>
                    Select a quick deposit amount or enter an amount to deposit:
                  </p>
                  </div>

                 {isMobile?"":
                  <div>
                   <span style={{fontSize:"10px"}}><b>Don't have Skrill?</b> Simply select a deposit amount and follow the steps to register.</span>
                   <br/>
                  <span style={{fontSize:"9px",float:isMobile?"initial":"right"}}>You must register the same email address as your BettDraft account.</span>
                  </div>
                  }

                </div>

                <div className="wallet_balance_info">
                  <div className="wallet_balance_flex">
                    <ul>
                      <li className="click-btn" onClick={(e) => Deposit("10", e)}>
                        <span>$10</span>
                      </li>
                      <li className="click-btn" onClick={(e) => Deposit("25", e)}>
                        <span>$25</span>
                      </li>
                      <li className="click-btn" onClick={(e) => Deposit("50", e)}>
                        <span>$50</span>
                      </li>
                      <li
                        className="click-btn"
                        onClick={(e) => Deposit("100", e)}
                      >
                        <span>$100</span>
                      </li>
                      <li
                        className="click-btn"
                        onClick={(e) => Deposit("200", e)}
                      >
                        <span>$200</span>
                      </li>
                      <li
                        className="click-btn"
                        onClick={(e) => Deposit("500", e)}
                      >
                        <span>$500</span>
                      </li>
                      <li>
                        <form>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Other Amount"
                            value={amt}
                            onChange={(e) => setAmt(e.target.value)}
                          />
                          <input
                            type="submit"
                            value={"Deposit"}
                            onClick={(e) => {
                              Deposit(amt, e);
                            }}
                          />
                          {/* <button className="btn btn-primary" >Deposit now</button> */}
                        </form>
                      </li>
                    </ul>
                  </div>
                </div>

                {isMobile?
                  <div className="compact-text-mobile">
                   <span style={{fontSize:"10px"}}><b>Don't have Skrill?</b> Simply select a deposit amount and follow the steps to register.</span>
                   <br/>
                  <span style={{fontSize:"9px",float:isMobile?"initial":"right"}}>You must register the same email address as your BettDraft account.</span>
                  </div>:""
                  }

                <div className="chage-email-option">
                  <span className="compact-text" style={{fontSize:"10px",lineHeight:"1.5",display:"inline-block"}}><b>Please note.</b> Your BettDraft registered email address <span style={{textDecoration:"underline"}}>MUST</span> match your Skrill account email address to deposit.<br/>
                  If your registered Skrill email address is not {state?.auth?.user?.email}, you can update your BettDraft email <b><a style={{textDecoration:"underline",cursor:"pointer"}} onClick={()=>{NavigateTomail()}}>here.</a></b>
                  </span>
                </div>
              </div>

              {/* Corepay Deposit */}
              {/* <div className="wallet_balance_main">
                <div className="wallet_header">
                  <h2>
                    <b>Deposit</b> with{" "}
                    <span>
                      <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/Visa.png")}
                        alt="logo"
                      />

                      <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/Mastercard.png")}
                        alt="logo"
                      />

                      <img
                        className="w-100"
                        src={require("./../../../../src/assests/wallet/Discovery.png")}
                        alt="logo"
                      />
                    </span>
                  </h2>
                  <p>
                    Select a quick deposit amount or enter an amount to deposit:
                  </p>
                </div>
                <div className="wallet_balance_info">
                  <div className="wallet_balance_flex">
                    <ul>
                      <li className="click-btn" onClick={(e) => CorepayModal("10", e)}>
                        <span>$10</span>
                      </li>
                      <li className="click-btn" onClick={(e) => CorepayModal("25", e)}>
                        <span>$25</span>
                      </li>
                      <li className="click-btn" onClick={(e) => CorepayModal("50", e)}>
                        <span>$50</span>
                      </li>
                      <li className="click-btn" onClick={(e) => CorepayModal("100", e)}>
                        <span>$100</span>
                      </li>
                      <li className="click-btn" onClick={(e) => CorepayModal("200", e)}>
                        <span>$200</span>
                      </li>
                      <li className="click-btn" onClick={(e) => CorepayModal("500", e)}>
                        <span>$500</span>
                      </li>
                      <li>
                        <form>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Other Amount"
                            value={corepayamt}
                            onChange={(e) => setCorepayamt(e.target.value)}
                          />
                          <input type="submit" 
                          onClick={(e) => CorepayModal(corepayamt, e)}
                          value={"Deposit"}
                           />
                        </form>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}

              <div className="row g-3 walelet_main2">

                {/* <div className="col-md-6">
                  <div className="wallet_balance_main">
                    <div className="wallet_header">
                      <h2>
                        <b>Deposit</b> with{" "}
                        <span>
                          <img
                            className="w-100"
                            src={require("./../../../../src/assests/wallet/FABICash.png")}
                            alt="logo"
                          />
                        </span>
                      </h2>
                      <p>
                        Select a quick deposit amount or enter an amount to
                        deposit:
                      </p>
                    </div>

                      <div className="wallet_balance_info wallet_balance_2">
                      <div className="wallet_balance_flex">
                        <ul>
                          <li>
                            <form>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Amount"
                                value={depositAmt}
                                onChange={(e)=> setdepositAmt(e.target.value)}
                              />
                              <input type="submit" value={"Deposit"} onClick={(e)=> febiDeposit(e)} />
                              <input
                                type="submit"
                                className="bg_blue"
                                onClick={(e)=> addMoney(e)}
                                value={"Go to FABI"}
                              />
                            </form>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div> */}
                
                <div className="col-md-12">
                  <div className={isMobile?"wallet_balance_main-withdrawl-mobile":"wallet_balance_main-withdrawl"}>
                    <div className="wallet_header" style={{marginTop:"5px"}}>
                      <h2>
                        <b>Withdraw Funds</b> <span></span>
                      </h2>
                      <div className="wallet_radio" style={{marginTop:"3px"}}>
                        <p>Withdraw to:</p>
                        <div class="form-check">
                          <input type="radio" name="c_radio1" id="skrill_radio"  value="Skrill"  onChange={handleOptionChange} 
                          checked={selectedOption === "Skrill"}  />
                          <label class="form-check-label" for="c_radio1">
                            <img
                              className="w-100"
                              src={require("./../../../../src/assests/wallet/skrill.png")}
                              alt="logo"
                            />
                          </label>
                        </div>
                        
                        {/* corepay withdrawal */}
                        {/* <div class="form-check">
                          <input type="radio" name="c_radio1" id="ach"  value="ach"  onChange={handleOptionChange} 
                          />
                          <label class="form-check-label" for="c_radio1">
                            <img
                              className="w-100"
                              src={require("./../../../../src/assests/wallet/ACH.png")}
                              alt="logo"
                            />
                          </label>
                        </div> */}

                      </div>
                    </div>

                    <div className="second-block">
                    {isMobile?"":
                            <div style={{marginTop:"-8px",marginBottom:"8px"}}>
                             <span style={{fontSize:"10px"}}><b>Don't have Skrill?</b> You will receive an email with your withdrawal with instructions on registering.</span>
                             <br/>
                            <span style={{fontSize:"9px",float:isMobile?"initial":"right"}}>Withdrawals are only sent to the email address as your BettDraft account.</span>
                            </div>
                          }
                    <div className="wallet_balance_info wallet_balance_2 mt-3">
                      <div className="wallet_balance_flex withdrawl_input_section">
                        <ul>
                          <li>
                            <p>
                              Max Withdrawal amount: <br /> <b className="max-withdrawl">${state?.auth?.user?.account?.balance}</b>
                            </p>
                            {/* <form>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Amount"
                                value={withdrawAmt}
                                onChange={(e) => setWithDrawAmt(e.target.value)}
                              />
                              <input
                                type="submit"
                                className="bg_blue"
                                value={"Withdraw"}
                                // onClick={() => {
                                //   withDrawSubmit();
                                // }}
                                onClick={(e) => {
                                  //Deposit(amt, e);
                                  withDrawSubmit(e);
                                }}
                              />
                            </form> */}
                                {selectedOption === 'Skrill' ? (
                  <form>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      value={withdrawAmt}
                      onChange={(e) => setWithDrawAmt(e.target.value)}
                    />
                    <input type="submit" className="bg_blue" value="Withdraw"
                     onClick={Withdrawoption}
                    />
                  </form>
                ) : (
                  <form>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      value={achamount}
                      onChange={famount}
                    />
                    <input
                      type="submit"
                      className="bg_blue"
                      value="Withdraw"
                      onClick={(event) => {achModal(event)}}
                    />
                  </form>
                )}
                          </li>
                        </ul>
                      </div>
                    </div>
                    </div>

                    {isMobile?
                  <div className="compact-text-mobile">
                   <span style={{fontSize:"10px"}}><b>Don't have Skrill?</b> You will receive an email with your withdrawal with instructions on registering.</span>
                   <br/>
                  <span style={{fontSize:"9px",float:isMobile?"initial":"right"}}>Withdrawals are only sent to the email address as your BettDraft account.</span>
                  </div>:""
                  }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="history_main">
                <h3>Transaction History</h3>
                {/* <ul>
                  <li>
                    <div className="date_histroy">
                      {" "}
                      <p>
                        May <span>15</span>
                      </p>
                    </div>
                    <div className="date_info">
                      <h4>Contest Entry</h4>
                      <p>
                        Trans ID: <span>24478</span>
                      </p>
                    </div>
                    <div className="h_button_group">
                      <Link className="c_btn">$25.00</Link>
                      <Link className="red_c">
                        <FaSquareMinus />
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="date_histroy">
                      {" "}
                      <p>
                        May <span>15</span>
                      </p>
                    </div>
                    <div className="date_info">
                      <h4>Contest Entry</h4>
                      <p>
                        Trans ID: <span>24478</span>
                      </p>
                    </div>
                    <div className="h_button_group">
                      <Link className="c_btn">$25.00</Link>
                      <Link className="red_c">
                        <FaSquareMinus />
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="date_histroy">
                      {" "}
                      <p>
                        May <span>15</span>
                      </p>
                    </div>
                    <div className="date_info">
                      <h4>Contest Entry</h4>
                      <p>
                        Trans ID: <span>24478</span>
                      </p>
                    </div>
                    <div className="h_button_group">
                      <Link className="c_btn">$25.00</Link>
                      <Link className="green_c">
                        <BsPlusSquareFill />
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="date_histroy">
                      {" "}
                      <p>
                        May <span>15</span>
                      </p>
                    </div>
                    <div className="date_info">
                      <h4>Contest Entry</h4>
                      <p>
                        Trans ID: <span>24478</span>
                      </p>
                    </div>
                    <div className="h_button_group">
                      <Link className="c_btn">$25.00</Link>
                      <Link className="green_c">
                        <BsPlusSquareFill />
                      </Link>
                    </div>
                  </li>
                </ul> */}
                <ul>
              {transactionList.map((item) => {
                      return (
                        <li>
                    <div className="date_histroy">
                      {" "}
                      <p>
                      {moment(item.insertion_time).format(
                                "MMM"
                              )} <span>{moment(item.insertion_time).format(
                                "DD"
                              )}</span>
                      </p>
                    </div>
                    <div className="date_info" style={{textAlign:"initial"}}>
                      <h4>{item.description}</h4>
                      <p>
                        Trans ID: <span>{item.id_transaction}</span>
                      </p>
                    </div>
                    <div className="h_button_group">
                      <Link className={item.impressions=="CASH"?"c_btn bg_green_light":"c_btn bg_orange_light"}>${formatAmount(item.amount)}</Link>
                      <Link className="red_c" style={{color:item.operation == "DEPOSIT" || item.operation == "CREDIT"?"green":"red"}}>
                      {item.operation == "DEPOSIT" || item.operation == "CREDIT" ? <BsPlusSquareFill /> : <FaSquareMinus />}
                      </Link>
                    </div>
                       </li>
                     );
                    })}
                    </ul>
                <div className="c_pagination">
                  <Link 
                  className={page === 1?"option-disabled":"option"}
                  disabled={page === 1}
                  onClick={handlePrevPage}>
                    <BsChevronLeft /> Previous
                  </Link>

                  <Link 
                  className={page === totalPages?"option-disabled":"option"}
                  disabled={page === totalPages}
                  onClick={handleNextPage}>
                    Next <BsChevronRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
       }
    </main>
  );
};

export default UserWallet;
