import React, { useEffect, useState } from "react";
import {
  IoMdCheckmarkCircle,
  IoIosCloseCircle,
  IoIosArrowUp,
  IoIosAddCircle,
  IoMdArrowDropupCircle,
} from "react-icons/io";
import { formatAmount } from "../../../Utility/functions/Helper";
import moment from "moment-timezone";
import { MdOutlineMotionPhotosOff } from "react-icons/md";
import void2 from "../../../image/Void.png";
import dnp from "../../../image/dnp.png";
import { FaBan } from "react-icons/fa";
import { isMobile } from "react-device-detect";

const HistoryBets = ({ history, isHistory }) => {
  const [value, setValue] = useState(50); // Initial value set to 50

  console.log("display", isHistory);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  const min = 0;
  const max = 100;

  const percentage = ((value - min) / (max - min)) * 100;
  const backgroundStyle = {
    background: `linear-gradient(to right, #179624 ${percentage}%, #ED1A3B ${percentage}%)`,
  };

  const HistoryData = history;

  return (
    <>
      {isHistory && (
        <div className="mainbox-history margint_main">
          <div className="row g-4">
            {HistoryData?.map((item) => {

              const playerData = item?.playerData;

              const eventIds = playerData?.map(
                (data) => data?.playerevent?.eventId
              );

              const uniqueEventIds = new Set(eventIds);

              let playerlength = item?.playerData?.length;

              let money = item?.wager;

              const mainResult = item?.defineresult;

              return (
                <div className="col-md-12">
                  <div className="mainbox_inner">
                    <div className="first_main">
                      <div className="main_left">
                        <h3 className="head_p">Entry:</h3>
                        <p>{item?.id}</p>
                      </div>
                      <div className="main_right">
                        <h4 style={{ overflow: "none!important" }}>
                          Entry Fee: <span> ${formatAmount(money)}</span>
                        </h4>
                      </div>
                    </div>
                    <div
                      className={
                        item.defined && mainResult == "win"
                          ? "second_main"
                          : "second_main lost"
                      }
                    >
                      <p>
                        {item?.winlPlayer} Correct Pays:{" "}
                        <span>${formatAmount(item?.projectedAmount)}</span>
                      </p>
                    </div>

                   <div className="refund_text">
                   {item?.void ? (
                                <label
                                  style={{ color: "#C02640", fontSize: "16px" }}
                                >
                                  Entry Void, ${item.wager} entry fee refunded.
                                </label>
                              ):
                                item?.defineresult == "refund" ? (
                                <span style={{ color: "#C02640", fontSize: "16px" }}>
                                  Entry Refunded due to DNP or Push Players
                                </span>
                              )  : 
                              ""}
                   </div>

                    <h3 className="head_p mb-4">Players:</h3>
                    {playerData?.map((player) => {
                      const teamHid = player?.playerevent?.teamH?.id;

                      const teamAid = player?.playerevent?.teamA?.id;

                      const teamHname = player?.playerevent?.teamH?.abv;

                      const teamAname = player?.playerevent?.teamA?.abv;

                      const Game_status = player?.playerevent?.status;

                      const reult = player?.defineresult;

                      const finalpoint = player?.finalpoints;

                      const rangeWidth =
                        (player?.finalpoints / (player?.points * 2)) * 100;

                      return (
                        <div
                          className={
                            player?.defined && reult == "win"
                              ? "main_item_card green_item"
                              : player?.defined && reult == "lose"
                              ? "main_item_card red_item"
                              : "main_item_card"
                          }
                        >
                          <div className="item_card">
                            <div className="item_left">
                              <div className="middle_div">
                                <div className="name_card name_wrap">
                                  <h3 style={{marginLeft:isMobile?"0px":"40px"}}>{player?.name}</h3>
                                  {player?.game_type == 2 ? (
                                    <p>{player?.single_event}</p>
                                  ) : (
                                    <p>
                                      <span>{player?.position}</span> |{" "}
                                      {player?.team == teamHid
                                        ? teamHname
                                        : teamAname}{" "}
                                      {""}
                                      {player?.team == teamHid
                                        ? "VS"
                                        : "@"}{" "}
                                      {""}
                                      {player?.team == teamHid
                                        ? teamAname
                                        : teamHname}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="item_right">
                              <p>
                                {moment(player?.event_time).format(
                                  "MMM DD, h:mm a"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="item_bottom item_card mt-2">
                            <div className="item_left">
                              <div className="icon-check">
                                {player?.void ? (
                                  <div className="item_icon void_icon">
                                    <FaBan/>
                                  </div>
                                ) : player?.dnp && !player?.result ? (
                                  <div className="item_icon">
                                    <img
                                      alt="bettdraft"
                                      src={dnp}
                                      style={{
                                        height: "35px",
                                        marginTop: "-15px",
                                      }}
                                    />
                                  </div>
                                ) : player?.defined && reult == "win" ? (
                                  <div className="item_icon">
                                    <IoMdCheckmarkCircle />
                                  </div>
                                ) : player?.defined && reult == "lose" ? (
                                  <div className="item_icon">
                                    <IoIosCloseCircle />
                                  </div>
                                ) : (
                                  <div className="item_icon" style={{ visibility: "hidden", height: "35px" }}></div>
                                )}
                              </div>

                              <div className="middle_div">
                                <div className="name_card">
                                  <IoMdCheckmarkCircle />
                                  <div
                                    className={
                                      player?.defined
                                        ? "w-100 range_div1 game_completed"
                                        : "w-100 range_div1"
                                    }
                                  >
                                    <input
                                      type="range"
                                      id="myRange"
                                      min={1}
                                      max={3}
                                      value={2}
                                      // onChange={handleChange}
                                      style={backgroundStyle}
                                    />
                                    <p>
                                      {player?.points}
                                      {" "}
                                      {player?.statAbv}
                                    </p>
                                    <div
                                      className="arrow_icon"
                                      style={{
                                        left:
                                          rangeWidth > 100
                                            ? isMobile?"90%":"98%"
                                            : `calc(${rangeWidth}% - 10px)`,
                                        position: "relative",
                                        top: "-33px",
                                      }}
                                    >
                                      <span
                                        className="finalpoint"
                                        style={{
                                          display: "block",
                                          marginTop:isMobile?"-15px":"-18px",
                                          color: "#000",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {finalpoint}
                                      </span>
                                      <IoMdArrowDropupCircle />
                                    </div>
                                  </div>
                                  <IoIosCloseCircle />
                                </div>
                              </div>
                            </div>
                            <div className="item_right">
                              <a className="btn_light">
                                {player?.defined
                                  ? "Completed"
                                  : Game_status == 2
                                  ? "inPlay"
                                  : Game_status == 6
                                  ? "Postponed"
                                  : Game_status == 7
                                  ? "Cancelled"
                                  : "Scheduled"}
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default HistoryBets;
