import React from 'react';
import {InputGroup,label} from 'react-bootstrap';

const PasswordChecklist = ({ password }) => {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const isLengthValid = password.length >= 8;

  // console.log("password",password,hasLowerCase,hasUpperCase,hasNumbers,hasSpecialChars);

  return (
    <div style={styles.container}>
      {/* <p style={styles.label}>Your Password must contains 8 to 20 characters and include:</p> */}
      <div style={styles.checklistItem}>
      <div className='alltext'>
        <span style={hasLowerCase ? styles.valid : styles.invalid}>
          {hasLowerCase ?<i className="fa fa-check pwd-icon2"></i>:
          <i className="fa fa-times pwd-icon"></i>}</span>
          <span style={styles.fontcolor}>1 Lower case letter,</span></div>
      </div>
      <div style={styles.checklistItem}>
      <div className='alltext'>
        <span style={hasUpperCase ? styles.valid : styles.invalid}> 
        {hasUpperCase ?<i className="fa fa-check pwd-icon2"></i>:
        <i className="fa fa-times pwd-icon"></i>}</span>
        <span style={styles.fontcolor}>1 Upper case letter,</span></div>
      </div>
      <div style={styles.checklistItem}>
      <div className='alltext'>
        <span style={hasNumbers ? styles.valid : styles.invalid}>
          {hasNumbers ?<i className="fa fa-check pwd-icon2"></i>:
          <i className="fa fa-times pwd-icon"></i>}</span>
                    <span style={styles.fontcolor}>1 Number and</span></div>
      </div>
      <div style={styles.checklistItem}>
        <div className='alltext'>
        <span style={hasSpecialChars ? styles.valid : styles.invalid}>
          {hasSpecialChars ?<i className="fa fa-check pwd-icon2"></i>:
          <i className="fa fa-times pwd-icon"></i>}</span>
          <span style={styles.fontcolor}>1 Special character.</span></div>
      </div>
      {/* <div style={styles.checklistItem}>
        <span style={isLengthValid ? styles.valid : styles.invalid}><i className="fa fa-times pwd-icon"></i> Minimum 8 characters</span>
      </div> */}
    </div>
  );
};

const styles = {
  container: {
    marginTop: 10,
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
  },
  checklistItem: {
    display:"flex",
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
  },
  valid: {
    color: 'green',
    marginBottom:"5px",
    fontSize:"12px"
  },
  invalid: {
    color: 'red',
    marginBottom:"5px",
    fontSize:"12px"
  },
  fontcolor:{
    color:'#0D3862',
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "14.66px",
    marginLeft:"4px"
    
  }
};

export default PasswordChecklist;
