import { useEffect, useState } from "react";
// import "./Join-Now.css";
import { Form, Button } from "react-bootstrap";
import { endPoints } from "../../constant/Environment";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../redux/Actions/Actions";
import { addData, updateUser } from "../../Utility/API";
import { getCookie } from "../../Utility/functions/Helper";
import { useNavigate } from "react-router-dom";

import Toaster from "../../components/Toast";
import Loader from "../../components/Loader";
import { ErrorToast, SuccesToast } from "../../components/Toast/message";
import { FiChevronDown } from "react-icons/fi";
import { FaBullseye } from "react-icons/fa";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";
import { Helmet } from "react-helmet";
import {
  geoClientMob,decryptUsingAES128
} from "../../Utility/ConfigData";
var geoClient = null;
var result = {};
const JoinNowStep = () => {

  const [newDate, setNewDate] = useState({ year: "", month: "", day: "" });
  const dispatch = useDispatch();
  const [monthError, setMonthError] = useState(false);
  const [dayError, setDayError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [validateButton, setValidateButton] = useState(true);
  const [refer, setRefer] = useState("");
  const [userName, setUserName] = useState("");
  const [date, setDate] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoadinig] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [montherr, setMonthErr] = useState(false);
  const [dateerr, setDateErr] = useState(false);
  const [yearerr, setYearErr] = useState(false);
  const state = useSelector((state) => state);
 
  const navigate = useNavigate();
  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const onChange = (e, fieldName) => {
    setError("");
    if (fieldName == "date") {
      let age = getAge(e);

      if (age >= 18) {
        setDate(e);
        setAgeError(false);
      } else {
        setAgeError(true);
        setDate(e);
      }
    } //setDate(e.target.value);
    if (fieldName == "Username") setUserName(e.target.value);
  };

  const handleSubmit = async (event) => {


    setMonthErr(false);
    
    setDateErr(false);
    
    setYearErr(false);
    
    setAgeError(false);

    // console.log("newDate",newDate);
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    if (newDate.month === "") {
      // setAgeError(true);
      setMonthErr(true);
      return;
    } else if (newDate.day === "") {
      // setAgeError(true);
      setDateErr(true);
      return;
    } else if (newDate.year === "") {
      // setAgeError(true);
      setYearErr(true);
      return;
    }
    else {
      setAgeError(false);
    }

    let modifyMonth = parseInt(newDate.month)+1;
    let modifyDay = newDate.day;
    if (modifyMonth.toString().length < 2) modifyMonth = "0" + modifyMonth;
    if (modifyDay.length < 2) modifyDay = "0" + modifyDay;
    let dob = `${newDate.year}-${modifyMonth}-${modifyDay}`;
    let age = getAge(dob);
    // console.log('age',modifymonth);

    if (age >= 18) {
      setDate(dob);
      setAgeError(false);
    } else {
      setAgeError(true);
      setDate(dob);
      return;
    }
  let userId = state.auth?.googleUser?.idUser
  console.log('userId',userId)
    let data = {
      birthDate: dob,
      idUser:userId,
      username:userName,
      promoCode:refer,
    //   login
    //   promoId: 0,
    //   promoCode: refer,
    //   referral: getCookie("ref"),
    //   campaign: getCookie("campaign"),
    };
     console.log("data", data);
    let url = `${endPoints.api.USER_PROFILE}`;
    setIsLoadinig(true);
    await updateUser(url, data)
      .then((response) => {
        setIsLoadinig(false);
        if (response.status === "success") {
          setIsLoadinig(false);
          //SuccesToast("Account completed Successfully");
          if(response.data.userStatus == "1"){
            localStorage.setItem("session", response.data.sessionId);
            dispatch({
            type: Actions.GOOGLE_LOGIN,
            data: { googleUser: response.data },
          });
            navigate('/join-now-pre')
          }else{
            let maintenanceData = state?.auth.maintenanceData;
            if(maintenanceData?.specifiers?.maintenance_mode &&  !maintenanceData?.whiteList.includes(response.data.idUser)){
              navigate("/under-maintenance");
              return;
            }
            localStorage.setItem(
              "walletUserId",
              JSON.stringify(response.data.account.fwUserId)
            );
            localStorage.setItem("session", response.data.sessionId);
            localStorage.setItem("user", JSON.stringify(response.data));
            sessionStorage.setItem("user", JSON.stringify(response.data));
            if (response.data.validation === "0") {
              // if validation 0 then naviagte verficatoin pageuse vadlaition 1 to check
              dispatch({
                type: Actions.LOGIN,
                data: { user: response.data },
              });
              navigate("/verification");
            } 
            else {
                 result = response.data;
                dispatch({
                  type: Actions.LOGIN,
                  data: { user: response.data },
                });

                dispatch({
                  type: Actions.TEST_CASE,
                  data: false,
                });

                  geoClient = geoClientMob.createClient();
              
                    // Set up parameters
                    geoClient.setUserId(result.idUser);
                    geoClient.setReason('LOGIN');
                    geoClient.setLicense(localStorage.getItem('license2'));
              
                        // console.log("request",geoClient.data);
              
                    geoClient.events.on('hint', function(BROWSER_GEOLOCATION_DENIED, data) {
                    //e.g. show hint messages to users
                    })
                    .on('engine.success', function(text, xml) {
                    // $.post('/your-service-to-decrypt-and-verify-the-response', function(text) {
                    // //e.g. fail or pass your user
                    // });
                    // console.log("text",text);
              
                    let decryptResponse = decryptUsingAES128(text);
                    // console.log("result",decryptResponse);
                    })
                    .on('*.failed', function(code, message) {
                    if (this.event === 'revise.failed' || this.event === 'config.failed' || this.event === 'engine.failed') {
                   if (code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION) {
                    //network issue, we can add retry logic if needed
                    // alert(message);
                    ErrorToast(message);
                    } else if (code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT || code ===
                    geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED) {
                      // alert(message);
                      // ErrorToast(message);
                    //e.g. update license and retry geolocating
                    } else if (code ===
                    geoClient.ErrorCodes.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS) {
                      ErrorToast(message);
                    //it's safe to be ignored, or we can wait for previous request to complete and try again
                    } else {
                    //something went wrong with GeoComply service, your license or your custom data
                    //e.g. submit log to record the issue
                    ErrorToast(message);
                    }
                    } else if (this.event === 'browser.failed') {
                      // alert(message);
                      // ErrorToast(message);
                    //it's safe to be ignored, you can remove this block or add your own logic
                    }
                    });
                    // Request geolocation
                    geoClient.request();
                    
                

                // SuccesToast("Successfully login.");

                navigate("/");


              
            }
          }
        
        }
        else {
          setIsLoadinig(false);
          ErrorToast(response?.message || response?.data?.message);
        }
      })
      .catch((error) => {
        setIsLoadinig(false);
        ErrorToast(
          "Issue connecting to server (error code 15). Please contact support for further assistance."
        );
        console.error(error);
      });
  };

  const validateUsername = (value) => {
    let regUsername = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
    if (value.match(regUsername)) {
      return false;
    } else {
      return true;
    }
  };

  const _onBlurCall = () => {
    let validateFlag = validateUsername(userName);
    setValidateButton(validateFlag);
  };
  return (
    <div>
      <Helmet>
        <title>Join Now</title>
        <meta
          name="description"
          content="Join BettDraft's Daily Fantasy Sports experience. Win up to 125X your entry fee."
        />

        <link rel="canonical" href="https://dfs.bettdraft.com/join-now" />
      </Helmet>

      <section className="login-page-section">
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-md-12">
            <Toaster></Toaster>
            {isLoading && <Loader spinner={true} visible={isLoading} />}

            <Form
              className="login-form col-md-5"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <h2>Complete Your Account</h2>
              <Form.Group className="mb-0" controlId="Username">
                <Form.Label className="my-form-label">Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  value={userName}
                  onChange={(e) => onChange(e, "Username")}
                  onBlur={_onBlurCall}
                  isInvalid={userName.length > 0 && validateUsername(userName)}
                  required
                  maxLength={14}
                />
                <Form.Control.Feedback type="invalid">
                  {userName.length < 5
                    ? "Username should be 6-14 charachters long"
                    : "Username should be Alphanumeric"}
                </Form.Control.Feedback>
              </Form.Group>

              <div>
                <Form.Group className="mb-3"></Form.Group>

                <Form.Group controlId="formLastName">
                  <Form.Label className="my-form-label ">
                    Date of Birth
                  </Form.Label>
                  <div>
                    <MonthPicker
                      defaultValue={"MM"}
                      numeric // to get months as numbers
                      endYearGiven // mandatory if end={} is given in YearPicker
                      year={newDate.year} // mandatory
                      value={newDate.month} // mandatory
                      onChange={(month) => {
                        // mandatory
                        // let newmonth = month+1;
                        // console.log("month",month);
                        setNewDate((prev) => ({ ...prev, month }));
                        setMonthError(false);
                      }}
                      id={"month"}
                      classes={`dropdown-picker ${dayError ? "error" : ""}`}
                      optionClasses={"option"}
                    />
                    <DayPicker
                      defaultValue={"DD"}
                      year={newDate.year} // mandatory
                      month={newDate.month} // mandatory
                      endYearGiven // mandatory if end={} is given in YearPicker
                      value={newDate.day} // mandatory
                      onChange={(day) => {
                        // mandatory
                        setNewDate((prev) => ({ ...prev, day }));
                      }}
                      id={"day"}
                      classes={`dropdown-picker ${monthError ? "error" : ""}`}
                      optionClasses={"option"}
                    />

                    <YearPicker
                      defaultValue={"YYYY"}
                      start={1950} // default is 1900
                      end={2023} // default is current year
                      reverse // default is ASCENDING
                      value={newDate.year} // mandatory
                      onChange={(year) => {
                        // mandatory
                        setNewDate((prev) => ({ ...prev, year }));
                      }}
                      id={"year"}
                      classes={`dropdown-picker ${yearError ? "error" : ""}`}
                      optionClasses={"option"}
                    />
                  </div>

                  {ageError && (
                    <Form.Label
                      className="my-form-label"
                      style={{ color: "#dc3545" }}
                    >
                      You need to be at least 18 years of age to join BettDraft. 
                    </Form.Label>
                  )}

                 {montherr && (
                    <Form.Label
                      className="my-form-label"
                      style={{ color: "#dc3545" }}
                    >
                     Please enter the Month of your Date of Birth.
                    </Form.Label>
                  )}

              {dateerr && (
                    <Form.Label
                      className="my-form-label"
                      style={{ color: "#dc3545" }}
                    >
                     Please enter the Day of your Date of Birth.
                    </Form.Label>
                  )}

                   {yearerr && (
                    <Form.Label
                      className="my-form-label"
                      style={{ color: "#dc3545" }}
                    >
                     Please enter the Year of your Date of Birth. 
                    </Form.Label>
                  )}

                </Form.Group>
              </div>

              <Form.Group className="mb-3 password-box" controlId="PASSWORD">
                <Form.Label className="my-form-label mt-3">
                  Promo Code
                </Form.Label>
                <Form.Control
                  placeholder="Enter a Promo or Referral Code (optional)"
                  type={"text"}
                  value={refer}
                  onChange={(e) => setRefer(e.target.value)}
                />
              </Form.Group>
              <div style={{ display: "flex" }}>
                <div>
                  <Form.Check inline required type="checkbox" />
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="login-p"
                    style={{
                      fontSize: "12px",
                      marginLeft: "-10px",
                      marginTop: "4px",
                    }}
                  >
                    * I agree to the{" "}
                  </div>
                  <div
                    className="login-p login-a"
                    style={{
                      marginTop: "4px",
                      marginLeft: "4px",
                      marginRight: "4px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/termsconditions"); //to open new page
                    }}
                  >
                    {"  "}Terms and Conditions,
                  </div>
                  <div
                    className="login-p login-a"
                    style={{
                      marginRight: "4px",
                    }}
                    onClick={() => {
                      navigate("/privacy-policy"); //to open new page
                    }}
                  >
                    Privacy Policy
                  </div>
                  <div
                    className="login-p"
                    style={{
                      fontSize: "12px",
                      marginTop: "4px",
                    }}
                  >
                    {" "}
                    and{" "}
                  </div>
                  <div
                    className="login-p login-a"
                    onClick={() => {
                      navigate("/houserules"); //to open new page
                    }}
                  >
                    House Rules
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <Form.Check inline required type="checkbox" />
                </div>
                <div
                  className="login-p"
                  style={{
                    fontSize: "12px",
                    marginLeft: "-10px",
                    marginTop: "4px",
                  }}
                >
                  * I confirm that I am at least 18 years of age and that the
                  information provided is accurate.
                </div>
              </div>

              <div className="user-upload">
                <h3>{error}</h3>
              </div>
              <div className="text-center">
                <Button
                  variant="primary"
                  className="login-btn-form btn btn-primary w-100"
                  type="submit"
                  disabled={validateButton}
                  style={{width:'50% !important'}}
                >
                  Complete Account
                </Button>
              </div>
              <div
                className="login-p"
                style={{ fontSize: "12px", marginTop: "10px" }}
              >
                By Clicking "join now" you agree to receiving messages and
                offers from BettDraft.
              </div>
              <span
                className="login-p"
                style={{
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                If you or someone you know has a gambling problem and wants
                help, call 1-800-522-4700 or chat at
              </span>
              <span
                className="login-a"
                style={{
                  marginTop: "4px",
                  marginLeft: "4px",
                  marginRight: "4px",
                  textDecoration: "underline",

                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open("https://www.ncpgambling.org/", "_blank"); //to open new page
                }}
              >
                ncpgambling.org
              </span>
              <div
                className="login-p"
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Already Have an Account?{" "}
                </span>{" "}
                <span
                  className="login-a mt-0"
                  style={{
                    marginLeft: "4px",
                    marginRight: "4px",
                    textDecoration: "underline",

                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Log in Here
                </span>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default JoinNowStep;
