import { Route, Outlet, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
const PrivateRoute =({ children })=> {
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { auth } = state;
  let maintenanceData = auth?.maintenanceData;
  let maintenanceFlag = maintenanceData?.specifiers?.maintenance_mode || false;
  if (maintenanceFlag && !maintenanceData?.whiteList.includes(auth?.user?.idUser)) {
    // If the user is not authenticated, you can redirect them to a login page or any other page.
    navigate('/under-maintenance'); // Replace with your login page path
    return null;
  }
  else if(window.location.pathname=="/userwallet" && !state.auth.isAuthenticated){
    navigate('/login');
  }

  return children;
}

export default PrivateRoute;