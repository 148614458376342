import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { endPoints } from "../../../constant/Environment";
import Toaster from "../../../components/Toast";
import Loader from "../../../components/Loader";
import { fetchAllData, getAllData } from "../../../Utility/API";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../redux/Actions/Actions";
import { ErrorToast } from "../../../components/Toast/message";
import { decryptUsingAES128, geoClientMob } from "../../../Utility/ConfigData";

var geoClient = null;

const Kyc = () => {
  let deviceId = "web";
  let params = new URLSearchParams(window.location.search);
  let userId = params.get("userId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoadinig] = useState(false);
  useEffect(() => {
    setIsLoadinig(true);
    getWalletInfo();
  }, []);
  function getWalletInfo() {
    let url = `${endPoints.apiBaseUrl}${endPoints.api.kyc_verify}${userId}`;

    fetchAllData(url)
      .then((res) => res.json())
      .then(async (response) => {
        //  console.log('response',response.data.validation);
        setIsLoadinig(false);
        if (response.status === "success" && response.data.validation === "1") {
          // dispatch({
          //     type: Actions.TEST_CASE,
          //     data: false,
          //   });
          // navigate("/");
          dispatch({
            type: Actions.TEST_CASE,
            data: false,
          });
          await GetUserById(userId);
          navigate({
            pathname: "/",
            search: "?signup=complete",
          });
          dispatch({
            type: Actions.GEO_RESPONSE, //Flag for the Geocomply response
            data: true,
          });
        } else {
          dispatch({
            type: Actions.LOGOUT, //User logout since Kyc not completed
          });
          dispatch({
            type: Actions.TEST_CASE,
            data: false,
          });
          navigate("/login");
        }
      })
      .catch((error) => {
        setIsLoadinig(false);
        console.error(error);
        dispatch({
          type: Actions.LOGOUT,
        });
        dispatch({
          type: Actions.TEST_CASE,
          data: false,
        });
        navigate("/login");
      });

    const GetUserById = async (userId) => {
      if (userId) {
        let url = `${endPoints.api.GET_BY_USERID}/${userId}`;
        getAllData(url).then((response) => {
          dispatch({
            type: Actions.LOGIN,
            data: { user: response.data },
          });
          dispatch({
            type: Actions.TEST_CASE,
            data: false,
          });

          geoClient = geoClientMob.createClient();

          // Set up parameters
          geoClient.setUserId(response.data.idUser);
          geoClient.setReason("SIGNUP");
          geoClient.setLicense(localStorage.getItem("license2"));

          // console.log("request",geoClient.data);

          geoClient.events
            .on("hint", function (BROWSER_GEOLOCATION_DENIED, data) {
              //e.g. show hint messages to users
            })
            .on("engine.success", function (text, xml) {
              // $.post('/your-service-to-decrypt-and-verify-the-response', function(text) {
              // //e.g. fail or pass your user
              // });
              // console.log("text",text);

              let payload = {
                deviceId: deviceId,
                userId: response.data.idUser,
                userName: `${response.data.name} ${response.data.surname}`,
              };

              let decryptResponse = decryptUsingAES128(text, payload);
              // console.log("result",decryptResponse);

              console.log("response", decryptResponse);
            })
            .on("*.failed", function (code, message) {
              if (
                this.event === "revise.failed" ||
                this.event === "config.failed" ||
                this.event === "engine.failed"
              ) {
                if (
                  code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION
                ) {
                  //network issue, we can add retry logic if needed
                  // alert(message);
                  ErrorToast(message);
                } else if (
                  code ===
                    geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT ||
                  code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED
                ) {
                  // alert(message);
                  // ErrorToast(message);
                  //e.g. update license and retry geolocating
                } else if (
                  code ===
                  geoClient.ErrorCodes
                    .CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS
                ) {
                  ErrorToast(message);
                  //it's safe to be ignored, or we can wait for previous request to complete and try again
                } else {
                  //something went wrong with GeoComply service, your license or your custom data
                  //e.g. submit log to record the issue
                  ErrorToast(message);
                }
              } else if (this.event === "browser.failed") {
                // alert(message);
                // ErrorToast(message);
                //it's safe to be ignored, you can remove this block or add your own logic
              }
            });
          // Request geolocation
          geoClient.request();

          console.log("call geoComply after kyc redirection");
        });
      }
    };
  }
  return <div style={{ marginTop: "300px" }}></div>;
};

export default Kyc;
