import React from "react";
import * as IoIcons from "react-icons/io";
import { BiInfoCircle, BiEnvelope } from "react-icons/bi";
import { FcMoneyTransfer } from "react-icons/fc";

export const SidebarData = [  
  {
    title: "Responsible settings",
    path: "/responsible-gaming-settings",
    icon: <IoIcons.IoIosWallet />,
    cName: "nav-text",
  },
  // {
  //   title: "Deposit Funds",
  //   path: "/userwallet",
  //   icon: <IoIcons.IoIosKey />,
  //   cName: "nav-text",
  // },
  {
    title: "My Entries",
    path: "/my-entries",
    icon: <FcMoneyTransfer />,
    cName: "nav-text",
  },
  {
    title: "My Account",
    path: "/personal-information",
    icon: <IoIcons.IoIosPerson />,
    cName: "nav-text",
  },

  {
    title: "My Wallet",
    path: "/userwallet",
    icon: <IoIcons.IoIosWallet />,
    cName: "nav-text",
  },
  {
    title: "Promo Code",
    path: "/promo-code",
    icon: <BiInfoCircle />,
    cName: "nav-text",
  },

  {
    title: "Contact Support",
    path: "/contact-us",
    icon: <BiEnvelope />,
    cName: "nav-text",
  },
];
