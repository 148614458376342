import "./login.css";
import React, { useState, useRef, useEffect } from "react";

import { Col } from "react-bootstrap";
import { fetchAllData } from "../../Utility/API";
import { Form, Button } from "react-bootstrap";
import { endPoints } from "../../constant/Environment";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../redux/Actions/Actions";
import { addData } from "../../Utility/API";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import {
  AiTwotoneEyeInvisible,
  AiFillEyeInvisible,
  AiFillEye,
} from "react-icons/ai";
import Toaster from "../../components/Toast";
import Loader from "../../components/Loader";
import {
  decryptUsingAES128,
  clientMob,
  geoClientMob,
} from "../../Utility/ConfigData";
import { ErrorToast, SuccesToast } from "../../components/Toast/message";

import {
  isMobile,
  isChrome,
  isFirefox,
  isSafari,
  isEdge,
  isIE,
  isAndroid,
} from "react-device-detect";
import { GeoComplyKey } from "../../constant/GeoEnv";
import HelmetExport, { Helmet } from "react-helmet";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";

import { FcGoogle } from "react-icons/fc";
var result = {};
var client1 = null;

var geoClient = null;

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoadinig] = useState(false);
  const state = useSelector((state) => state);
  const [userResponse, setUserResponse] = useState({});
  const [googledata, setGoogledata] = useState([]);
  const [kycmodal, setkycmodal] = useState(false);
  const [kycmsg, setKycmsg] = useState("");

  function Init(GeoComply) {
    GeoComply.Client.on("connect", function () {
      console.log("GeoComply Client connected");
      //  console.log('result',result)
      triggerGeolocation(
        localStorage.getItem("license"),
        "login",
        result.idUser,
        "1234567890"
      );
    })
      .on("error", function (errorCode, errorMessage) {
        setIsLoadinig(false);
        switch (errorCode) {
          case GeoComply.Client.CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE:
            console.log(
              "Connection to GeoComply Client failed. Details: ErrCode=[" +
                errorCode +
                "]; ErrMessage=[" +
                errorMessage +
                "]"
            );
            // ErrorToast("Review if the Location check software is installed and running (Code 612). For assitance, contact support.");

            //if (navigator.appVersion.indexOf('Win')) {
            if (navigator.userAgentData.platform == "macOS") {
              // if (
              //   window.confirm(
              //     "We need to verify your location before you can use BettDraft. Click Ok to install GeoComply, so that we can validate your location."
              //   )
              // ) {
              //   window.open(GeoComplyKey.installer_link_mac, "_self");
              // } else {
              // }
              // } else if (navigator.appVersion.indexOf('Mac') != -1) {
              dispatch({
                type: Actions.GEOSOFTWARE,
                geoFlag: true,
              });
            } else if (navigator.userAgentData.platform == "Windows") {
              // if (
              //   window.confirm(
              //     "We need to verify your location before you can use BettDraft. Click Ok to install GeoComply, so that we can validate your location."
              //   )
              // ) {
              //   window.open(GeoComplyKey.installer_link_win, "_self");
              // } else {
              // }
              dispatch({
                type: Actions.GEOSOFTWARE,
                geoFlag: true,
              });
            }
            break;

          case GeoComply.Client.CLNT_ERROR_LOCAL_SERVICE_UNSUP_VER:
            console.log(
              "Connection to GeoComply Client failed. Details: ErrCode=[" +
                errorCode +
                "]; ErrMessage=[" +
                errorMessage +
                "]"
            );
            ErrorToast(
              "Incompatible local service version (Code 615). Please update to the latest software version. For assistance, contact support."
            );
            break;

          case GeoComply.Client.CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION:
            console.log(
              "GeoLocation failed. Details: ErrCode=[" +
                errorCode +
                "]; ErrMessage=[" +
                errorMessage +
                "]"
            );
            // ErrorToast(
            //   "There was a technical issue (Code 603). Please try again. For assitance, contact support."
            // );
            break;

          case GeoComply.Client.CLNT_ERROR_LICENSE_EXPIRED:
          case GeoComply.Client.CLNT_ERROR_INVALID_LICENSE_FORMAT:
            console.log(
              "GeoLocation failed. Details: ErrCode=[" +
                errorCode +
                "]; ErrMessage=[" +
                errorMessage +
                "]"
            );
            ErrorToast(
              "There was a technical issue (Code 606). For assitance, contact support."
            );
            break;

          case GeoComply.Client.CLNT_ERROR_CLIENT_LICENSE_UNAUTHORIZED:
            console.log(
              "GeoLocation failed. Details: ErrCode=[" +
                errorCode +
                "]; ErrMessage=[" +
                errorMessage +
                "]"
            );
            ErrorToast(
              "There was a technical issue (Code 607). For assitance, contact support."
            );
            break;

          case GeoComply.Client.CLNT_ERROR_CLIENT_SUSPENDED:
          case GeoComply.Client.CLNT_ERROR_DISABLED_SOLUTION:
            console.log(
              "GeoLocation failed. Details: ErrCode=[" +
                errorCode +
                "]; ErrMessage=[" +
                errorMessage +
                "]"
            );
            ErrorToast(
              "Geolocation disabled (Code 605). For assitance, contact support."
            );
            break;

          case GeoComply.Client.CLNT_ERROR_SERVER_COMMUNICATION:
          case GeoComply.Client.CLNT_ERROR_NOT_CERTIFIED_BINARIES:
          case GeoComply.Client.CLNT_ERROR_NETWORK_CONNECTION:
          case GeoComply.Client.CLNT_ERROR_INVALID_CUSTOM_FIELDS:
          case GeoComply.Client.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS:
          case GeoComply.Client.CLNT_ERROR_UNEXPECTED:
            console.log(
              "GeoLocation failed. Details: ErrCode=[" + errorCode + "]"
              // ErrMessage=[" +
              // errorMessage +
              // "]"
            );
            // ErrorToast(
            //   "An unexpected error occurred (Code 600). Please try again. For assitance, contact support."
            // );
            break;
          case GeoComply.Client.CLNT_ERROR_WRONG_OR_MISSING_PARAMETER:
            console.log(
              "GeoLocation failed. Details: ErrCode=[" +
                errorCode +
                "]; ErrMessage=[" +
                errorMessage +
                "]"
            );
            ErrorToast(
              "There was a technical issue (Code 699). For assistance, contact support."
            );
            break;

          default:
            console.log(
              "GeoLocation failed. Details: ErrCode=[" +
                errorCode +
                "]; ErrMessage=[" +
                errorMessage +
                "]"
            );
            ErrorToast(errorMessage);
            break;
        }
      })
      .on("geolocation", function (data) {
        console.log("GeoPacket created: " + data);
        setIsLoadinig(false);
        if (data) {
          // if (result) {
          //   dispatch({
          //     type: Actions.LOGIN,
          //     data: { user: result },
          //   });
          // }
          // dispatch({
          //   type: Actions.TEST_CASE,
          //   data: false,
          // });

          // navigate("/");
          let decryptResponse = decryptUsingAES128(data);

          // if (result) {
          //   dispatch({
          //     type: Actions.LOGIN,
          //     data: { user: result },
          //   });
          // }
          // dispatch({
          //   type: Actions.TEST_CASE,
          //   data: false,
          // });

          // navigate("/");
          // const allResult = decryptResponse?.elements[0]?.elements;
          // let filter = allResult?.filter(
          //   (d) =>
          //     d.name == "error_code" ||
          //     d.name == "error_message" ||
          //     d.name == "error_details" ||
          //     d.name == "error_summary" ||
          //     d.name == "troubleshooter"
          // );
          // // console.log('filter',filter)

          // if (filter[0]?.elements[0]?.text == "1") {
          //   for (var item of filter[4]?.elements) {
          //     if (item.elements[0]?.text) {
          //       alert(item.elements[0]?.text);
          //       break;
          //     }
          //   }
          // } else if (filter[0]?.elements[0]?.text == "0") {
          //   if (result) {
          //     dispatch({
          //       type: Actions.LOGIN,
          //       data: { user: result },
          //     });
          //   }
          //   dispatch({
          //     type: Actions.TEST_CASE,
          //     data: false,
          //   });

          //   navigate("/");
          // }
        }
      })
      .on("log", function (log) {
        //  console.log("log: " + log);

        setIsLoadinig(false);
        //  window.GeoComply.Client.disconnect();
      })
      .on("my_ip_success", function (ip) {
        console.log("Client Ip Changed: " + ip);
        GeoComply.Client.ackMyIpSuccess();
      })
      .on("my_ip_failure", function (code, message) {
        console.log(
          "Client Ip Failure: code=[" + code + "]; message=[" + message + "]"
        );
        ErrorToast(message);
      });
  }
  function triggerGeolocation(
    license,
    geolocationReason,
    userId,
    userPhoneNumber
  ) {
    // Sets the license to the object. The license should be requested // by app from GeoComply License service. By default the license is // expired every 24 hours. The application should prepare to
    // process CLNT_ERROR_LICENSE_EXPIRED error code in onError event // handler, request a new license from GeoComply License service in // that case and repeat geolocation.
    window.GeoComply.Client.setLicense(license);
    // specifies the reason for geolocation (Login or Periodic).
    // the reason is shown in geolocation transaction report in GeoComply Back Office site.
    // Please note, that sending us the reason for geolocation will // greatly help us in a troubleshooting
    window.GeoComply.Client.setGeolocationReason(geolocationReason);
    // specifies the id of a user logged into your app. The id is
    // associated with the geolocation result. The user id is shown
    // in geolocation transaction report in GeoComply Back Office site.
    // Please note, that sending us unique username is very important because // it greatly affects our reporting
    window.GeoComply.Client.setUserId(userId);
    // Sets Session Key custom field. This is just an example of optional // custom field.
    //window.GeoComply.Client.customFields.set('session_key', 'Your SessionKey');
    // Triggers a process to get geolocation data. When geolocation // data is available onGeolocationAvailable() event is fired.
    window.GeoComply.Client.requestGeolocation();

    // window.GeoComply.client.setAutoOpenAppStore(false);
    // window.GeoComply.client.setAutoOpenPlayStore(false);

    ///
  }

  let deviceId = "web";

  let operatingPlatform = navigator.platform;

  let browsername = window.navigator;

  function devicetype() {
    if (isMobile) return "Mobile web";
    else return "web";
  }

  function BrowserInfo() {
    if (isChrome) return "Google Chrome";
    if (isFirefox) return "Mozilla Firefox";
    if (isSafari) return "Apple Safari";
    if (isEdge) return "Microsoft Edge";
    if (isIE) return "Internet Explorer";
    return "Unknown Browser";
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "module";
    script.src =
      "https://cdn.geocomply.com/plc/4.0.0.4/react.js/geocomply-client.min.js";
    script.async = true;
    script.onload = () => {
      setTimeout(() => {}, 5000);
    };
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    getGeolocation();
    getGeolocation2();
  }, []);

  function getGeolocation2() {
    setIsLoadinig(true);
    let url = `${endPoints.apiBaseUrl}${endPoints.api.geocomplyLience1}`;

    axios
      .get(url)
      .then((response) => {
        let result = response?.data?.data?.split('">')[1];
        result = result?.split("</")[0];
        // console.log('result',result)
        localStorage.setItem("license2", result);

        setIsLoadinig(false);
      })
      .catch((error) => {
        setIsLoadinig(false);

        console.error(error);
      });
  }

  function getGeolocation() {
    setIsLoadinig(true);
    let url = `${endPoints.apiBaseUrl}${endPoints.api.geocomplyLience}`;
    axios
      .get(url)
      .then((response) => {
        let result = response?.data?.data?.split('">')[1];
        result = result?.split("</")[0];
        // console.log('result',result)
        localStorage.setItem("license", result);
        var initConfig = {
          //   oobeeUrl: 'url of new Oobee Server version 2.x',
          oobeeUrl: GeoComplyKey.oobeeUrl,
          license: result || localStorage.getItem("license"),
        };
        if (client1 == null) {
          client1 = clientMob.createClient(initConfig);
        }

        setIsLoadinig(false);
      })
      .catch((error) => {
        setIsLoadinig(false);

        console.error(error);
      });
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    let data = {
      login: email,
      passwd: password,
      deviceId: deviceId,
      platform: operatingPlatform,
      browser: BrowserInfo(),
      device: devicetype(),
    };

    if (email && password) {
      let url = `${endPoints.api.LOGIN_ENDPOINT}`;
      setIsLoadinig(true);
      await addData(url, data)
        .then(async (response) => {
          if (response.data.status === "success") {
            let maintenanceData = state?.auth.maintenanceData;
            if (
              maintenanceData?.specifiers?.maintenance_mode &&
              !maintenanceData?.whiteList.includes(response.data.data.idUser)
            ) {
              navigate("/under-maintenance");
              return;
            }
            localStorage.setItem(
              "walletUserId",
              JSON.stringify(response.data.data.account.fwUserId)
            );

            localStorage.setItem("session", response.data.data.sessionId);

            localStorage.setItem("user", JSON.stringify(response.data.data));
            sessionStorage.setItem("user", JSON.stringify(response.data));
            if (response.data.data.validation === "1") {
              result = response.data.data;
              setUserResponse(response.data.data);

              if (response.data.data.bannerPramotion == null) {
                dispatch({
                  type: Actions.POPUP_DATA,
                  data: [],
                });
              } else {
                dispatch({
                  type: Actions.POPUP_DATA,
                  data: [response.data.data.bannerPramotion],
                });
              }

              dispatch({
                type: Actions.LOGIN,
                data: { user: response.data.data },
              });

              dispatch({
                type: Actions.POPUP_MODAL,
                data: true,
              });

              dispatch({
                type: Actions.PROMO_PAGE,
                data: false,
              });

              dispatch({
                type: Actions.TEST_CASE,
                data: false,
              });

              geoClient = geoClientMob.createClient();

              // Set up parameters
              geoClient.setUserId(result.idUser);
              geoClient.setReason("LOGIN");
              geoClient.setLicense(localStorage.getItem("license2"));

              // console.log("request",geoClient.data);

              geoClient.events
                .on("hint", function (BROWSER_GEOLOCATION_DENIED, data) {
                  //e.g. show hint messages to users
                })
                .on("engine.success", function (text, xml) {
                  let payload = {
                    deviceId: deviceId,
                    userId: response.data.data.idUser,
                    userName: `${response.data.data.name} ${response.data.data.surname}`,
                  };

                  let decryptResponse = decryptUsingAES128(text, payload);
                  // console.log("result",decryptResponse);

                  console.log("response", decryptResponse);
                })
                .on("*.failed", function (code, message) {
                  if (
                    this.event === "revise.failed" ||
                    this.event === "config.failed" ||
                    this.event === "engine.failed"
                  ) {
                    if (
                      code ===
                      geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION
                    ) {
                      //network issue, we can add retry logic if needed
                      // alert(message);
                      ErrorToast(message);
                    } else if (
                      code ===
                        geoClient.ErrorCodes
                          .CLNT_ERROR_LICENSE_INVALID_FORMAT ||
                      code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED
                    ) {
                      // alert(message);
                      // ErrorToast(message);
                      //e.g. update license and retry geolocating
                    } else if (
                      code ===
                      geoClient.ErrorCodes
                        .CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS
                    ) {
                      ErrorToast(message);
                      //it's safe to be ignored, or we can wait for previous request to complete and try again
                    } else {
                      //something went wrong with GeoComply service, your license or your custom data
                      //e.g. submit log to record the issue
                      ErrorToast(message);
                    }
                  } else if (this.event === "browser.failed") {
                    // alert(message);
                    // ErrorToast(message);
                    //it's safe to be ignored, you can remove this block or add your own logic
                  }
                });
              // Request geolocation
              geoClient.request();

              // SuccesToast("Successfully login.");

              navigate("/");
            } else {
              setIsLoadinig(false);
              ErrorToast(
                "Your account has not passed our KYC checks. Please contact customer support."
              );
            }
          } else if (response.data.status === "kycfailed") {
            localStorage.setItem("session", response.data.data.sessionId);

            localStorage.setItem("user", JSON.stringify(response.data.data));
            sessionStorage.setItem("user", JSON.stringify(response.data));
            dispatch({
              type: Actions.LOGIN,
              data: { user: response.data.data },
            });
            dispatch({
              type: Actions.TEST_CASE,
              data: false,
            });
            setKycmsg(response.data.message);
            setkycmodal(true);
            setIsLoadinig(false);
          } else {
            setIsLoadinig(false);
            ErrorToast(response.data.message);
          }
        })
        .catch((error) => {
          setIsLoadinig(false);
          ErrorToast(
            "Issue connecting to server (error code 15). Please contact support for further assistance."
          );
          console.error(error);
        });
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      //  console.log("Google login successful", tokenResponse);
      // You can now use the tokenResponse to authenticate the user in your app
      if (tokenResponse.access_token) {
        const userInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
        );
        const resultData = userInfo.data;

        const { email, picture, family_name, given_name, sub } = resultData;
        let data = {
          firstname: given_name,
          lastname: family_name,
          email: email,
          picture: picture,
          username: "",
          birthDate: "",
          promoCode: "",
          google_code: sub,
          deviceId: deviceId,
          platform: operatingPlatform,
          browser: BrowserInfo(),
          device: devicetype(),
        };

        let url = `${endPoints.api.GOOGLE_LOGIN}`;
        setIsLoadinig(true);
        await addData(url, data)
          .then(async (response) => {
            if (response.data.status === "success") {
              setIsLoadinig(false);

              if (response.data.data.userStatus == "1") {
                // navigate("/join-now-pre");
                setGoogledata([response.data.data]);
                let data = response.data.data;
                navigate("/signup", { state: data });
              } else {
                localStorage.setItem("session", response.data.data.sessionId);
                dispatch({
                  type: Actions.GOOGLE_LOGIN,
                  data: { googleUser: response.data.data },
                });
                let maintenanceData = state?.auth.maintenanceData;
                if (
                  maintenanceData?.specifiers?.maintenance_mode &&
                  !maintenanceData?.whiteList.includes(
                    response.data.data.idUser
                  )
                ) {
                  navigate("/under-maintenance");
                  return;
                }
                localStorage.setItem(
                  "walletUserId",
                  JSON.stringify(response.data.data.account.fwUserId)
                );
                localStorage.setItem("session", response.data.data.sessionId);
                localStorage.setItem(
                  "user",
                  JSON.stringify(response.data.data)
                );
                sessionStorage.setItem("user", JSON.stringify(response.data));
                if (response.data.data.validation === "1") {
                  // if validation 0 then naviagte verficatoin pageuse vadlaition 1 to check
                  //   dispatch({
                  //     type: Actions.LOGIN,
                  //     data: { user: response.data.data },
                  //   });
                  //   navigate("/verification");
                  // } else {
                  result = response.data.data;
                  setUserResponse(response.data.data);
                  dispatch({
                    type: Actions.LOGIN,
                    data: { user: response.data.data },
                  });

                  dispatch({
                    type: Actions.POPUP_MODAL,
                    data: true,
                  });

                  if (response.data.data.bannerPramotion == null) {
                    dispatch({
                      type: Actions.POPUP_DATA,
                      data: [],
                    });
                  } else {
                    dispatch({
                      type: Actions.POPUP_DATA,
                      data: [response.data.data.bannerPramotion],
                    });
                  }

                  dispatch({
                    type: Actions.TEST_CASE,
                    data: false,
                  });

                  geoClient = geoClientMob.createClient();

                  // Set up parameters
                  geoClient.setUserId(result.idUser);
                  geoClient.setReason("LOGIN");
                  geoClient.setLicense(localStorage.getItem("license2"));

                  // console.log("request",geoClient.data);

                  geoClient.events
                    .on("hint", function (BROWSER_GEOLOCATION_DENIED, data) {
                      //e.g. show hint messages to users
                    })
                    .on("engine.success", function (text, xml) {
                      let payload = {
                        deviceId: deviceId,
                        userId: response.data.data.idUser,
                        userName: `${response.data.data.name} ${response.data.data.surname}`,
                      };

                      let decryptResponse = decryptUsingAES128(text, payload);
                      console.log("result", decryptUsingAES128(text));
                    })
                    .on("*.failed", function (code, message) {
                      if (
                        this.event === "revise.failed" ||
                        this.event === "config.failed" ||
                        this.event === "engine.failed"
                      ) {
                        if (
                          code ===
                          geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION
                        ) {
                          //network issue, we can add retry logic if needed
                          // alert(message);
                          ErrorToast(message);
                        } else if (
                          code ===
                            geoClient.ErrorCodes
                              .CLNT_ERROR_LICENSE_INVALID_FORMAT ||
                          code ===
                            geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED
                        ) {
                          // alert(message);
                          // ErrorToast(message);
                          //e.g. update license and retry geolocating
                        } else if (
                          code ===
                          geoClient.ErrorCodes
                            .CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS
                        ) {
                          ErrorToast(message);
                          //it's safe to be ignored, or we can wait for previous request to complete and try again
                        } else {
                          //something went wrong with GeoComply service, your license or your custom data
                          //e.g. submit log to record the issue
                          ErrorToast(message);
                        }
                      } else if (this.event === "browser.failed") {
                        // alert(message);
                        // ErrorToast(message);
                        //it's safe to be ignored, you can remove this block or add your own logic
                      }
                    });
                  // Request geolocation
                  geoClient.request();

                  // SuccesToast("Successfully login.");

                  navigate("/");
                } else {
                  setIsLoadinig(false);
                  ErrorToast(
                    "Your account has not passed our KYC checks. Please contact customer support."
                  );
                }
              }
            } else if (response.data.status === "kycfailed") {
              localStorage.setItem("session", response.data.data.sessionId);

              localStorage.setItem("user", JSON.stringify(response.data.data));
              sessionStorage.setItem("user", JSON.stringify(response.data));
              dispatch({
                type: Actions.LOGIN,
                data: { user: response.data.data },
              });
              dispatch({
                type: Actions.TEST_CASE,
                data: false,
              });
              setKycmsg(response.data.message);
              setkycmodal(true);
              setIsLoadinig(false);
            } else {
              setIsLoadinig(false);
              ErrorToast(response.data.message);
            }
          })
          .catch((error) => {
            setIsLoadinig(false);
            console.error(error);
          });
      }
    },
    onError: () => {
      console.error("Google login failed");
      // Handle login errors here
      ErrorToast("Google login failed");
    },
    flow: "implicit", // Use 'auth-code' for the authorization code flow
  });

  const rememberMe = () => {
    if (email.length > 0 && password.length > 0) {
      localStorage.setItem("savedUsername", email);
      localStorage.setItem("savedPassword", password);
    } else {
      localStorage.removeItem("savedUsername");
      localStorage.removeItem("savedPassword");
    }
  };

  useEffect(() => {
    const savedUsername = localStorage.getItem("savedUsername");
    const savedPassword = localStorage.getItem("savedPassword");
    if (savedUsername && savedPassword) {
      // Automatically fill the login form
      setEmail(savedUsername);
      setPassword(savedPassword);
    }
  }, []);

  const modalclose = () => {
    dispatch({
      type: Actions.LOGOUT,
    });
    sessionStorage.clear();
    setkycmodal(false);
  };

  const kycContinue = async () => {
    setIsLoadinig(true);
    let url = `${endPoints.apiBaseUrl}${endPoints.api.apiurl}/${state.auth.user.idUser}`;
    try {
      let res = await fetchAllData(url);
      const response = await res.json();
      if (response.status == "success") {
        window.location.href = response.data.RedirectUrl;
      } else {
        setkycmodal(false);
        setIsLoadinig(true);
      }
    } catch (error) {
      ErrorToast(
        "Issue connecting to server (error code 15). Please contact support for further assistance."
      );
      setIsLoadinig(true);
      console.error(error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Login | BettDraft Fantasy Sports</title>

        <meta
          name="description"
          content="Login to BettDraft's Daily Fantasy Sports. Select your sports players and create your top fantasy teams."
        />

        <link rel="canonical" href="https://dfs.bettdraft.com/login" />
      </Helmet>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        show={kycmodal ? true : ""}
        //  style={{paddingTop:"30px"}}
      >
        <Modal.Body className="paddingtop">
          <div className="mt-3" style={{ textAlign: "center" }}>
            <h5
              style={{
                textAlign: "center",
                lineHeight: "1.4",
                color: "#0D3862",
              }}
            >
              {kycmsg}
            </h5>
          </div>
        </Modal.Body>
        <Modal.Footer className="bordernone" style={{ paddingTop: "0px" }}>
          <Col
            lg="12"
            xs="12"
            style={{
              textAlign: "center",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Button
              style={{
                cursor: "pointer",
                backgroundColor: "rgb(23, 150, 36)",
                border: "1px solid rgb(23, 150, 36)",
                width: "100%",
              }}
              onClick={kycContinue}
            >
              <span style={{ fontSize: "17px" }}>Continue</span>
            </Button>
          </Col>
          <Col lg="12" xs="12" style={{ textAlign: "center" }}>
            <span
              style={{
                textAlign: "center",
                fontWeight: "600",
                color: "red",
                cursor: "pointer",
              }}
              onClick={modalclose}
            >
              Close
            </span>
          </Col>
        </Modal.Footer>
      </Modal>

      <section className="login-page-section">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Toaster />
        </div>

        <div className="login-form-div">
          {isLoading && <Loader spinner={true} visible={isLoading} />}
          <Form
            className="login-form col-md-5"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <h2 className="mb-4">Login</h2>
            <Form.Group className="mb-3" controlId="EMAIL">
              <Form.Control
                required
                placeholder="Email/UserName"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid email
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 password-box" controlId="PASSWORD">
              <Form.Control
                required
                placeholder="Password"
                type={passwordShow ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <div
                className="show-icon"
                onClick={() => {
                  setPasswordShow(!passwordShow);
                }}
              >
                {passwordShow ? <AiFillEye /> : <AiFillEyeInvisible />}
              </div>
              <Form.Control.Feedback type="invalid">
                Please enter password.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 flex-div" id="formGridCheckbox">
              <Form.Check
                className="checkbox-content"
                type="checkbox"
                // label="Remember my email."
                label="Remember me."
                onChange={rememberMe}
              />
              <a
                onClick={() => navigate("/forgot-password")}
                className="forget-login-a"
              >
                Forgot your password?
              </a>
            </Form.Group>

            <div className="login-btn-last  mt-5">
              <Button
                className="login-btn-form"
                variant="primary"
                // onClick={HandleSubmit1}
                //onClick={handleSubmit}
                type="submit"
              >
                Login
              </Button>

              <Button
                className="join-btn-form2"
                variant="primary"
                onClick={() => navigate("/join-now")}
              >
                Join Now
              </Button>
            </div>
            <div
              className="  mt-5"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="light" onClick={() => googleLogin()}>
                <FcGoogle size={24} /> Sign in with Google
              </Button>
            </div>

            <div>
              <p className="login-p">
                BettDraft encourages you to gamble responsibly. For problem
                gambling information and assistance, call the 24-hour
                confidential Problem, Gamblers helpline at 1-800-522-4700 or
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "5px",
                    overflow: "visible",
                  }}
                >
                  visit :
                  <a
                    rel="noopener noreferrer nofollow"
                    href="https://www.nevadacouncil.org"
                    target="_blank"
                    style={{ marginLeft: "7px" }}
                  >
                    www.whenthefunstops.org
                  </a>
                </p>
              </p>
            </div>
          </Form>
        </div>
      </section>
    </div>
  );
};

export default Login;
