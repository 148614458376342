import React ,{useEffect} from "react";
import TermsAndconditions from "../../Rules/Termsconditions";
import { PageVisit } from "../../../Utility/API";
import { useSelector } from "react-redux";


const Termsconditions = () => {

  const state = useSelector((state) => state);

  useEffect(()=>{
     PageVisit(state?.auth?.user?.idUser || "","terms-conditions");
   },[]);

  return <TermsAndconditions />;
};

export default Termsconditions;
