import React from "react";
import "./style.css";
import verified from "../../assests/new/tick-image.png";
import { useState } from "react";
import { endPoints } from "../../constant/Environment";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllData } from "../../Utility/API";
import { Actions } from "../../redux/Actions/Actions";
import Loader from "../../components/Loader";

const Verified = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [iSclose, setiSclose] = useState(true);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  let userid = state?.auth?.user?.idUser;

  const Checkemailpopup = async () => {
    setIsLoading(true);
    let url = `${endPoints.apiBaseUrl}${endPoints.api.EMAIL_POPUP}/${userid}`;
    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        if (response.status == "success") {
          setIsLoading(false);
          dispatch({
            type: Actions.LOGIN,
            data: { user: response.data },
          });
          dispatch({
            type: Actions.TEST_CASE,
            data: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const closebox = () => {
    setiSclose(false);
    Checkemailpopup();
  };

  return (
    <div>
      {isLoading && <Loader spinner={true} visible={isLoading} />}
      {iSclose ? (
        <div className="verification-box">
          <div className="inner-verified">
            <img src={verified} className="verified-icon" />
            <span className="main-para">
              <span className="big-heading">Email Verified!</span>
              <br />
              <span className="small-heading">
                Your email has been verified and you now have full access to
                your account.
              </span>
            </span>
            <div className="end-box-verified" onClick={closebox}>
              <i class="fa fa-times icon-danger" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Verified;
