import React, { useEffect, useState } from "react";
import {
  IoMdCheckmarkCircle,
  IoIosCloseCircle,
  IoMdArrowDropupCircle,
} from "react-icons/io";
import { formatAmount } from "../../../Utility/functions/Helper";
import moment from "moment-timezone";
import { MdOutlineMotionPhotosOff } from "react-icons/md";
import void2 from "../../../image/Void.png";
import dnp from "../../../image/dnp.png";
import { isMobile } from "react-device-detect";
import { FaBan } from "react-icons/fa";

const PendingBets = ({ pending, isPending }) => {
  const [value, setValue] = useState(50); // Initial value set to 50

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  const min = 0;
  const max = 100;

  const percentage = ((value - min) / (max - min)) * 100;
  const backgroundStyle = {
    background: `linear-gradient(to right, #179624 ${percentage}%, #ED1A3B ${percentage}%)`,
  };

  const backgroundStyleOpen = {
    background: `linear-gradient(to right, #ED1A3B ${percentage}%, #179624 ${percentage}%)`,
  };

  const PendingData = pending;

  return (
    <>
      {isPending && (
        <div className="mainbox-pending margint_main">
          <div className="row g-4">
            {PendingData?.map((item) => {
              const playerData = item?.playerData;

              const eventIds = playerData?.map(
                (data) => data?.playerevent?.eventId
              );

              const uniqueEventIds = new Set(eventIds);

              let playerlength = item?.playerData?.length;

              let money = item?.wager;

              return (
                <div className="col-md-12">
                  <div className="mainbox_inner">
                    <div className="first_main">
                      <div className="main_left">
                        <h3 className="head_p">Entry:</h3>
                        <p>{item?.id}</p>
                      </div>
                      <div className="main_right">
                        <h4 style={{ overflow: "none!important" }}>
                          Entry Fee: <span> ${formatAmount(item?.wager)}</span>
                        </h4>
                      </div>
                    </div>
                    <div>
                      {playerlength === 2 ? (
                        <div className="second_main">
                          <p>
                            2 Correct Pays:{" "}
                            <span>${Math.trunc(money * 3 * 100) / 100}</span>
                          </p>
                        </div>
                      ) : playerlength === 3 ? (
                        <div className="second_main">
                          <p>
                            3 Correct Pays:{" "}
                            <span>
                              $
                              {uniqueEventIds.size === 1
                                ? Math.trunc(money * 2.25 * 100) / 100
                                : Math.trunc(money * 4 * 100) / 100}
                            </span>
                          </p>
                        </div>
                      ) : playerlength === 4 ? (
                        <div className="second_main">
                          <p>
                            4 Correct Pays:{" "}
                            <span>
                              $
                              {uniqueEventIds.size === 1
                                ? Math.trunc(money * 4 * 100) / 100
                                : Math.trunc(money * 5 * 100) / 100}
                            </span>
                          </p>
                          <p>
                            3 Correct Pays:{" "}
                            <span>${Math.trunc(money * 1 * 100) / 100}</span>
                          </p>
                        </div>
                      ) : playerlength === 5 ? (
                        <div className="second_main">
                          <p>
                            5 Correct Pays:{" "}
                            <span>
                              $
                              {uniqueEventIds.size === 1
                                ? Math.trunc(money * 7 * 100) / 100
                                : Math.trunc(money * 10 * 100) / 100}
                            </span>
                          </p>
                          <p>
                            4 Correct Pays:{" "}
                            <span>
                              $
                              {uniqueEventIds.size === 1
                                ? Math.trunc(money * 1 * 100) / 100
                                : Math.trunc(money * 1.25 * 100) / 100}
                            </span>
                          </p>
                        </div>
                      ) : playerlength === 6 ? (
                        <div className="second_main">
                          <p>
                            6 Correct Pays:{" "}
                            <span>
                              $
                              {uniqueEventIds.size === 1
                                ? Math.trunc(money * 12 * 100) / 100
                                : Math.trunc(money * 25 * 100) / 100}
                            </span>
                          </p>
                          <p>
                            5 Correct Pays:{" "}
                            <span>
                              $
                              {uniqueEventIds.size === 1
                                ? Math.trunc(money * 1.5 * 100) / 100
                                : Math.trunc(money * 2 * 100) / 100}
                            </span>
                          </p>
                          {uniqueEventIds.size !== 1 && (
                            <p>
                              4 Correct Pays:{" "}
                              <span>
                                ${Math.trunc(money * 0.5 * 100) / 100}
                              </span>
                            </p>
                          )}
                        </div>
                      ) : playerlength === 7 ? (
                        <div className="second_main">
                          <p>
                            7 Correct Pays:{" "}
                            <span>${Math.trunc(money * 40 * 100) / 100}</span>
                          </p>
                          <p>
                            6 Correct Pays:{" "}
                            <span>${Math.trunc(money * 4 * 100) / 100}</span>
                          </p>
                          <p>
                            5 Correct Pays:{" "}
                            <span>${Math.trunc(money * 0.75 * 100) / 100}</span>
                          </p>
                        </div>
                      ) : playerlength === 8 ? (
                        <div className="second_main">
                          <p>
                            8 Correct Pays:{" "}
                            <span>${Math.trunc(money * 60 * 100) / 100}</span>
                          </p>
                          <p>
                            7 Correct Pays:{" "}
                            <span>${Math.trunc(money * 5 * 100) / 100}</span>
                          </p>
                          <p>
                            6 Correct Pays:{" "}
                            <span>${Math.trunc(money * 1 * 100) / 100}</span>
                          </p>
                        </div>
                      ) : playerlength === 9 ? (
                        <div className="second_main">
                          <p>
                            9 Correct Pays:{" "}
                            <span>${Math.trunc(money * 80 * 100) / 100}</span>
                          </p>
                          <p>
                            8 Correct Pays:{" "}
                            <span>${Math.trunc(money * 6 * 100) / 100}</span>
                          </p>
                          <p>
                            7 Correct Pays:{" "}
                            <span>${Math.trunc(money * 1 * 100) / 100}</span>
                          </p>
                        </div>
                      ) : playerlength === 10 ? (
                        <div className="second_main">
                          <p>
                            10 Correct Pays:{" "}
                            <span>${Math.trunc(money * 125 * 100) / 100}</span>
                          </p>
                          <p>
                            9 Correct Pays:{" "}
                            <span>${Math.trunc(money * 7 * 100) / 100}</span>
                          </p>
                          <p>
                            8 Correct Pays:{" "}
                            <span>${Math.trunc(money * 1.5 * 100) / 100}</span>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <h3 className="head_p mb-4">Players:</h3>
                    {playerData?.map((player) => {
                      const teamHid = player?.playerevent?.teamH?.id;

                      const teamAid = player?.playerevent?.teamA?.id;

                      const teamHname = player?.playerevent?.teamH?.abv;

                      const teamAname = player?.playerevent?.teamA?.abv;

                      const Game_status = player?.playerevent?.status;

                      const result = player?.defineresult;

                      const finalpoint = player.finalpoints;

                      const rangeWidth =
                        (player.finalpoints / (player.points * 2)) * 100;

                      return (
                        <div
                          className={
                            player?.defined && result == "win"
                              ? "main_item_card green_item"
                              : player?.defined && result == "lose"
                              ? "main_item_card red_item"
                              : "main_item_card"
                          }
                        >
                          <div className="item_card">
                            <div className="item_left">
                              <div className="middle_div">
                                <div className="name_card name_wrap">
                                  <h3
                                    style={{
                                      marginLeft: isMobile ? "0px" : "40px",
                                    }}
                                  >
                                    {player?.name}
                                  </h3>
                                  {player?.game_type == 2 ? (
                                    <p>{player?.single_event}</p>
                                  ) : (
                                    <p>
                                      <span>{player?.position}</span> |{" "}
                                      {player?.team == teamHid
                                        ? teamHname
                                        : teamAname}{" "}
                                      {""}
                                      {player?.team == teamHid
                                        ? "VS"
                                        : "@"}{" "}
                                      {""}
                                      {player?.team == teamHid
                                        ? teamAname
                                        : teamHname}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="item_right">
                              <p>
                                {moment(player?.event_time).format(
                                  "MMM DD, h:mm a"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="item_bottom item_card mt-2">
                            <div className="item_left">
                              <div className="icon-check">
                                {player?.void ? (
                                  <div className="item_icon void_icon">
                                    <FaBan
                                      style={{
                                        marginTop: isMobile ? "-5px" : "-10px",
                                      }}
                                    />
                                  </div>
                                ) : player?.dnp && !player?.result ? (
                                  <div className="item_icon">
                                    <img
                                      alt="bettdraft"
                                      src={dnp}
                                      style={{
                                        height: "35px",
                                        marginTop: "-15px",
                                      }}
                                    />
                                  </div>
                                ) : player?.defined && result == "win" ? (
                                  <div className="item_icon">
                                    <IoMdCheckmarkCircle />
                                  </div>
                                ) : player?.defined && result == "lose" ? (
                                  <div className="item_icon">
                                    <IoIosCloseCircle />
                                  </div>
                                ) : (
                                  <div
                                    className="item_icon"
                                    style={{
                                      visibility: "hidden",
                                      height: "35px",
                                    }}
                                  ></div>
                                )}
                              </div>

                              <div className="middle_div">
                                <div className="name_card">
                                  {player?.defined ? (
                                    <IoMdCheckmarkCircle />
                                  ) : Game_status == 2 ? (
                                    <IoMdCheckmarkCircle />
                                  ) : Game_status == 6 ? (
                                    <IoMdCheckmarkCircle />
                                  ) : Game_status == 7 ? (
                                    <IoMdCheckmarkCircle />
                                  ) : (
                                    <IoIosCloseCircle
                                      style={{ color: "#C02640" }}
                                    />
                                  )}
                                  {/* <IoMdCheckmarkCircle /> */}
                                  <div
                                    className={
                                      player?.defined
                                        ? "w-100 range_div1 game_completed"
                                        : "w-100 range_div1 game_completed"
                                    }
                                  >
                                    <input
                                      type="range"
                                      id="myRange"
                                      min={min}
                                      max={max}
                                      value={value}
                                      // onChange={handleChange}
                                      style={
                                        player?.defined
                                          ? backgroundStyle
                                          : Game_status == 2
                                          ? backgroundStyle
                                          : Game_status == 6
                                          ? backgroundStyle
                                          : Game_status == 7
                                          ? backgroundStyle
                                          : backgroundStyleOpen
                                      }
                                    />
                                    <p>
                                      {player.points} {player.statAbv}
                                    </p>
                                    <div
                                      className="arrow_icon"
                                      style={{
                                        left:
                                          rangeWidth > 100
                                            ? "99%"
                                            : `calc(${rangeWidth}% - 10px)`,
                                        position: "relative",
                                        top: "-33px",
                                      }}
                                    >
                                      <span
                                        className="finalpoint"
                                        style={{
                                          display: "block",
                                          marginTop: isMobile
                                            ? "-15px"
                                            : "-18px",
                                          color: "#000",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {finalpoint}
                                      </span>
                                      <IoMdArrowDropupCircle />
                                    </div>
                                  </div>
                                  {player?.defined ? (
                                    <IoIosCloseCircle />
                                  ) : Game_status == 2 ? (
                                    <IoIosCloseCircle />
                                  ) : Game_status == 6 ? (
                                    <IoIosCloseCircle />
                                  ) : Game_status == 7 ? (
                                    <IoIosCloseCircle />
                                  ) : (
                                    <IoMdCheckmarkCircle
                                      style={{ color: "#179624" }}
                                    />
                                  )}
                                  {/* <IoIosCloseCircle /> */}
                                </div>
                              </div>
                            </div>
                            <div className="item_right">
                              <a className="btn_light">
                                {player?.defined
                                  ? "Completed"
                                  : Game_status == 2
                                  ? "inPlay"
                                  : Game_status == 6
                                  ? "Postponed"
                                  : Game_status == 7
                                  ? "Cancelled"
                                  : "Scheduled"}
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default PendingBets;
