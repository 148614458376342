import React, { useState, Suspense, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";

import Loading from "./screens/Loading";
import Login from "./screens/Login";
import PersonalInformation from "./screens/Profile/PersonalInformation";
import ChangeEmail from "./screens/Profile/ChangeEmail";
import ChangePassword from "./screens/Profile/ChangePassword";

import Signup from "./screens/signup";
import JoinNowStep from "./screens/JoinNowStep";
import Termsconditions from "./screens/Rules/Termsconditions";
import HouseRules from "./screens/Sportsbook/HouseRules";
import SportsTermsconditions from "./screens/Sportsbook/TermConditions";
import SportsResponseGame from "./screens/Sportsbook/ResponseGame";
import Faq from "./screens/Faq";
import Contacts from "./screens/Contacts";
import Validity from "./screens/Rules/Validity";
import Gamingrules from "./screens/Rules/Gamingrules";
import Privacypolicy from "./screens/Rules/Privacypolicy";
import Sportsrules from "./screens/Rules/Sportsrules";
import Responsiblegaming from "./screens/Rules/Responsiblegaming";
import Responsiblegamingsettings from "./screens/Responsible-gaming-settings";

import { useSelector, useDispatch } from "react-redux";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import Kyc from "./screens/Profile/kyc";
import Geocheck from "./screens/Geocheck";
import MyBets from "./screens/Accounting/MyBets";

import LoadingIndicator from "./components/Spinner";

import { sportEndPoint } from "./constant/Environment";
import { fetchAllData } from "./Utility/API";
import { Actions } from "./redux/Actions/Actions";
import Enroll from "./screens/Profile/Enroll";

import Activation from "./screens/Profile/Activation";
import AccountLimit from "./screens/Profile/AccountLimit";
import AboutUs from "./screens/AboutUs";

import AccountVerfication from "./screens/AccountVerification";
import AccountSuccess from "./screens/AccountSuccess";
import AccountCongrats from "./screens/AccountCongrats";
import Info from "./screens/Info";
import Scoring from "./screens/Rules/Scoring";
import Howtoplay from "./screens/Rules/howtoplay";

import UnderMaintenance from "./screens/UnderMaintenance";
import PrivateRoute from "./PrivateRoute";
import NewUserWallet from "./screens/Profile/NewUserWallet";

import PromoCode from "./screens/PromoCode";
import MobileWallet from "./screens/Profile/MobileWallet";
import MobilePromo from "./screens/PromotionList/MobilePromo";

const MainRoutes = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { auth, sidebar } = state;

  useEffect(() => {
    defaultBetConfig();
  }, []);
  function defaultBetConfig() {
    let url = `${sportEndPoint.apiBaseUrl}${sportEndPoint.api.Default_Bet_Config}`;
    fetchAllData(url)
      .then((res) => res.json())
      .then((response) => {
        dispatch({ type: Actions.BET_CONFIG, data: response });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <div>
          <Routes>
            <Route exact path="/Spinner" element={<LoadingIndicator />} />

            <Route
              exact
              path="/join-now"
              element={
                <PrivateRoute>
                  {/* <Joinnow /> */}
                  <Signup />
                </PrivateRoute>
              }
            />
            <Route exact path="/join-now-pre" element={<JoinNowStep />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/login" element={<Login />} />
            <Route
              path="/resetpassword"
              element={
                <PrivateRoute>
                  <ResetPassword />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/promo-code"
              element={
                <PrivateRoute>
                  <PromoCode />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/mobile-promo"
              element={
                <PrivateRoute>
                  <MobilePromo />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/forgot-password"
              element={
                <PrivateRoute>
                  <ForgotPassword />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/how-to-play"
              element={
                <PrivateRoute>
                  <Howtoplay />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/geocheck"
              element={
                <PrivateRoute>
                  <Geocheck />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/scoring"
              element={
                <PrivateRoute>
                  <Scoring />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/responsiblegaming"
              element={
                <PrivateRoute>
                  <SportsResponseGame />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/responsible-gaming-settings"
              element={
                <PrivateRoute>
                  <Responsiblegamingsettings />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/termsconditions"
              element={
                <PrivateRoute>
                  <SportsTermsconditions />
                </PrivateRoute>
              }
            />

            <Route
              path="/houserules"
              element={<Navigate to="/house-rules" replace />}
            />

            <Route
              exact
              path="/house-rules"
              element={
                // <PrivateRoute>
                <HouseRules />
                // {/* </PrivateRoute> */}
              }
            />

            <Route
              path="/enroll"
              element={
                <PrivateRoute>
                  <Enroll />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/Wallet"
              element={
                <PrivateRoute>
                  <Wallet />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/kyc"
              element={
                <PrivateRoute>
                  <Kyc />
                </PrivateRoute>
              }
            />

            <Route
              path="/my-entries"
              element={
                <PrivateRoute>
                  <MyBets />
                </PrivateRoute>
              }
            />
            <Route
              path="/activations/:userName/:token"
              element={<Activation />}
            />
            <Route
              exact
              path="/under-maintenance"
              element={<UnderMaintenance />}
            />

            <Route
              exact
              path="/personal-information"
              element={
                <PrivateRoute>
                  <PersonalInformation />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/Account-Data"
              element={
                <PrivateRoute>
                  <ChangeEmail />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/change-email"
              element={
                <PrivateRoute>
                  <ChangeEmail />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/change-password"
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/userwallet"
              element={
                <PrivateRoute>
                  <NewUserWallet />
                </PrivateRoute>
              }
            />
            <Route exact path="/mobile-wallet" element={<MobileWallet />} />

            <Route
              exact
              path="/terms-conditions"
              element={
                <PrivateRoute>
                  <Termsconditions />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/faq"
              element={
                <PrivateRoute>
                  <Faq />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/contact-us"
              element={
                <PrivateRoute>
                  <Contacts />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/validity-and-acceptance"
              element={
                <PrivateRoute>
                  <Validity />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/gaming-rules"
              element={
                <PrivateRoute>
                  <Gamingrules />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/privacy-policy"
              element={
                <PrivateRoute>
                  <Privacypolicy />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/sports-rules"
              element={
                <PrivateRoute>
                  <Sportsrules />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/responsible-gaming"
              element={
                <PrivateRoute>
                  <Responsiblegaming />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/accountLimit"
              element={
                <PrivateRoute>
                  <AccountLimit />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/accountverified"
              element={<AccountVerfication />}
            />
            <Route exact path="/info" element={<Info />} />
            <Route exact path="/accountSuccess" element={<AccountSuccess />} />
            <Route
              exact
              path="/accountCongrats"
              element={<AccountCongrats />}
            />
            <Route
              exact
              path="/about-us"
              element={
                <PrivateRoute>
                  <AboutUs />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </Suspense>
    </div>
  );
};

export default MainRoutes;
