import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/Store/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        {/* <div style={{
      marginLeft:'250px'
    }}> */}

        <GoogleOAuthProvider clientId="873652672045-vmi4hk4r35klcths3idmm5r2nq1idis0.apps.googleusercontent.com">
          {/* 873652672045-t35m00tbh06190b4htf9ukp9842jt45q.apps.googleusercontent.com */}

          <App />
        </GoogleOAuthProvider>
        {/* </div> */}
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
