import React, { useEffect } from "react";
import "./style.css";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PaymentEndpoints, endPoints } from "../../../constant/Environment";
import { getAllData } from "../../../Utility/API";
import Loader from "../../../components/Loader";
import Toaster from "../../../components/Toast";
import { SuccesToast, ErrorToast } from "../../../components/Toast/message";
import { useNavigate } from "react-router-dom";
import { addData, addBetData } from "../../../Utility/API";
import { useDispatch } from "react-redux";
import { Actions } from "../../../redux/Actions/Actions";
import axios from "axios";
import { Link } from "react-router-dom";
import { SkrillEnv } from "../../../constant/SkrillEnv";
const MobileWallet = () => {
  const state = useSelector((state) => state);
  const [amt, setAmt] = useState("");
  const [isLoading, setIsLoadinig] = useState(false);
  const [backButton, setBackButton] = useState(false);
  let params = new URLSearchParams(window.location.search);
  let mobileUserId = params.get("mobileUserId") || "";
  let mobileSessionId = params.get("sessionId") || "";
  let fromMobile = params.get("fromMobile") || "";
  let mobAmount = params.get("amount") || "";

  //new-wallet?userId=112&sessionId=444
  const RandomRefNum = (prefix) => {
    const randomNum = Math.floor(Math.random() * 900000) + 100000;
    return `${prefix}_${randomNum}`;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    GetUserById();
  }, [fromMobile]);
  const GetUserById = async () => {
    let url = `${endPoints.apiBaseUrl}${endPoints.api.GET_BY_USERID}/${mobileUserId}`;
    setIsLoadinig(true);
    const response = await axios.get(url, {
      headers: {
        SessonId: mobileSessionId,
      },
    });
    setIsLoadinig(false);
    /// window.close();

    if (response?.data?.status == "success") {
      dispatch({
        type: Actions.LOGIN,
        data: { user: response?.data?.data },
      });
      dispatch({
        type: Actions.TEST_CASE,
        data: false,
      });
      const merchantRefNum = RandomRefNum(
        `bettdraft_${response?.data?.data?.idUser}`
      );
      if (!backButton) {
        SkrillAdd(mobAmount, merchantRefNum, response?.data?.data);
      }
    }
  };

  const navigateBackToMobile = () => {
    const deepLink = "myapp://Wallet";
    // Check if the app is running on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // If the app is running on a mobile device, redirect to the deep link URL
    if (isMobile) {
      window.location.replace(deepLink);
    } else {
      // Handle case where the app is not running on a mobile device
      console.log("This feature is only available on mobile devices.");
    }
  };

  const GetUserById1 = () => {
    if (state?.auth?.user?.idUser) {
      let url = `${endPoints.api.GET_BY_USERID}/${state?.auth?.user?.idUser}`;
      getAllData(url).then((response) => {
        dispatch({
          type: Actions.LOGIN,
          data: { user: response.data },
        });
      });
    }
  };

  const SkrillAdd = async (depositamount, referenceNo, userInfo) => {
    let url = `${endPoints.apiBaseUrl}${endPoints.api.SKRILL_PAYMENT}`;
    setIsLoadinig(true);
    let data = {
      id_skrill_wallet_transaction: 0,
      merchantRefNum: referenceNo,
      id_user: userInfo?.idUser,
      amount: Number((depositamount * 100).toFixed(2)),
      currencyCode: "USD",
      id_payment: 0,
      paymentHandleToken: "string",
      txnTime: "string",
      status: "string",
      transaction_id: "string",
      pay_from_email: "string",
      pay_to_email: "string",
      merchant_id: "string",
      gateway_id: "string",
      sid: "string",
    };
    try {
      setIsLoadinig(true);
      // let response = await addData(url, data);
      const response = await axios.post(url, data, {
        headers: {
          SessonId: mobileSessionId,
        },
      });
      if (response.data.status == "success") {
        window.paysafe.checkout.setup(
          API_KEY_MERCHANT_TEST,
          {
            currency: "USD", // 4 currencies currently supported by Paysafe Checkout - "USD", "CAD", "EUR", "GBP"
            amount: Number((depositamount * 100).toFixed(2)), // This qualifies as USD $ 100.00. Multiply Payment Amount by 100 and supply here.
            payout: false, // payout: true --> for Withdrawal (standalone credits); payout: false --> for Payments/Deposit
            payoutConfig: {
              // payoutConfig section required only when payout: true [for Withdrawal cases].
              maximumAmount: 100000000,
            },
            locale: "en_US",
            simulator: "EXTERNAL",
            imageUrl:
              "https://hosted.paysafe.com/checkout/resource/demo-store/images/logo.png", // Supply Your Logo URL here.
            environment: API_ENV, // environment: "LIVE" --> Production, "TEST" --> test environment for customers to try out Checkout & its features
            buttonColor: "#66cc99", // feel free to change the color of buttons (RGB value)
            companyName: "Bettdraft", // Supply Your Company Name here
            holderName: "John Smith", // Supply Customer's Name here
            customer: {
              firstName: userInfo?.name,
              lastName: userInfo?.surname,
              email: userInfo?.email,
              phone: userInfo?.mobile,
              dateofBirth: userInfo?.birthDate,
            },
            billingAddress: {
              // Supply customer's billing Address here.
              nickName: userInfo?.name,
              street: userInfo?.address,
              city: userInfo?.town,
              zip: userInfo?.zipCode,
              country: userInfo?.country,
              state: userInfo?.stateCode,
            },
            merchantRefNum: referenceNo, // Will be unique and must keep changing every transaction
            canEditAmount: true, // Makes the payment amount editable on Checkout screen. Make it false to open Checkout with a fixed non-editable amount.
            merchantDescriptor: {
              dynamicDescriptor: "XYZ",
              phone: "1234567890",
            },
            displayPaymentMethods: [
              "neteller",
              "skrill",
              "paysafecard",
              "paysafecash",
              "instantach",
              "paypal",
              "card",
              "vippreferred",
              "sightline",
              "ach",
              "eft",
            ],
            // displayPaymentMethods : Array serves two purposes. You can use it to restrict the payment methods that a customer can see.
            // You can also use it to order the payment methods based on your preference.
            // If this field is present, the customer will only see those payment methods in the order specified, hence,
            // Ensure that you provide all payment methods if you are using it only for the purpose of payment method ordering.
            paymentMethodDetails: {
              // Please read the Checkout Objects Documentation on developer.paysafe.com .....
              paysafecard: {
                // .... for details on paymentMethodDetails (including all supported mandatory and optional fields)
                consumerId: "123456",
              },
              paysafecash: {
                consumerId: "123456",
              },
              sightline: {
                consumerId: "12341231256",
                SSN: "123456789",
                last4ssn: "6789",
                //accountId: "1009688222"  // Supply Account ID only if multiple accounts are configured with same payment method
              },
              vippreferred: {
                consumerId: "120288765",
                //accountId: "1679688456"  // Supply Account ID only if multiple accounts are configured with same payment method
              },
              card: {
                //accountId: "1009688230"  // Supply Account ID only if multiple accounts are configured with same payment method
              },
              skrill: {
                consumerId: userInfo.email,
                emailSubject: "Payout for Greg Neteller",
                emailMessage: "You Have Received Payout of $100.",
              },
              instantach: {
                consumerId: "john.doe@email.com",
                paymentId: "3aeb9c63-6386-46a3-9f8e-f452e722228a",
                emailSubject: "Instant ACH Payout",
                emailMessage:
                  "Your Instant ACH Payout request has been processed",
              },
              neteller: {
                consumerId: "netellertest_EUR@neteller.com",
                recipientDescription: "logo_url_alt_text",
                logoUrl: "http://www.paysafe.com/icon.jpg",
              },
            },
          },
          function (instance, error, result) {
            if (result && result.paymentHandleToken) {
              setIsLoadinig(false);
              SkrillPay(
                result.amount,
                referenceNo,
                result.paymentHandleToken,
                userInfo
              );
              instance.close();
            } else if (result.paymentHandleToken == null) {
              SkrillFailed(depositamount, referenceNo, userInfo);
              setIsLoadinig(false);
              instance.close();
            } else {
              // alert(error);
              ErrorToast(error?.detailedMessage);
              setIsLoadinig(false);
            }
          },
          function (stage, expired) {
            setIsLoadinig(false);
            switch (stage) {
              case "PAYMENT_HANDLE_NOT_CREATED": // Handle the scenario
              case "PAYMENT_HANDLE_CREATED": // Handle the scenario
              case "PAYMENT_HANDLE_REDIRECT": // Handle the scenario
              case "PAYMENT_HANDLE_PAYABLE": // Handle the scenario
              default: // Handle the scenario
            }
          },
          function (instance, amount, paymentMethod) {
            // use parameters amount and payment method to do riskCheck
            // Applicable to only Cards, PaySafe Cash, VIPP and Sightline
            if (amount >= 100) {
              instance.decline("Please use amount less than 100");
              setIsLoadinig(false);
              // ErrorToast("Please use amount less than 100");
            } else {
              instance.accept();
              setIsLoadinig(false);
            }
          }
        );
      } else {
        ErrorToast(response.message);
        setIsLoadinig(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoadinig(false);
      ErrorToast(
        "Issue connecting to server (error code 15). Please contact support for further assistance."
      );
    }
  };

  const SkrillPay = async (depositamount, referenceNo, tokenId, userInfo) => {
    let url = `${PaymentEndpoints.apiBaseUrl}${PaymentEndpoints.api.SKRILL_WALLET}`;

    setIsLoadinig(true);
    let data = {
      merchantRefNum: referenceNo,
      amount: depositamount,
      accountid: "1002727400",
      paymentHandleToken: tokenId,
      currencyCode: "USD",
      id_user: userInfo?.idUser,
      settleWithAuth: true,
    };
    try {
      setIsLoadinig(true);
      //  let response = await addBetData(url, data);
      const response = await axios.post(url, data, {
        headers: {
          // SessonId: mobileSessionId,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.data.status == "success") {
        SuccesToast(response.data.message);
        setBackButton(true);
        GetUserById();
        // navigateBackToMobile();
      } else {
        ErrorToast(response.data.message);
      }
    } catch (err) {
      console.log(err);
      ErrorToast(
        "Issue connecting to server (error code 15). Please contact support for further assistance."
      );
      setIsLoadinig(false);
    } finally {
      setAmt("");
      setIsLoadinig(false);
    }
  };

  const SkrillFailed = async (depositamount, referenceNo, userInfo) => {
    let url = `${PaymentEndpoints.apiBaseUrl}${PaymentEndpoints.api.FAILED_DEPOSIT}`;
    setIsLoadinig(true);
    let data = {
      merchantRefNum: referenceNo,
      amount: depositamount,
      id_user: userInfo?.idUser,
      accountid: "string",
      paymentHandleToken: "string",
      currencyCode: "string",
      settleWithAuth: "string",
      status: "string",
      description: "string",
      tstatus: 0,
    };
    try {
      setIsLoadinig(true);
      // let response = await addBetData(url, data);
      const response = await axios.post(url, data, {
        headers: {
          // SessonId: mobileSessionId,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.data.status == "failure") {
        ErrorToast(response.data.message);
      } else {
        ErrorToast(response.data.message);
      }
    } catch (err) {
      console.log(err);
      ErrorToast(
        "Issue connecting to server (error code 15). Please contact support for further assistance."
      );
      setIsLoadinig(false);
    } finally {
      setAmt("");
      setIsLoadinig(false);
    }
  };

  let API_KEY_MERCHANT_TEST = `${SkrillEnv.keys}`;

  let API_ENV = `${SkrillEnv.Env}`;

  const openMobileApp = () => {
    // Replace 'myapp://' with your custom URL scheme
    window.location.href = "myapp://Wallet";
  };
  return (
    <>
      <Toaster></Toaster>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {isLoading && <Loader spinner={true} visible={isLoading} />}
        {backButton ? (
          <>
            <Button
              variant="primary"
              style={{ marginTop: "200px", backgroundColor: "#0D3862" }}
              onClick={() => {
                openMobileApp();
              }}
            >
              Back to BettDraft Application
            </Button>
          </>
        ) : (
          <span
            style={{ marginTop: "200px", fontSize: "20px", fontWeight: "bold" }}
          >
            We are processing your request ....
          </span>
        )}
      </div>
    </>
  );
};

export default MobileWallet;
