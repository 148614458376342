import React, { useState } from "react";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { endPoints } from "../../constant/Environment";
import { addData } from "../../Utility/API";
import { SuccesToast, ErrorToast } from "../../components/Toast/message";

const MailVerification = () => {
  const state = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);

  const resendMail = async () => {
    let data = {
      idUser: state.auth.user.idUser,
      login: state.auth.user.login,
    };

    setIsLoading(true);
    let url = `${endPoints.api.resendMail}`;
    await addData(url, data)
      .then((response) => {
        // debugger
        setIsLoading(false);
        if (response.data.status == "success") {
          SuccesToast(response.data.message);
        } else {
          ErrorToast(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="verification-box2">
        <div className="inner-maildiv">
          <i
            class="fa fa-2x fa-exclamation-triangle icon-mail"
            aria-hidden="true"
          ></i>
          <span className="main-para">
            <span className="big-heading">Email not Verified!</span>
            <br />
            <span className="small-heading">
              You need to confirm your email address before you can start using
              your BettDraft Account.
            </span>
            <div className="end-box">
              <span
                className="resend-para"
                style={{ cursor: "pointer" }}
                onClick={() => resendMail()}
              >
                <a>{"Resend Link >>>"}</a>
              </span>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default MailVerification;
