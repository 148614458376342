import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { PageVisit } from "../../../Utility/API";


const Howtoplay = () => {

   const state = useSelector((state) => state);

   useEffect(()=>{
      PageVisit(state?.auth?.user?.idUser || "","how-to-play");
    },[]);

   
  return (
    <div>
      <Helmet>
       <title>How to Play Prop Fantasy Sports | BettDraft Fantasy Sports</title>

        <meta
          name="description"
          content="Mix & match your favorite leagues at BettDraft: NFL, MLB, NBA, NHL, MLS and more. Strategize if your players will achieve more or less than the projected stats."
        />

        <link
          rel="canonical"
          href="https://dfs.bettdraft.com/how-to-play"
        />
        </Helmet>
      <div className="container bg-color-pp space-bottom">
        <div className="inner-smallspace-pp">
          <div className="row">
            <div className="col-md-12">
              <div className="content-page-design">
                <h2>HOW TO PLAY</h2>

                 <p>
                    <b>1. Select your players.</b>
                 </p>
                 <p>
                 A selection of players is listed for games that take place up to five days in advance. Not all players are listed for each game. For each player, you may find several player cards, each with a specific stat projection. You can navigate the available games in the top menu of each sport’s section.
                 </p>
                 <p>
                 Select a minimum of two players and add them to your Roster. Each player card has a specific stat projection listed, such as Passing Yards or Rebounds. The projection stat selected will be added to your ticket’s Roster.
                 </p>
                 <p>
                 You can add multiple stats for the same player; however, your Roster cannot consist of all players from the same team. 
                 </p>

                 <p>
                    <b>2. Predict if your players will achieve More or Less than the projected stat.</b>
                 </p>

                 <p>
                 Predict if each of your players in your Roster will achieve MORE or LESS than their projected stat.
                 </p>

                 <p>
                    <b>3. Select your entry fee and submit an entry.</b>
                 </p>

                 <p>
                 Once you have selected your Roster and decided if each will score more or less, you can submit your ticket.
                 </p>

                 <p>
                 You can enter for as little as $1. When you add your entry fee, you will see the possible payouts listed for your contest entry. You will see a payout table at the bottom of your ticket, which will indicate how many you need to get correct to qualify for a payout.
                 </p>

                 <p>
                 You don’t need to get all your selections correct to be eligible for a payout from the prize pool. If you have selected more than 3 players, you can get up to 2 selections incorrect and still get a payout!
                 </p>

                 {/* <p>
                 You now have control over the amount available to spend on contests and you can move funds to and from your wallet at any time.
                 </p>

                 <p>
                 To Withdraw funds, you simply select to transfer funds from your BettDraft Balance to your Wallet. Then from the Wallet tab, simply select to withdraw to your bank. Withdrawals are fast and free. (Some methods do incur a charge such as a Check)
                 </p> */}

                 {/* <h4>PLAYER SELECTION</h4>

                 <p>
                 A selection of players are listed for games that take place up to 5 days in advance. Not all players are listed for each event. For each player you will find several player cards, each with a specific stat projection associated. You can navigate the stats in the top menu of each sport section.
                 </p>

                 <p>
                 When selecting a player, ensure you are adding the correct stat card to your ticket. You can add multiple stats for the same player; however, your Team Roster cannot be made of all players from the same team. You will need at least two teams to appear on your ticket roster. 
                 </p>

                 <p>
                 Once you have added your team players and stats, you can select, based on your knowledge and expertise, if each player will achieve More than the projected stat or less.
                 </p>

                 <h4>TICKET SUBMISSION</h4>

                 <p>
                 Once you have selected your team and decided if they will score more or less, you can submit your ticket. 
                 </p>

                 <p>
                 You will see a payout table at the bottom of your ticket, which will indicate how many entries you need to get correct to qualify for a payout.
                 </p> */}

                 <p>
                 Payouts:
                 </p>

                 <p>
                    <b>2 Player Roster</b><br/>
                    <li>2 players correct pays your Entry Fee X 3</li>
                 </p>

                 <p>
                    <b>3 Player Roster</b><br/>
                    <li>3 players correct pays your Entry Fee X 4</li>
                    {/* <li>2 players correct pays your Entry Fee X 1.25</li> */}
                 </p>

                 <p>
                    <b>4 Player Roster</b><br/>
                    <li>4 players correct pays your Entry Fee X 5</li>
                    <li>3 players correct pays your Entry Fee X 1</li>
                    {/* <li>2 players correct pays your Entry Fee X 1</li> */}
                 </p>

                 <p>
                    <b>5 Player Roster</b><br/>
                    <li>5 players correct pays your Entry Fee X 10</li>
                    <li>4 players correct pays your Entry Fee X 1.25</li>
                    {/* <li>3 players correct pays your Entry Fee X 1.25</li> */}
                 </p>

                 <p>
                    <b>6 Player Roster</b><br/>
                    <li>6 players correct pays your Entry Fee X 25</li>
                    <li>5 players correct pays your Entry Fee X 2</li>
                    <li>4 players correct pays your Entry Fee X 0.5</li>
                 </p>

                 <p>
                    <b>7 Player Roster</b><br/>
                    <li>7 players correct pays your Entry Fee X 40</li>
                    <li>6 players correct pays your Entry Fee X 4</li>
                    <li>5 players correct pays your Entry Fee X 0.75</li>
                 </p>

                 <p>
                    <b>8 Player Roster</b><br/>
                    <li>8 players correct pays your Entry Fee X 60</li>
                    <li>7 players correct pays your Entry Fee X 5</li>
                    <li>6 players correct pays your Entry Fee X 1</li>
                 </p>

                 <p>
                    <b>9 Player Roster</b><br/>
                    <li>9 players correct pays your Entry Fee X 80</li>
                    <li>8 players correct pays your Entry Fee X 6</li>
                    <li>7 players correct pays your Entry Fee X 1</li>
                 </p>

                 <p>
                    <b>10 Player Roster</b><br/>
                    <li>10 players correct pays your Entry Fee X 125</li>
                    <li>9 players correct pays your Entry Fee X 7</li>
                    <li>8 players correct pays your Entry Fee X 1.5</li>
                 </p>

                 <p>
                 All entries are final and once submitted cannot be refunded.
                 </p>

                 <p>
                 <b>ADDING AND WITHDRAWING FUNDS</b>
                 </p>

                 <p>
                 Your Wallet and BettDraft Balance are 2 separate accounts. This allows you to manage your spend more efficiently.
                 </p>

                 <p>
                 To add funds to your BettDraft Balance so you can enter contests, first you need to fund your wallet from your Credit or Debit card. Select the Wallet Tab and add funds to your wallet. Once your Wallet is funded, you can then select your BettDraft Balance Tab and select the amount you would like to transfer to your BettDraft Balance.
                 </p>

                 <p>
                 You now have control over the amount available to spend on contests, and you can move funds to and from your wallet at any time.
                 </p>

                 <p>
                 To Withdraw funds, you simply select to transfer funds from your BettDraft Balance to your Wallet. Then from the Wallet tab, simply select to withdraw to your bank. Withdrawals are fast and most are free.
                 </p>

                 <p>
                 <b>
                 DNP, CANCELLATION AND PUSHES
                 </b>
                 </p>

                 <p>
                 If you have selected a player on your roster who Did Not Play (DNP) in the game, participated in a game that was canceled, or the actual final stat achieved was exactly the projected stat (a Push), this will inactivate the player on the Roster and revert the contest entry to an entry with one less player than the original. The entry will remain active until the final player’s game has concluded and the entry will remain eligible for a prize payout.
                 </p>

                 <p>
                 For Rosters of 3 or more players, your entry will still be eligible for a payout based on one player being incorrect.
                 </p>

                 <p>
                 For example, if you have a player Pushes on an 8-player selection, your entry will become a 7-player selection and the max payout you can receive will be that of a 7-player selection entry. All other players are not affected and the remaining players on the entry are valid.
                 </p>

                 <p>
                 If you have selected a 2-player roster, we will refund your entry if one player Pushes, the event is Canceled, or the player Did Not Play.
                 </p>

                 <p>
                 If 2 players are incorrect (DNP, Cancelled or Push) on a 3-player roster, or 3 players incorrect (DNP, Cancelled or Push) on a 4-player or more roster, your entry will be refunded.
                 </p>

                 <p>
                 Refunds will be processed within 48 hours of the end of the final event on the ticket.
                 </p>


                 <p>See <a href="/scoring">Scoring</a> section for details on how each sport and player stat is calculated.</p>
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Howtoplay;
