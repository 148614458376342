import React, { useState } from "react";
// ICONS
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
// ROUTING
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";

import "./Navbar.css";
import logo from "../../assests/images/bdlogo.png";
import Button from "react-bootstrap/Button";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../redux/Actions/Actions";
import { Row, Col } from "react-bootstrap";
import logout from "../../assests/new/Exit.png";
import user from "../../assests/new/My_Account.png";

import Dropdown from "react-bootstrap/Dropdown";
import { ErrorToast } from "../Toast/message";
export default function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    if (User?.validation === "0") {
      ErrorToast("Please update your profile");
    } else {
      setSidebar(!sidebar);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const state = useSelector((state) => state);
  const { auth, cart } = state;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const User = auth.user;

  function goToCart() {
    dispatch({
      type: Actions.TEST_CASE,
      data: false,
    });
    navigate("/cart");
  }

  function Logout() {
    dispatch({
      type: Actions.LOGOUT,
    });
    dispatch({
      type: Actions.REMOVE_ALL_BETSLIP,
    });
    dispatch({
      type: Actions.PARLAY_BETS,
      data: false,
    });
    sessionStorage.clear();
    navigate("/login");
  }
  function navigateToJoinNow() {
    dispatch({
      type: Actions.LOGOUT,
    });
    dispatch({
      type: Actions.SIDEBAR_EXPAND,
      data: false,
    });
    localStorage.clear();
    // navigate("/join-now");
    navigate("/join-now");
  }
  function ClearGame() {
    dispatch({
      type: Actions.CLEAR_GAME,
    });
    dispatch({
      type: Actions.SHOW_GAMES_MENU,
    });
    navigate("/");
  }

  const clearData = () => {
    dispatch({
      type: Actions.TEST_CASE,
      data: false,
    });
  };

  const wallet_access = state?.auth?.geoWallet;

  const pathname = window.location.pathname;

  let verification = state?.auth?.user?.verification;

  return (
    <>
      <IconContext.Provider value={{ color: "#FFF" }}>
        {/* All the icons now are white */}

        <div
          className="navbar"
          style={{ height: pathname === "/" ? "300px!important" : "" }}
        >
          <Row style={{ width: "100%" }}>
            <div className="mainhead">
              <img
                alt="Bettdraft"
                src={logo}
                className="logo"
                style={{
                  width: "auto",
                  height: "44px",
                  cursor: "pointer",
                  marginLeft: "15px",
                }}
                onClick={ClearGame}
              />

              {auth.isAuthenticated ? (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <div className="big-nav-div">
                    <span
                      className="user-price hide-ipad"
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (verification == "0") {
                          ErrorToast(
                            "Please verify your email before you can add funds to your account."
                          );
                          navigate("/");
                        } else if (!wallet_access) {
                        } else {
                          dispatch({
                            type: Actions.TEST_CASE,
                            data: false,
                          });
                          navigate("/userwallet");
                        }
                      }}
                    >
                      {"$"} {User?.account?.totalBalance}
                    </span>

                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="user_dropdown_menu m-hide"
                      >
                        <span className="user-profile">
                          <img
                            alt="bettdraft"
                            src={user}
                            className="fa fa-2x fa-user-plus"
                            id="dropdown-basic"
                            style={{
                              color: "#0D3862",
                              marginRight: "18%",
                              cursor: "pointer",
                              height: "30px",
                            }}
                          />

                          <p
                            className="mt-1"
                            style={{
                              color: "#0D3862",
                              fontWeight: "600",
                              marginTop: "15px!important",
                            }}
                          >
                            {User?.name}
                          </p>
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="open_dropdown_p">
                        <section className="profile-dropdown">
                          <div className="user_profile_details">
                            <div
                              className="user_profile_one"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (verification === "0") {
                                  ErrorToast(
                                    "You need to confirm your email address before you can start using your BettDraft Account."
                                  );
                                  navigate("/");
                                } else {
                                  dispatch({
                                    type: Actions.TEST_CASE,
                                    data: false,
                                  });
                                  navigate("/personal-information");
                                }
                              }}
                            >
                              <h2
                                style={{
                                  color: "#0D3862",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                {User?.name}
                              </h2>
                            </div>
                            <hr className="profile_hr" />
                          </div>

                          <div>
                            {SidebarData.map((item, index) => {
                              return (
                                <div className="navbaritem">
                                  <li
                                    key={index}
                                    className={item.cName}
                                    onClick={clearData}
                                  >
                                    <Link
                                      to={
                                        item.path == "/userwallet"
                                          ? !wallet_access ||
                                            verification == "0"
                                            ? ""
                                            : "/userwallet"
                                          : item.path
                                      }
                                    >
                                      <span className="title-text">
                                        {item.title}
                                      </span>
                                    </Link>
                                  </li>
                                </div>
                              );
                            })}
                          </div>
                          {/* } */}
                        </section>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="exit">
                    <img
                      alt="bettdraft"
                      src={logout}
                      style={{
                        height: "35px",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={Logout}
                    />
                  </div>
                </div>
              ) : (
                !auth.isModalLogin && (
                  <div style={{ display: "flex", marginRight: "5px" }}>
                    <Button
                      className="join-btn-text loginbtn"
                      onClick={Logout}
                      style={{ marginRight: "1rem", background: "#0D3862" }}
                    >
                      Login
                    </Button>
                    {window.location.pathname == "/join-now" ? (
                      ""
                    ) : (
                      <Button
                        className="join-btn-text"
                        onClick={navigateToJoinNow}
                        style={{ background: "#179624" }}
                      >
                        Join Now
                      </Button>
                    )}
                  </div>
                )
              )}
            </div>
          </Row>
        </div>

        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>

            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName} onClick={clearData}>
                  <Link to={item.path}>
                    {item.icon}
                    <span
                      className={
                        item.path === "/contact-us"
                          ? "title-contact-us"
                          : "title-text "
                      }
                      style={{
                        color: item.path == "/contact-us" ? "red" : "#0D3862",
                      }}
                    >
                      {item.title}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}
