//promo-code

import React, { useEffect, useState } from "react";
import "./promoCode.css";
import { useSelector, useDispatch } from "react-redux";
import { endPoints, PaymentEndpoints } from "../../constant/Environment";
import {
  getAllData,
  updateUser,
  getData,
  updateBetUser,
  addData,
} from "../../Utility/API";
import { Actions } from "../../redux/Actions/Actions";
import Toaster from "../../components/Toast";
import Loader from "../../components/Loader";
import { Form, Button, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ErrorToast, SuccesToast } from "../../components/Toast/message";
import { Helmet } from "react-helmet";
import { PageVisit } from "../../Utility/API";

const PromoCode = () => {
  const state = useSelector((state) => state.auth);
  const userData = state.user;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoadinig] = useState(false);
  const [code, setCode] = useState("");

  // console.log("state",state)

  useEffect(() => {
    PageVisit(state?.user?.idUser || "", "promo-code");
  }, []);

  const GetUserById = () => {
    if (state.isAuthenticated) {
      let url = `${endPoints.api.GET_BY_USERID}/${state?.user?.idUser}`;
      getAllData(url).then((response) => {
        dispatch({
          type: Actions.LOGIN,
          data: { user: response.data },
        });
      });
    }
  };

  const handleSubmit = async () => {
    let data = {
      code: code,
      id_user: userData?.idUser,
    };
    console.log("data", data);
    let url = `${endPoints.api.promo}`;
    setIsLoadinig(true);
    await addData(url, data)
      .then((response) => {
        setIsLoadinig(false);
        if (response.data.status === "success") {
          setMessage("Succcess");
          SuccesToast("Promo code applied Successfully");
          setCode("");
          GetUserById();
        } else {
          setMessage(response.data.message);
          ErrorToast(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoadinig(false);
        ErrorToast(
          "Issue connecting to server (error code 15). Please contact support for further assistance."
        );
        console.error(error);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Promotions & Bonuses | BettDraft Fantasy Sports</title>

        <meta
          name="description"
          content="Read more about BettDraft's available promotions and bonuses."
        />

        <link rel="canonical" href="https://dfs.bettdraft.com/promo-code" />
      </Helmet>

      <div className="container bg-color-pi">
        <div className="inner-divspace">
          <Toaster />
          {isLoading && <Loader spinner={true} visible={isLoading} />}
          <>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <div>
                    <h3
                      style={{
                        color: "#0D3862",
                        textAlign: "center",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                    >
                      Claim a Promo Code
                    </h3>
                    <div
                      style={{
                        color: "#0D3862",
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                    >
                      {/* Promo Code */}
                    </div>
                    <div
                      style={{
                        color: "#0D3862",
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                    >
                      Please enter your promotion code below.
                    </div>
                    <div
                      style={{
                        color: "#0D3862",
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                    >
                      Please refer to the promotion terms for more information
                      about the promotion and promotion expiry dates prior to
                      claiming.
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 form-space-r">
                <div className="my-form-layout1">
                  <div
                    className="row"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="phone">
                        <Form.Label className="my-form-label">
                          Promo Code:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Please enter your Promo Code."
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                        {/* <Form.Control.Feedback type="invalid">
                          Please provide a valid SSN.
                        </Form.Control.Feedback> */}
                      </Form.Group>
                      <Button
                        variant="primary"
                        className="save-btn samllbtn"
                        type="submit"
                        style={{ marginTop: "15px" }}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        <b style={{ fontSize: "17px" }}>Claim Promo Code</b>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default PromoCode;
