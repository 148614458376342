import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Promotions from "../../screens/Sportsbook/Promotions";
import "./Navbar.css";
import { useSelector } from "react-redux";

const Slider = () => {
  const state = useSelector((state) => state);

  const pathname = window.location.pathname;

  return (
    <div>
      {pathname === "/" ? (
        <Row
          className="slider-section"
          style={{
            width: "100%",
            marginTop: "65px",
            marginLeft: "0px",
            paddingRight: "40px!important",
          }}
        >
          {!state?.betslip?.promoPage ? <Promotions /> : ""}
        </Row>
      ) : (
        ""
      )}
    </div>
  );
};

export default Slider;
